import Loading from 'common/services/Loading';
import { LOGGER_LOG_TYPE } from 'Config';
import Logger from 'common/services/Logger';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import styles from './NotesTab.module.scss';
import { ReactComponent as AddCircleIcon } from 'assets/svg/addcircle.svg';
import { ReactComponent as EditIcon } from 'assets/svg/pencil.svg';
import { ReactComponent as TrashIcon } from 'assets/svg/trash.svg';
import { useEffect, useState } from 'react';
import Button, { Color } from 'common/components/buttons/Button';
import moment from 'moment';
import SummernoteController from 'common/components/summernoteController/SummernoteController';
import ClientNotesService from 'api/clientNotes/ClientNotesService';
import { ClientNoteDto } from 'api/clientNotes/models/ClientNoteDto';
import { ClientNotesSearchCriteria } from 'api/clientNotes/models/ClientNotesSearchCriteria';
import { useForm } from 'react-hook-form';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import InputError from 'common/components/inputs/inputError/InputError';
import WarningToast from 'common/components/warningToast/WarningToast';

interface Props {
    clientId: string | undefined
    updateCounters: Function
}

function NotesTab ({ clientId, updateCounters }: Props): JSX.Element {
    const { t } = useTranslation();

    const { handleSubmit, formState, control, reset, setValue } = useForm<ClientNoteDto>({ shouldUnregister: false });

    const [notes, setNotes] = useState<ClientNoteDto[]>();

    const [criteria] = useState<ClientNotesSearchCriteria>({ clientId });
    const [addNew, setAddNew] = useState(false);
    const errors = formState.errors;

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<ClientNoteDto>({
        id: '',
        description: '',
        clientId: undefined,
        date: undefined,
        userName: undefined
    });

    useEffect(() => {
        void getData();
    }, [clientId]);

    const getData = async () => {
        try {
            Loading.show();
            const list = await ClientNotesService.getList(criteria);
            setNotes(list);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't get documents list", error);
            toast.error(`${t('shared_translations.messages.error_load_info')}`);
            Loading.hide();
        }
    };

    const onSubmit = async (model: ClientNoteDto) => {
        try {
            Loading.show();
            if (model.id && model.id != null) {
                await ClientNotesService.update({ ...model, clientId });
                toast.success(`${t('shared_translations.messages.record_save_success')}`);
            } else {
                await ClientNotesService.create({ ...model, clientId });
                toast.success(`${t('shared_translations.messages.record_save_success')}`);
            }
            reset({
                description: ''
            });
            updateCounters();
            void getData();
            setAddNew(false);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't create/update categorie", error);
            toast.error(`${t('shared_translations.messages.record_save_error')}`);
            Loading.hide();
        }
    };

    const onError = async () => {
        toast(`${t('shared_translations.messages.required_fields_empty')}`, {
            icon: <WarningToast />,
        });
    };

    const onCancelRemove = () => {
        setItemToRemove({
            id: undefined,
            description: '',
            clientId: undefined,
            date: undefined,
            userName: undefined
        });
        setShowRemoveModal(false);
    };

    const removeItem = async () => {
        try {
            Loading.show();
            await ClientNotesService.remove(itemToRemove);
            toast.success(`${t('shared_translations.messages.record_delete_success')}`);
            setShowRemoveModal(false);
            Loading.hide();
            updateCounters();
            void getData();
        } catch (error) {
            setShowRemoveModal(false);
            toast.error(`${t('shared_translations.messages.record_delete_error')}`);
            setShowRemoveModal(false);
            Loading.hide();
        }
    };

    const renderItem = (note: ClientNoteDto) => {
        return <div>
            <div className={styles.noteInfos}>
                <span>
                    {moment(note.date).format('DD/MM/YYYY  | HH:mm')}
                </span>
                <span style={{ marginLeft: '1.5rem' }}>
                    {note.userName}
                </span>
            </div>

            <div className={styles.noteContainer}>
                <div className={styles.noteContent}>
                    <div className={styles.noteText} dangerouslySetInnerHTML={{ __html: note.description ?? '' }} />

                    <div className={styles.actionButtonsContainer}>
                        <div className={styles.actionButton} onClick={() => { setAddNew(true); setValue('description', note.description); setValue('id', note.id); }}>
                            <EditIcon />
                        </div>

                        <div className={styles.actionButton} onClick={() => { setItemToRemove(note); setShowRemoveModal(true); }}>
                            <TrashIcon />
                        </div>

                    </div>
                </div>
            </div>
        </div>;
    };

    return (
        <div>
            {addNew && <div className={styles.containerInputNew}>
                <form className={styles.form} onSubmit={handleSubmit(onSubmit, onError)}>
                    <SummernoteController
                        className={styles.summernote}
                        name={'description'}
                        placeholder={t('shared_translations.common.write_here')}
                        isDisabled={false}
                        control={control}
                        required={true} />
                    <InputError error={errors.description} />
                    <div className={styles.buttonsContainer}>
                        <Button text={t('shared_translations.common.cancel')} color={Color.white} onClick={() => { setAddNew(false); setValue('description', undefined); setValue('id', undefined); }} />
                        <Button text={t('shared_translations.common.save')} color={Color.black} type='submit' />
                    </div>

                </form>
            </div>}

            <div className={styles.containerAdd}>
                <div className={styles.title}>
                    {t('clients.tabs.notes.tab_notes')}
                </div>
                {!addNew && <AddCircleIcon onClick={() => setAddNew(true)} style={{ cursor: 'pointer' }} />}
            </div>

            {notes?.map((note, index) => (
                <div key={`note_${index}`} className={styles.containerItem} >
                    {renderItem(note)}
                </div>
            ))}
            {notes?.length === 0 && <div>
                <div className={styles.noItemsText}>
                    {<h3>{t('shared_translations.messages.no_info_to_show')}</h3>}
                </div>
            </div>}

            <QuestionYesNo
                onNo={onCancelRemove}
                onYes={removeItem}
                isVisible={showRemoveModal}
                message={t('shared_translations.messages.remove_record_with_ident', {
                    name: '',
                })}
            />
        </div >
    );
}

export default NotesTab;
