import React, { useEffect, useState } from 'react';
import Sidebar, { SidebarItem } from '../sidebar/Sidebar';
import styles from './AuthenticatedLayout.module.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { changeSideBar } from 'store/sideBar/action';
import { Reducers } from 'store/types';
import { ApplicationPaths, DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import Loading from 'common/services/Loading';
import CategoriesService from 'api/categories/CategoriesService';

import Logger from 'common/services/Logger';
import toast from 'react-hot-toast';
import { CategorieDto } from 'api/categories/models/CategorieDto';
import { CategorySearchCriteria } from 'api/categories/models/CategorySearchCriteria';
import DocumentTitle from 'react-document-title';

interface Props {
    children: React.ReactNode
}

const AuthenticatedLayout: React.FunctionComponent<Props> = ({ children }: Props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const showSidebar = useSelector<Reducers, boolean>(state => state.sideBar.open);
    const [categories, setCategories] = useState<CategorieDto[]>();

    const [criteria] = useState<CategorySearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderBy: 'asc',
        orderColumn: 'name',
    });

    useEffect(() => {
        void getData();
    }, [i18n.language, showSidebar]);

    const getData = async () => {
        try {
            Loading.show();
            const categoriesList = await CategoriesService.getList(criteria);
            setCategories(categoriesList);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t  get categories list', error);
            toast.error(`${t('shared_translations.messages.error_load_info')}`);
            Loading.hide();
        }
    };
    const sidebarItems: SidebarItem[] = [
        {
            text: t('menu.start'),
            url: '/home',
        },
        {
            text: t('menu.categories'),
            url: '/',
            subNav: categories
        },
        {
            text: t('menu.clients_management'),
            url: ApplicationPaths.Clients,
            policies: ['SETTINGUP_CLIENTS_WRITE', 'SETTINGUP_CLIENTS_READ']
        },
        {
            text: t('menu.backoffice'),
            url: ApplicationPaths.Backoffice,
            policies: [
                'SETTINGUP_RULES_READ',
                'SETTINGUP_RULES_WRITE',
                'SETTINGUP_USERS_READ',
                'SETTINGUP_USERS_WRITE',
                'SETTINGUP_CLIENTS_READ',
                'SETTINGUP_CLIENTS_WRITE',
                'SETTINGUP_ROLES_READ',
                'SETTINGUP_ROLES_WRITE',
                'SETTINGUP_LANGUAGES_READ',
                'SETTINGUP_LANGUAGES_WRITE',
                'SETTINGUP_DEFINITIONS_READ',
                'SETTINGUP_DEFINITIONS_WRITE'
            ],
        },
    ];

    const closeSideBar = () => {
        dispatch(changeSideBar());
    };

    return (
        <DocumentTitle title={t('menu.website_name')}>
            <div className= {styles.container} >
                <div className={`${styles.contentContainer} ${showSidebar ? styles.overflowCon : null}`} id="tableScroll" >
                    {children }
                </div>
                {showSidebar && <Sidebar items={sidebarItems} onClose={() => closeSideBar()} />}

            </div>
        </DocumentTitle>
    );
};

export default AuthenticatedLayout;
