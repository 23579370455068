import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './InputGroup.module.scss';

export type Props = {
    name?: string;
    text?: string;
    icon?: any;
    format?: 'default' | 'money';
    register?: UseFormRegister<any>;
    disabled?: boolean;
    required?: boolean;
    onChange?: any;
    pattern?: string;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const InputGroup = ({ name, text, icon, format, register, required, onChange, pattern, ...props }: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { t } = useTranslation();

    const registerProps: any = (register && name
        ? register(name, {
            required,
            validate: required ? (value) => { return !value?.trim() ? (t('shared_translations.messages.required_field') ?? false) : true; } : undefined
        })
        : {});

    const valueChange = (e: any) => {
        if (pattern) {
            e.target.value = e.target.value.replace(new RegExp(pattern, 'ig'), '');
        }

        if (onChange) {
            onChange(e);
        }

        if (registerProps?.onChange) {
            registerProps?.onChange(e);
        }
    };

    return (
        <div className={styles.inputGroup}>
            <input
                {...ref}
                {...registerProps}
                type={format === 'money' ? 'number' : 'text'}
                step={format === 'money' ? '.01' : undefined}
                disabled={props.disabled}
                onChange={valueChange}
                onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                className={`${styles.input} ${props.disabled ? styles.disabled : ''} ${required ? styles.warning : styles.noWarning}`}
                style={{ width: '100%', textAlign: format === 'money' ? 'right' : undefined }}
            />
            {(text ?? icon) && !format && <div className={` ${styles.inputGroupAppend}`} >
                <span className={` ${styles.inputGroupText}`}>{icon || text}</span>
            </div>}
            {format === 'money' && <div className={` ${styles.inputGroupAppend}`} >
                <span className={` ${styles.inputGroupText}`}>{'€'}</span>
            </div>}
        </div>

    );
};

export default React.forwardRef<HTMLInputElement, Props>(InputGroup);
