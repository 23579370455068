export default function deepen (obj: any) {
    const result = {};
    const objKeys = Object.keys(obj);

    // For each object path (property key) in the object
    for (const objectPath in obj) {
        if (objKeys.find((x: string) => x.startsWith(objectPath + '.'))) {
            continue;
        }

        // Split path into component parts
        const parts = objectPath.split('.');

        // Create sub-objects along path as needed
        let target: any = result;
        while (parts.length > 1) {
            const part: any = parts.shift();
            target = target[part] = target[part] || {};
        }

        // Set value at end of path
        target[parts[0]] = obj[objectPath];
    }

    return result;
}

export function mergeDeep (prev: any, obj: any) {
    const isObject = (o: any) => o && typeof o === 'object';

    const result: any = { ...prev };

    Object.keys(obj).forEach(key => {
        const pVal = prev[key];
        const oVal = obj[key];

        if (!pVal) {
            return;
        }

        if (Array.isArray(pVal) && Array.isArray(oVal)) {
            result[key] = pVal.concat(...oVal);
        } else if (isObject(pVal) && isObject(oVal)) {
            result[key] = mergeDeep(pVal, oVal);
        } else {
            result[key] = oVal;
        }
    });

    return result;
}

export function deepCleanStrings (obj: any, ignorePrefix?: string) {
    const newObj = { ...obj };

    for (const key in newObj) {
        if (Object.prototype.hasOwnProperty.call(newObj, key)) {
            if (typeof newObj[key] === 'object') {
                newObj[key] = deepCleanStrings(newObj[key], ignorePrefix);
            } else if (!ignorePrefix || !key.startsWith(ignorePrefix)) {
                newObj[key] = '';
            } else {
                //
            }
        }
    }

    return newObj;
}

export function deepFilter (obj: any, accept: (key: string, container: any) => boolean) {
    const newObj = { ...obj };

    for (const key in newObj) {
        if (Object.prototype.hasOwnProperty.call(newObj, key)) {
            if (typeof newObj[key] === 'object') {
                newObj[key] = deepFilter(newObj[key], accept);
            }

            if (!accept(key, newObj)) {
                delete newObj[key];
            }
        }
    }

    return newObj;
}
