import { ReactComponent as GoBackIcon } from 'assets/svg/arrowleft.svg';
import { ReactComponent as SearchIcon } from 'assets/svg/icon-pesquisa.svg';
import { ReactComponent as UserIcon } from 'assets/svg/icon-user-client.svg';
import { ReactComponent as BurgerIcon } from 'assets/svg/menu.svg';
import { ReactComponent as AdvancedIcon } from 'assets/svg/pesquisa-avançada.svg';
import { ReactComponent as SearchIcon2 } from 'assets/svg/search-circle.svg';
import { ReactComponent as SettingIcon } from 'assets/svg/settings-circle.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/x-circle.svg';
import { IoMdArrowDropdown } from 'react-icons/io';

import Button, { Color } from 'common/components/buttons/Button';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Navbar.module.scss';

import { useDispatch, useSelector } from 'react-redux';

import { UserProfile } from 'api/account/models/UserProfile';
import { BreadCrumb } from 'common/types/BreadCrumb';
import hasPolicies from 'common/utils/hasPolicies';
import { API_BASE_URL, ApplicationPaths, LOGGER_LOG_TYPE } from 'Config';
import { useNavigate } from 'react-router-dom';
import { changeSideBar } from 'store/sideBar/action';
import { Reducers } from 'store/types';
import { showEditLayout } from 'store/editLayout/action';
import { setChosenUser } from 'store/chosenUser/action';
import { useDebouncedCallback } from 'use-debounce';
import { PagedSearchCriteria } from 'api/common/types/PagedSearchCriteria';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import toast from 'react-hot-toast';
import ClientsService from 'api/clients/ClientsService';
import { ClientDto } from 'api/clients/models/ClientDto';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';

interface Props {
    onOpen?: () => void
    searchText?: string
    onChange?: any
    onSearch?: any
    breadCrumbs: BreadCrumb[],
    handleSettingClick?: () => void,
    isBackoffice?: boolean,
    inputPlaceHolder?: string,
    isDetails?: boolean
    hideSearch?: boolean
}

function Navbar (props: Props): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isDetails = true, hideSearch } = props;

    const [searchBar, setSearchBar] = useState(false);
    const [showprofileWindow, setShowProfileWindow] = useState(false);
    const [users, setUsers] = useState<ClientDto[]>([]);
    const [showUsersList, setShowUserList] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

    const [criteria, setCriteria] = useState<PagedSearchCriteria>({
        itemsPerPage: 5,
        page: 1,
        orderBy: 'asc',
        orderColumn: 'u.real_name',
        allIn: ''
    });

    const user = useSelector<Reducers, UserProfile | null>(
        (state) => state.authentication.profile
    );

    const userSearched = useSelector<Reducers, ClientDto | null>(
        (state) => state.chosenUser.user
    );

    const showEditLayoutConst = useSelector<Reducers, boolean>(state => state.editLayout.open);
    const hasEditLayoutReadPolicy = hasPolicies(user, ['SETTINGUP_EDITLAYOUT_READ']);

    const handleEditButton = () => {
        dispatch(showEditLayout());
    };

    const openSideBar = () => {
        dispatch(changeSideBar());
    };

    if (!user) {
        navigate('/login');
    }

    const logout = () => {
        navigate('/authentication/logout');
    };

    const loadBread = () => {
        return (
            <ul>
                {props.breadCrumbs.map((item, i) => item.text !== '' && (
                    <li key={i}>
                        <a href={item.url}>{item.text}</a>
                    </li>
                ))}
            </ul>
        );
    };

    const debounced = useDebouncedCallback((value: string) => {
        setCriteria({ ...criteria, page: 1, allIn: value });
    }, 500);

    const userClicked = (newUser: ClientDto) => {
        dispatch(setChosenUser(newUser));
        setShowUserList(false);
        if (props.onChange) { props.onChange({ target: { value: '' } }); }
        setCriteria({ ...criteria, allIn: '' });
    };

    const clearUser = () => {
        dispatch(setChosenUser(null));
    };

    const getData = async () => {
        try {
            Loading.show();
            const page = await ClientsService.getList(criteria);
            setUsers(page.items);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get users list', error);
            toast.error(`${t('shared_translations.messages.error_load_info')}`);
            Loading.hide();
        }
    };

    useEffect(() => {
        if (!props.isBackoffice && isDetails) {
            void getData();
        }
        if (hideSearch) { setSearchBar(false); }
    }, [criteria.allIn, hideSearch]);

    const lastIcon = () => {
        if (userSearched === null && !props.isBackoffice) {
            return (<IoMdArrowDropdown style={{ height: '20px', width: '20px' }} onClick={() => showUserListClick()} />);
        }

        if (props.isBackoffice ?? !isDetails) {
            return (<AdvancedIcon style={{ display: 'none' }} />);
        }

        return (<CloseIcon className={styles.closeIcon} onClick={() => showRemoveItemDialog()} />);
    };

    const onCancelRemove = () => {
        setShowRemoveModal(false);
    };

    const removeItem = () => {
        clearUser();
        setShowRemoveModal(false);
    };

    const showRemoveItemDialog = () => {
        if (userSearched && userSearched != null) {
            try {
                Loading.show();
                setShowRemoveModal(true);
                Loading.hide();
            } catch (error) {
                setShowRemoveModal(false);
                toast.error(`${t('shared_translations.messages.record_delete_error')}`);
                Loading.hide();
            }
        }
    };

    const showUserListClick = () => {
        if (userSearched === null && isDetails) {
            const element = document.getElementById('input');
            showUsersList ? element?.blur() : element?.focus();
            setShowUserList(!showUsersList);
        }
    };

    const onSerchStringChange = (e: any) => {
        if (props.onChange) { props.onChange(e); }
    };

    const mobileUserSelectedDiv = () => (
        userSearched !== null && detailsNotEditLayout && !props.isBackoffice && <div className={`${styles.chosenUserMobile} ${styles.onlyXS}`} >

            {userSearched?.mediaUrl
                ? <img src={userSearched.mediaUrl} className={styles.resultIconMobile} />
                : <UserIcon className={styles.resultIconMobile} />}
            <div className={styles.chosenData} onClick={() => navigate(`${ApplicationPaths.Clients}/file/${userSearched.id}`)}>
                <span className={styles.title}>{userSearched?.realName}</span>
                <span className={styles.subtitle}>{userSearched?.email}</span>
            </div>

            <CloseIcon className={styles.closeIcon} onClick={() => showRemoveItemDialog()} />
        </div>

    );

    const loadUserImage = () => {
        if (!props.isBackoffice && isDetails) {
            if (userSearched?.mediaUrl) {
                return <img src={userSearched.mediaUrl} className={styles.resultIconSearchBar} style={{ borderRadius: '2rem' }} onClick={() => showUserListClick()} />;
            } else {
                return <UserIcon className={styles.resultIconSearchBar} onClick={() => showUserListClick()} />;
            }
        } else {
            return <SearchIcon2 style={{ cursor: 'pointer' }} />;
        }
    };

    const loggedUserIcon = () => (
        user?.mediaUrl
            ? <img src={user.mediaUrl} className={styles.circularIcon} onClick={() => { setShowProfileWindow(!showprofileWindow); }} />
            : <UserIcon
                className={`${styles.circularIcon} ${showprofileWindow ? styles.selectedProfileIcon : null}`}
                onClick={() => {
                    setShowProfileWindow(!showprofileWindow);
                }}
            />
    );

    const profileWindow = () => (
        showprofileWindow && (
            <div className={styles.profileDiv}>
                <div
                    className={styles.info}
                    onClick={() =>
                        navigate(
                            `${ApplicationPaths.Backoffice}/users/details/${user?.id}`
                        )
                    }
                    style={{ cursor: 'pointer' }}
                >
                    {user?.mediaUrl
                        ? <img src={user.mediaUrl} className={styles.circularIcon} />
                        : <UserIcon
                            className={`${styles.circularIcon}`}

                        />}
                    <div className={styles.marginLeft}>
                        <div className={styles.name}>{user?.realName}</div>
                        <div className={styles.email}>{user?.email}</div>
                    </div>
                </div>
                <div className={styles.button}>
                    <Button
                        text={t('menu.logout')}
                        color={Color.black}
                        style={{ width: '100%', paddingLeft: 0 }}
                        onClick={logout}
                    />
                </div>
            </div>
        )
    );

    const detailsNotEditLayout = !showEditLayoutConst && isDetails;

    return (
        <div style={{ position: 'relative' }}>
            <div className={styles.upperDiv}>
                {!searchBar && (
                    <>
                        <div className={styles.logoDiv}>
                            <div className={styles.upperBurgerIcon}>
                                <BurgerIcon onClick={() => openSideBar()} />
                            </div>
                            <div className={styles.logoContainer} style={{ backgroundImage: `url("${API_BASE_URL}/contentconfigurations/media-by-internal-finder/logo")`, cursor: 'pointer' }}
                                onClick={() => navigate(`${ApplicationPaths.Home}`)} />
                        </div>
                        {(detailsNotEditLayout || props.isBackoffice) && <div className={styles.webSearchBar}>
                            {!hideSearch && <div className={styles.webInput}>
                                <span onClick={props.onSearch}>{loadUserImage()}</span>
                                {(userSearched === null || props.isBackoffice)
                                    ? <input
                                        id='input'
                                        type="text"
                                        autoComplete="off"
                                        value={props.searchText}
                                        onChange={(e) => { debounced(e.currentTarget.value); onSerchStringChange(e); }}
                                        placeholder={props.inputPlaceHolder ?? t('shared_translations.common.search')}
                                        onClick={() => showUserListClick()}
                                    />
                                    : <div className={styles.resultRow} style={{ width: '100%' }} onClick={() => navigate(`${ApplicationPaths.Clients}/file/${userSearched.id}`)}>
                                        <div className={styles.resultData} style={{ marginLeft: '0px' }}>
                                            <span className={styles.title}>{userSearched?.realName}</span>
                                            <span className={styles.subtitle}>{userSearched?.email}</span>
                                        </div>
                                    </div>
                                }

                                <span style={{ marginRight: '1.25rem', cursor: 'pointer' }}>
                                    {lastIcon()}
                                </span>
                            </div>}
                            {showUsersList && <div className={styles.resultsDiv}>
                                {users.map((item, index) => (
                                    <div key={index} className={styles.resultRow} onClick={() => userClicked(item)} >
                                        {item?.mediaUrl
                                            ? <img src={item.mediaUrl} className={styles.resultIcon} style={{ borderRadius: '2rem' }} onClick={() => showUserListClick()} />
                                            : <UserIcon className={styles.resultIcon} />}
                                        <div className={styles.resultData}>
                                            <span className={styles.title}>{item.realName}</span>
                                            <span className={styles.subtitle}>{item.email}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>}
                        </div>}

                        <div className={styles.iconsDiv}>
                            {detailsNotEditLayout && !hideSearch && <SearchIcon
                                className={`${styles.circularIcon} ${styles.onlyXS}`}
                                onClick={() => setSearchBar(true)}
                            />}
                            {(hasEditLayoutReadPolicy && !props.isBackoffice) && <SettingIcon className={`${styles.circularIcon} ${showEditLayoutConst ? styles.opacity : null}`} onClick={handleEditButton} />}
                            {loggedUserIcon()}
                            {profileWindow()}
                        </div>

                    </>
                )}

                {/* MOBILE SEARCHBAR */}
                {searchBar && (
                    <div className={styles.mobileSearchBar}>
                        <span
                            style={{ marginLeft: '1.25rem', cursor: 'pointer' }}
                            onClick={() => { setSearchBar(false); setShowUserList(false); }}
                        >
                            <GoBackIcon />
                        </span>
                        <input
                            type="text"
                            value={props.searchText}
                            style={{ fontSize: '1rem' }}
                            placeholder={props.inputPlaceHolder ?? t('shared_translations.common.search')}
                            onChange={(e) => { debounced(e.currentTarget.value); onSerchStringChange(e); }}
                            onClick={() => setShowUserList(!showUsersList)}
                        />
                    </div>
                )}
            </div>

            {/* MOBILE USER SELECTED */}
            {mobileUserSelectedDiv()}

            {/* BreadCrumbs */}
            <div className={styles.breadCrumbsContainer}>
                <div className={styles.breadCrumbsDiv}>
                    <BurgerIcon
                        onClick={() => openSideBar()}
                        className={styles.lowerBurgerIcon}
                    />
                </div>
                <div className={styles.breadCrumbsDiv}>
                    {loadBread()}
                </div>
            </div>

            {/* MOBILE USERS LIST */}
            {showUsersList && <div className={`${styles.resultsDiv} ${styles.onlyXS}`} >
                {users.map((item, index) => (
                    <div key={index} className={styles.resultRow} onClick={() => { setSearchBar(false); userClicked(item); }} >
                        {item?.mediaUrl
                            ? <img src={item.mediaUrl} className={styles.resultIcon} />
                            : <UserIcon className={styles.resultIcon} />}
                        <div className={styles.resultData}>
                            <span className={styles.title}>{item.realName}</span>
                            <span className={styles.subtitle}>{item.email}</span>
                        </div>
                    </div>
                ))}
            </div>}

            <QuestionYesNo onNo={onCancelRemove} onYes={removeItem} isVisible={showRemoveModal}
                message={t('shared_translations.messages.leave_client_chosen', { clientName: userSearched?.realName })} />
        </div>
    );
};

export default Navbar;
