import LanguageDto from 'api/languages/models/LanguageDto';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import Button, { Color } from '../buttons/Button';
import { Select } from '../inputs/SelectInput';
import QuestionYesNo from '../questionYesNo/QuestionYesNo';
import styles from './CopyContent.module.scss';

interface Props {
    languages: LanguageDto[];
    onCopy: (language: LanguageDto) => void;
    onClear: () => void;
}

function CopyContent ({ languages, onCopy, onClear }: Props): JSX.Element {
    const { t } = useTranslation();
    const [languageId, setLanguageId] = useState<string | null>(null);
    const languagesOptions: SelectValueLabel[] = languages.map(lang => ({ value: lang.id, label: lang.name }));
    const [show, setShow] = useState(false);
    const [showClearModal, setShowClearModal] = useState(false);

    const onChangeLanguage = (langId: string | null | undefined) => {
        setLanguageId(langId ?? null);
    };

    const onClickCopy = () => {
        const lang = languages.find(l => l.id === languageId);
        if (!lang) {
            return;
        }

        onCopy(lang);
    };

    const onClickClear = () => {
        setShowClearModal(true);
    };

    const onClearModalNo = () => {
        setShowClearModal(false);
    };

    const onClearModalYes = () => {
        setShowClearModal(false);
        onClear();
    };

    return (
        <div className={styles.container}>
            <div className={styles.headerContainer} onClick={() => setShow(!show)}>
                <label className={styles.header}>{t('backoffice.languages.translation_menu.manage_content')}</label>
                <div>
                    {show && <FaCaretDown className={styles.icon} size={18} />}
                    {!show && <FaCaretUp className={styles.icon} size={18} />}
                </div>
            </div>
            {show && <div className={styles.contentContainer}>
                <label>{t('backoffice.languages.translation_menu.copy_from_language')}</label>
                <div className={styles.selectorContainer}>
                    <div className={styles.selectContainer}>
                        <Select optionsList={languagesOptions} onChange={onChangeLanguage} value={languageId} />
                    </div>
                    <div className={styles.buttons}>
                        <Button color={Color.white} text={t('backoffice.languages.translation_menu.clear')} onClick={onClickClear} />
                        <Button color={Color.black} text={t('backoffice.languages.translation_menu.copy')} onClick={onClickCopy} disabled={!languageId} />
                    </div>
                </div>
            </div>}
            <QuestionYesNo
                onNo={onClearModalNo}
                onYes={onClearModalYes}
                isVisible={showClearModal}
                message={t('shared_translations.messages.clear_content')}
            />
        </div>
    );
}

export default CopyContent;
