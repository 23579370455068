import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import DocumentTitle from 'react-document-title';

interface Props {
    title: string
    children?: ReactNode | ReactNode[] | null
}

const ScreenTitle: React.FunctionComponent<Props> = ({ children, title }: Props) => {
    const { t } = useTranslation();

    const titleTranslation: string = t('menu.website_name');

    return (
        <DocumentTitle title={`${title} - ${titleTranslation}`}>
            <>{children}</>
        </DocumentTitle>
    );
};

export default ScreenTitle;
