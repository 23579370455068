import { SelectValueLabel } from 'common/types/SelectValueLabel';
import ReactSelect from 'react-select';
import { Control, Controller } from 'react-hook-form';

interface SelectProps {
    optionsList: SelectValueLabel[];
    disabled?: boolean;
    placeholder?: string;
    showRequired?: boolean;
    value: string | null | undefined;
    onChange: (v: string | null | undefined) => void;
    onCategorieChange?: any;
}

export function Select ({ optionsList, disabled, placeholder, showRequired, value, onChange, onCategorieChange }: SelectProps) {
    return (
        <ReactSelect
            options={optionsList}
            isClearable={true}
            placeholder={placeholder}
            isDisabled={disabled}
            required={true}
            styles={{
                control: (base, _state) => ({
                    ...base,
                    background: disabled ? '#eee' : '#ffff',
                    border: showRequired ? '1px solid #FFBF00' : '1px solid #e7e7e7',
                    borderRadius: '0.625rem',
                    height: '2.5rem',
                    marginTop: '0.4rem',
                    boxShadow: 'none',
                    '&:hover': {
                        border: '1px solid #B5B5C3',
                    }
                }),
            }}
            value={optionsList.find((c) => c.value === value) ?? null}
            onChange={(val) => {
                onChange(val?.value);
                if (onCategorieChange) {
                    onCategorieChange(val?.value);
                }
            }}
        />
    );
}

type Props = Omit<SelectProps, 'value' | 'onChange'> & {
    required?: boolean
    name: string
    control: Control<any>
    onCategorieChange?: any
};

const SelectInput = ({ name, required = false, control, onCategorieChange, ...props }: Props) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={{ required }}
            render={({ field }) => (
                <Select {...props} onChange={field.onChange} value={field.value} onCategorieChange={onCategorieChange} />
            )}

        />
    );
};

export default SelectInput;
