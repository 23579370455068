import { PagedSearchCriteria } from 'api/common/types/PagedSearchCriteria';
import BackofficeTable, { BackofficeTableColumn } from 'common/components/backofficeTable/BackofficeTable';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { ApplicationPaths, DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import LanguageService from 'api/languages/LanguagesService';
import LanguageDto from 'api/languages/models/LanguageDto';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/dropdown/DropdownItem';
import { ReactComponent as ActionsIcon } from 'assets/svg/more-vertical.svg';
import styles from './LanguageTab.module.scss';
import CheckBox from 'common/components/checkBox/CheckBox';
import { useNavigate } from 'react-router-dom';
import hasPolicies from 'common/utils/hasPolicies';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import { ReactComponent as AvatarIcon } from 'assets/svg/inputImage.svg';

interface Props {
    searchString: string
    searchOrder: boolean
}

const LanguageTab = (props: Props) => {
    const { searchString, searchOrder } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [languages, setLanguages] = useState<LanguageDto[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [criteria, setCriteria] = useState<PagedSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderBy: 'asc',
        orderColumn: 'l.name',
    });
    const user = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = hasPolicies(user, ['SETTINGUP_LANGUAGES_WRITE']);

    let allInField = searchString;

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<LanguageDto | null>(null);

    useEffect(() => {
        allInField = searchString;
        void getLanguageList(criteria.page === 1);
    }, [criteria]);

    useEffect(() => {
        setCriteria({ ...criteria, page: 1 });
        void getLanguageList(true);
    }, [searchOrder]);

    const showRemoveItemDialog = (item: LanguageDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    };

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const removeItem = async () => {
        if (!itemToRemove) {
            return;
        }

        try {
            Loading.show();
            await LanguageService.deactivate(itemToRemove.id);
            toast.success(`${t('shared_translations.messages.record_delete_success')}`);
            setShowRemoveModal(false);
            changePage(1);
            Loading.hide();
        } catch (error) {
            setShowRemoveModal(false);
            toast.error(`${t('shared_translations.messages.record_delete_error')}`);
            setShowRemoveModal(false);
            Loading.hide();
        }
    };

    const changePage = (page: number) => {
        setCriteria({ ...criteria, page });
    };

    const getLanguageList = async (reset = false) => {
        try {
            Loading.show();
            const page = await LanguageService.getList({ ...criteria, allIn: allInField });
            if (reset) {
                setLanguages(page.items);
            } else {
                setLanguages([...languages, ...page.items]);
            }
            setTotalItems(page.totalItems);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't get languages list", error);
            toast.error(`${t('shared_translations.messages.error_load_info')}`);
            Loading.hide();
        }
    };

    const orderTable = async (orderField: string) => {
        const page = 1;
        const orderColumn = orderField;
        let orderBy: string;

        if (orderColumn !== criteria.orderColumn) {
            orderBy = 'asc';
        } else {
            if (criteria.orderBy === 'asc') {
                orderBy = 'desc';
            } else {
                orderBy = 'asc';
            }
        }
        setCriteria({ ...criteria, page, orderColumn, orderBy });
    };

    const renderActionCell = (row: LanguageDto) => {
        return (
            <div className={styles.optionsIcon}>
                <Dropdown
                    options={
                        <>
                            <DropdownItem url={`${ApplicationPaths.Backoffice}/languages/details/${row.id}`}>
                                {t('shared_translations.common.details')}
                            </DropdownItem>
                            { canWrite &&
                                <DropdownItem url={`${ApplicationPaths.Backoffice}/languages/edit/${row.id}`}>
                                    {t('shared_translations.common.edit')}
                                </DropdownItem>
                            }
                            { canWrite &&
                                <DropdownItem onClick={() => showRemoveItemDialog(row)}>
                                    {t('shared_translations.common.delete')}
                                </DropdownItem>
                            }
                        </>
                    }
                >
                    <div>
                        <ActionsIcon />
                    </div>
                </Dropdown>
            </div>
        );
    };

    const LanguagesColumn: BackofficeTableColumn<LanguageDto>[] = [
        {
            name: '',
            renderCell: (row) => row.mediaUrl ? <img className={styles.image} src={row.mediaUrl}/> : <AvatarIcon className={styles.image}/>,
            width: '5%',
            hideOn: ['sm', 'md'],
            columnName: '',
        },
        {
            name: t('backoffice.languages.form.name'),
            renderCell: (row) => row.name,
            width: '80%',
            hideOn: [],
            columnName: 'name',
        },
        {
            name: t('backoffice.languages.form.default'),
            renderCell: (row) => {
                return <CheckBox checked={row.isDefault} disabled />;
            },
            width: '20%',
            hideOn: [],
            columnName: 'is_default',
        },
        {
            name: '',
            renderCell: renderActionCell,
            width: 'fill',
            columnName: '',
            preventClick: true,
        },
    ];

    return (
        <div>
            <BackofficeTable
                columns={LanguagesColumn}
                rows={languages}
                totalItems={totalItems}
                currentPage={criteria.page}
                changePage={changePage}
                orderTable={orderTable}
                navigateTo={`${ApplicationPaths.Backoffice}/languages/create`}
                initialOrder={{ columnName: 'name', isOrderAsc: true }}
                onRowClick={row => navigate(`${ApplicationPaths.Backoffice}/languages/details/${row.id}`)}
                showAddButton={canWrite}
            />
            <QuestionYesNo
                onNo={onCancelRemove}
                onYes={removeItem}
                isVisible={showRemoveModal}
                message={t('shared_translations.messages.remove_record_with_ident', {
                    name: itemToRemove?.name ?? '',
                })}
            />
        </div>
    );
};

export default LanguageTab;
