import LanguageDto from 'api/languages/models/LanguageDto';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { FaExclamationTriangle, FaLanguage } from 'react-icons/fa';
import styles from './LanguagesTabs.module.scss';

interface Props {
    selectedLanguageIndex: number;
    languages: LanguageDto[];
    onChange: (language: LanguageDto, i: number) => void;
    children: ReactNode
    showTab?: boolean
};

function LanguagesTab ({ selectedLanguageIndex, languages, onChange, showTab = true, children }: Props): JSX.Element {
    const { t } = useTranslation();

    return (
        <div>
            {showTab && <div className={styles.tabs}>
                {languages.map((lang, i) => (
                    <div
                        key={lang.id}
                        className={`${styles.tab} ${i === selectedLanguageIndex ? styles.active : ''}`}
                        onClick={() => onChange(lang, i)}
                    >
                        {lang.invalid && <FaExclamationTriangle className={styles.invalid} />}
                        {lang.isDefault && <FaLanguage className={styles.languageDefault} title={t('shared_translations.common.language_default')} />}
                        {lang.name}
                    </div>
                ))}
            </div>
            }
            <div>
                {children}
            </div>

        </div>
    );
};

export default LanguagesTab;
