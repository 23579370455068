import React from 'react';
import styles from './ButtonsDiv.module.scss';

interface Props {
    children: any
    style?: any
}

const ButtonsDiv = (props: Props) => (
    <div className={styles.buttonsDiv} style={props.style}>
        {props.children}
    </div>
);

export default ButtonsDiv;
