import { Paged } from 'api/common/types/Page';
import { PagedSearchCriteria } from 'api/common/types/PagedSearchCriteria';
import CustomFile from 'common/models/CustomFile';
import Http from 'common/services/Http';
import { ContentConfigurationDto } from './models/ContentConfigurationDto';

class ContentConfigurationsService {
    public update (model: ContentConfigurationDto, mediaFile: CustomFile | null) {
        return Http.putWithFile('contentconfigurations', model, mediaFile ? [mediaFile] : []);
    }

    public getAllWithMedia (criteria: PagedSearchCriteria) {
        return Http.get<Paged<ContentConfigurationDto>>('contentconfigurations/all-with-media', criteria);
    }

    public getById (id: string) {
        return Http.get<ContentConfigurationDto>('contentconfigurations/' + id);
    }
}

export default new ContentConfigurationsService();
