import { IconContext } from 'react-icons';
import { CiWarning } from 'react-icons/ci';

const WarningToast = () => {
    return (<IconContext.Provider
        value={{ color: '#FFBF00', size: '1.75rem' }}
    >
        <div>
            <CiWarning />
        </div>
    </IconContext.Provider>
    );
};

export default WarningToast;
