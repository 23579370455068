import { Col, Row } from 'react-flexbox-grid';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE, ApplicationPaths, HTTP_ERRORS } from 'Config';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import Button, { Color } from 'common/components/buttons/Button';
import FormItem from 'common/components/formItem/FormItem';
import Input from 'common/components/inputs/input/Input';
import Label from 'common/components/label/Label';
import styles from './RoleScreen.module.scss';
import Navbar from 'common/layouts/navBar/Navbar';
import { BreadCrumb } from 'common/types/BreadCrumb';
import ContentLayout from 'common/layouts/contentLayout/ContentLayout';
import RolesService from 'api/roles/RolesService';
import { RoleDto } from 'api/roles/models/RoleDto';
import WarningToast from 'common/components/warningToast/WarningToast';
import { Tab } from 'screens/backoffice/BackofficeScreen';
import InputError from 'common/components/inputs/inputError/InputError';
import ButtonsDiv from 'common/components/buttons/buttonsDiv/ButtonsDiv';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import hasPolicies from 'common/utils/hasPolicies';

const Types = {
    create: 'create',
    edit: 'edit',
    details: 'details',
};

const RoleScreen = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id, type } = useParams<{ id: string; type: string }>();
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = hasPolicies(userProfile, ['SETTINGUP_ROLES_WRITE']);
    const { register, handleSubmit, setValue, formState } = useForm<RoleDto>();
    const errors = formState.errors;
    const [roleToRemove, setRoleToRemove] = useState<RoleDto | null>(null);

    const breadCrumbs: BreadCrumb[] = [
        {
            text: t('menu.backoffice'),
            url: ApplicationPaths.Backoffice,
        },
        {
            text: t('backoffice.roles.role_profile'),
        },
    ];

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [isDetails, setIsDetails] = useState<boolean>(
        !!(type && type === Types.details)
    );

    useEffect(() => {
        void getData();
    }, [id, type]);

    const getData = async () => {
        if (id && id != null) {
            const role = await RolesService.getById(id);
            setValues(role);
        }
    };

    const setValues = (role: RoleDto) => {
        setIsDetails(!!(type && type === Types.details));
        setRoleToRemove(role);
        setValue('id', role.id);
        setValue('realName', role.realName);
    };

    const onSubmit = async (role: RoleDto) => {
        try {
            Loading.show();
            if (id && id != null) {
                await RolesService.update(role);
                toast.success(`${t('shared_translations.messages.record_save_success')}`);
                navigateTo();
            } else {
                await RolesService.create(role);
                toast.success(`${t('shared_translations.messages.record_save_success')}`);
                navigateTo();
            }
        } catch (error) {
            if ((error as any).response.status === HTTP_ERRORS.CONFLICT) {
                toast(`${t('backoffice.roles.roles_name_conflict_message')}`, {
                    icon: <WarningToast />,
                });
                Loading.hide();
            } else {
                Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't get roles list", error);
                toast.error(`${t('shared_translations.messages.record_save_error')}`);
                Loading.hide();
            }
        }
    };

    const onError = async () => {
        if (errors && errors.name?.type === 'required') {
            toast(`${t('shared_translations.messages.required_fields_empty')}`, {
                icon: <WarningToast />,
            });
        }
    };

    const showRemoveItemDialog = async () => {
        setShowRemoveModal(true);
    };

    const onCancelRemove = () => {
        setShowRemoveModal(false);
    };

    const removeItem = async () => {
        if (id && id != null && roleToRemove) {
            try {
                Loading.show();
                await RolesService.remove(roleToRemove);
                toast.success(`${t('shared_translations.messages.record_delete_success')}`);
                Loading.hide();
                navigateTo();
            } catch (error) {
                setShowRemoveModal(false);
                toast.error(`${t('shared_translations.messages.record_delete_error')}`);
                Loading.hide();
            }
        }
    };

    const navigateTo = (typeUrl?: string, idToGo?: string) => {
        if (typeUrl) {
            navigate(`${ApplicationPaths.Backoffice}/roles/${typeUrl}/${idToGo}`);
            setIsDetails(typeUrl === Types.details);
        } else {
            navigate(ApplicationPaths.Backoffice, { state: { tab: Tab.ROLES } });
        }
    };

    if (!canWrite && type !== Types.details) {
        return <Navigate replace={true} to={ApplicationPaths.Backoffice} state={{ tab: Tab.ROLES }} />;
    }

    return (
        <>
            <Navbar breadCrumbs={breadCrumbs} isBackoffice={true}/>
            <ContentLayout>
                <form className={styles.form} onSubmit={handleSubmit(onSubmit, onError)}>
                    <Row>
                        <Col xs={12}>
                            <ButtonsDiv>
                                <Button
                                    text={t('shared_translations.common.go_back')}
                                    onClick={() => navigateTo()}
                                    color={Color.white}
                                />
                                {type !== Types.create && canWrite && (
                                    <Button
                                        text={t('shared_translations.common.delete')}
                                        onClick={() => showRemoveItemDialog()}
                                        color={Color.red}
                                    />
                                )}
                                {type === Types.details && canWrite && (
                                    <Button
                                        text={t('shared_translations.common.edit')}
                                        onClick={() => {
                                            navigateTo('edit', id);
                                        }}
                                        color={Color.black}
                                    />
                                )}

                                {type !== Types.details && (
                                    <Button
                                        text={
                                            type === Types.create
                                                ? t('shared_translations.common.register')
                                                : t('shared_translations.common.save')
                                        }
                                        type="submit"
                                        color={Color.black}
                                    />
                                )}
                            </ButtonsDiv>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <FormItem>
                                <Label required={!!errors.realName}>{t('backoffice.roles.form.name') + '*'}</Label>
                                <Input
                                    type="text"
                                    register={register}
                                    name="realName"
                                    disabled={isDetails}
                                    required={true}
                                    maxLength={250}
                                    showRequired={!!errors.realName}
                                    pattern="[^A-Za-z0-9_ \-]"
                                />
                                <InputError error={errors.realName} maxLength={250} />
                            </FormItem>

                        </Col>
                    </Row>
                </form>
                <QuestionYesNo
                    onNo={onCancelRemove}
                    onYes={removeItem}
                    isVisible={showRemoveModal}
                    message={t('shared_translations.messages.remove_record_with_ident', {
                        name: roleToRemove?.realName ?? '',
                    })}
                />
            </ContentLayout>
        </>
    );
};

export default RoleScreen;
