import React from 'react';
import styles from './Dropdown.module.scss';
import DropdownWrapper from './DropdownWrapper';
import { DropdownWrapperChildFunc } from '../../types/ReactDropdownWrapper';

interface Props {
    children: React.ReactNode;
    options: React.ReactNode | React.ReactNode[];
}

const Dropdown = ({ children, options }: Props) => {
    return (
        <DropdownWrapper
            closeOnOutsideClick >
            {({ changeStatus, isShow }: DropdownWrapperChildFunc) => (
                <>
                    <div className={styles.icon} onClick={e => {
                        e.preventDefault();
                        changeStatus(!isShow);
                    }}>{children}</div>
                    {isShow && (
                        <div className={styles.dropdownContainer}>
                            {options}
                        </div>
                    )}
                </>
            )}
        </DropdownWrapper>
    );
};

export default Dropdown;
