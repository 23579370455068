import { RefObject, useEffect } from 'react';

const useClickOutsideMultiple = <T extends HTMLElement>(refs: RefObject<T>[], fn: () => void, deps: any[] = []) => {
    useEffect(() => {
        function handleClickOutside (event: Event) {
            let clickedInside = false;

            const elements = refs.map(x => x.current) ?? [];
            for (const el of elements) {
                if (el?.contains(event.target as Node | null)) {
                    clickedInside = true;
                }
            }

            if (!clickedInside) {
                fn();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [refs, ...deps]);
};

export default useClickOutsideMultiple;
