import React, { useEffect, useState } from 'react';
import { QueryParameterNames, ApplicationPaths, LogoutActions } from '../../Config';
import authService, { AuthenticationResultStatus } from '../../common/services/AuthorizeService';
import { useDispatch } from 'react-redux';
import { logout as signOut } from '../../store/authentication/action';
import { AppDispatch } from 'store/store';

interface Props {
    action: string
}

interface LogoutState {
    message?: string
    authenticated?: boolean
}

const IdentityLogoutScreen = ({ action }: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const [, setLogoutInfo] = useState<LogoutState>({ message: undefined, authenticated: false });

    useEffect(() => {
        switch (action) {
            case LogoutActions.Logout:
                void logout(getReturnUrl(null));
                break;
            case LogoutActions.LogoutCallback:
                void processLogoutCallback();
                break;
            case LogoutActions.LoggedOut:
                setLogoutInfo({ message: 'You successfully logged out!' });
                break;
            default:
                throw new Error(`Invalid action '${action}'`);
        }
        void populateAuthenticationState();
    }, []);

    const logout = async (returnUrl: any) => {
        const state = { returnUrl };
        const isAuthenticated = await authService.isAuthenticated();
        if (isAuthenticated) {
            const result = await authService.signOut(state);
            switch (result.status) {
                case AuthenticationResultStatus.Redirect:
                    break;
                case AuthenticationResultStatus.Success:
                    navigateToReturnUrl(returnUrl);
                    break;
                case AuthenticationResultStatus.Fail:
                    setLogoutInfo({ message: (result as { status: string, message: string }).message });
                    break;
                default:
                    throw new Error('Invalid authentication result status.');
            }
        } else {
            setLogoutInfo({ message: 'You successfully logged out!' });
        }
    };

    const processLogoutCallback = async () => {
        const url = window.location.href;
        const result = await authService.completeSignOut(url);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                // There should not be any redirects as the only time completeAuthentication finishes
                // is when we are doing a redirect sign in flow.
                throw new Error('Should not redirect.');
            case AuthenticationResultStatus.Success:
                dispatch(signOut() as any);
                navigateToReturnUrl(getReturnUrl((result as { status: string, state: any }).state));
                break;
            case AuthenticationResultStatus.Fail:
                setLogoutInfo({ message: (result as { status: string, message: string }).message });
                break;
            default:
                throw new Error('Invalid authentication result status.');
        }
    };

    const populateAuthenticationState = async () => {
        const authenticated = await authService.isAuthenticated();
        setLogoutInfo({ authenticated });
    };

    const getReturnUrl = (state: any) => {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery != null && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error('Invalid return url. The return url needs to have the same origin as the current page.');
        }
        return state?.returnUrl ?? fromQuery ?? `${window.location.origin}${ApplicationPaths.LoggedOut}`;
    };

    const navigateToReturnUrl = (returnUrl: any) => window.location.replace(returnUrl);

    return (
        <div></div>

    );
};
export default IdentityLogoutScreen;
