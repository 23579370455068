import styles from './CommentFavorites.module.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from 'assets/svg/pencil.svg';
import { ReactComponent as TrashIcon } from 'assets/svg/trash.svg';
import { ReactComponent as SaveIcon } from 'assets/svg/check.svg';
import { ReactComponent as AddCircleIcon } from 'assets/svg/addcircle.svg';
import { ReactComponent as CloseCircleIcon } from 'assets/svg/close-circle.svg';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import Loading from 'common/services/Loading';
import InspirationDto from 'api/inspirations/models/InspirationDto';
import InspirationCommentDto from 'api/inspirationComments/models/InspirationCommentDto';
import { ClientDto } from 'api/clients/models/ClientDto';
import { Col, Row } from 'react-flexbox-grid';
import moment from 'moment';
import useIsMobile from 'common/hooks/useIsMobile';
import InputWithButton from 'common/components/inputs/inputWithButton/InputWithButton';

interface Props {
    onDelete: (model: InspirationCommentDto) => void;
    onSaveComment: (model: InspirationCommentDto) => void;
    inspiration: InspirationDto | null;
    selectedClient: ClientDto;
};

const CommentFavorites = ({ onDelete, onSaveComment, inspiration, selectedClient }: Props) => {
    const { t } = useTranslation();

    const [editCommentId, setEditCommentId] = useState<string | null>(null);
    const [itemToRemove, setItemToRemove] = useState<InspirationCommentDto>();
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

    const [addNewComment, setAddNewComment] = useState(false);
    const [newComment, setNewComment] = useState<string>();
    const [comment, setComment] = useState<string>();
    const [hasCommentError, setHasCommentError] = useState(false);

    const isMobile = useIsMobile();

    const onCancelRemove = () => {
        setShowRemoveModal(false);
    };

    const removeComment = async () => {
        if (itemToRemove) {
            try {
                Loading.show();
                onDelete(itemToRemove);
                Loading.hide();
                setShowRemoveModal(false);
            } catch (error) {
                setShowRemoveModal(false);
                Loading.hide();
            }
        }
    };

    const saveComment = async (item?: InspirationCommentDto, commentText?: string) => {
        let c: InspirationCommentDto;
        setNewComment(undefined);

        if (item?.id) {
            c = {
                id: item.id,
                comment: commentText ?? item.comment,
                inspirationId: inspiration?.masterRecordId ?? '',
                clientId: selectedClient?.id ?? '',
                languageId: inspiration?.languageId ? inspiration?.languageId : '',
                masterRecordId: item.masterRecordId
            };
            setEditCommentId(null);
        } else {
            c = {
                id: '',
                comment: commentText ?? '',
                inspirationId: inspiration?.masterRecordId ?? '',
                clientId: selectedClient?.id ?? '',
                languageId: inspiration?.languageId ? inspiration?.languageId : ''
            };
            setAddNewComment(false);
        }

        onSaveComment(c);
    };

    const renderLabel = () => {
        if (addNewComment) {
            return t('home_screen.inspirations.add_comment');
        } else {
            if (inspiration?.comments) {
                return t('home_screen.inspirations.comments');
            } else {
                return t('home_screen.inspirations.without_comments');
            }
        }
    };

    const renderCommentsHeader = () => {
        return (
            <div className={styles.commentHeaderContainer} style={{ marginBottom: (!inspiration?.comments && addNewComment) || (!!inspiration?.comments) ? '1rem' : undefined }} >
                <div className={styles.title}>
                    {renderLabel()}
                </div>
                {addNewComment ? <CloseCircleIcon onClick={() => setAddNewComment(false)} style={{ cursor: 'pointer' }} /> : <AddCircleIcon onClick={() => setAddNewComment(true)} style={{ cursor: 'pointer' }} />}
            </div>

        );
    };

    const renderAddComment = () => {
        return (
            <div style={{ marginBottom: '1rem' }}>
                <InputWithButton
                    lightInput={true}
                    style={{ minWidth: isMobile ? '15rem' : '30rem ' }}
                    placeholder={t('home_screen.inspirations.write_here')}
                    onChange={(e) => {
                        setComment(e.target.value);
                        setHasCommentError(false);
                    }}
                    onButtonClick={() => {
                        if (comment && comment !== ' ') {
                            void saveComment(undefined, comment);
                            setAddNewComment(false);
                            setComment(undefined);
                        } else {
                            setHasCommentError(true);
                        }
                    }}
                />
                {hasCommentError && <div className={styles.messageError}>
                    {t('shared_translations.messages.required_field')}
                </div>}
            </div>
        );
    };

    return (
        <div className={styles.container} >
            {inspiration?.comments
                ? <> {renderCommentsHeader()}
                    {addNewComment && renderAddComment()}

                    {inspiration.comments.map((item, index) => {
                        const keyName = `comment.${index}`;
                        const isEditing = Boolean(editCommentId && item.id === editCommentId);
                        return (
                            <div key={keyName} className={styles.comment}>
                                <Row>
                                    <Col xl={10} xs={9}>
                                        <div className={styles.info_text}>
                                            <span>
                                                {moment(item.createdDate).format('DD/MM/YYYY  | HH:mm')}
                                            </span>
                                            <span style={{ marginLeft: '1rem' }}>
                                                {item.userName}
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xl={2} xs={3} className={styles.infoContainer}>
                                        <div className={styles.buttonsContainer}>
                                            {isEditing
                                                ? <div className={styles.button} onClick={() => saveComment(item, newComment)} ><SaveIcon /></div>
                                                : <div className={styles.button} onClick={() => setEditCommentId(editCommentId ? null : item.id)} ><EditIcon /></div>}

                                            <div className={styles.button} style={{ marginLeft: '1rem' }} onClick={() => {
                                                setItemToRemove(item);
                                                setShowRemoveModal(true);
                                            }}>
                                                <TrashIcon />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <div style={{ marginBottom: '0.5rem' }}>
                                    <div contentEditable={isEditing} suppressContentEditableWarning={true}
                                        onInput={e => setNewComment(e.currentTarget.textContent ?? undefined)}
                                        className={isEditing ? styles.commentContainer : undefined}
                                    >
                                        {item.comment}
                                    </div>
                                </div>
                            </div>);
                    })}
                </>
                : <>  {renderCommentsHeader()}
                    {addNewComment && renderAddComment()}
                </>
            }
            <QuestionYesNo
                onNo={onCancelRemove}
                onYes={removeComment}
                isVisible={showRemoveModal}
                message={t('shared_translations.messages.remove_record_with_ident', { name: '' })} />

        </div >

    );
};

export default CommentFavorites;
