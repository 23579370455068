import { UserProfile } from 'api/account/models/UserProfile';
import hasPolicies from 'common/utils/hasPolicies';
import { POLICIES } from 'Config';

interface Props {
    user: UserProfile | null;
    policies: (keyof typeof POLICIES)[];
    type?: 'OR' | 'AND';
    children: JSX.Element;
}

function IfPolicy ({ user, policies, type = 'OR', children }: Props): JSX.Element | null {
    if (!hasPolicies(user, policies ?? [], type)) {
        return null;
    }

    return children;
}

export default IfPolicy;
