import { UserManager, WebStorageStateStore, User } from 'oidc-client';
import { ApplicationPaths, ApplicationName } from '../../Config';

export const AuthenticationResultStatus = {
    Redirect: 'redirect',
    Success: 'success',
    Fail: 'fail'
};

export class AuthorizeService {
    private userManager: UserManager | undefined = undefined;
    private readonly _callbacks: any = [];
    private readonly _nextSubscriptionId = 0;
    private _user: User | undefined | null = null;
    private _isAuthenticated = false;
    private readonly _popUpDisabled = true;

    public createArguments = (state: any) => ({ useReplaceToNavigate: true, data: state });
    public error = (message: any) => ({ status: AuthenticationResultStatus.Fail, message });
    public success = (state: any, user: User | undefined) => ({ status: AuthenticationResultStatus.Success, state, user });
    public redirect = () => ({ status: AuthenticationResultStatus.Redirect });

    public async isAuthenticated () {
        const user = await this.getUser();
        return user != null;
    }

    public async getUser () {
        if ((this._user?.profile) != null) {
            return this._user.profile;
        }
        await this.ensureUserManagerInitialized();
        const user = await this.userManager?.getUser();
        return user?.profile;
    }

    public async getAccessToken () {
        await this.ensureUserManagerInitialized();
        const user = await this.userManager?.getUser();
        return user?.access_token;
    }

    public async signIn (state: any) {
        await this.ensureUserManagerInitialized();
        try {
            await this.userManager?.signinRedirect(this.createArguments(state));
            return this.redirect();
        } catch (redirectError) {
            return this.error(redirectError);
        }
    }

    public async completeSignIn (url: any) {
        try {
            await this.ensureUserManagerInitialized();
            const user = await this.userManager?.signinCallback(url);
            this.updateState(user);
            return this.success(user?.state, user);
        } catch (error) {
            return this.error('There was an error signing in.');
        }
    }

    async signOut (state: any) {
        await this.ensureUserManagerInitialized();
        try {
            await this.userManager?.signoutRedirect(this.createArguments(state));
            return this.redirect();
        } catch (redirectSignOutError) {
            return this.error(redirectSignOutError);
        }
    }

    async completeSignOut (url: any) {
        await this.ensureUserManagerInitialized();
        try {
            const response = await this.userManager?.signoutCallback(url);
            this.updateState(null);
            return this.success(response?.state, undefined);
        } catch (error) {
            return this.error(error);
        }
    }

    updateState (user: User | undefined | null) {
        this._user = user;
        this._isAuthenticated = this._user != null;
    }

    async ensureUserManagerInitialized () {
        if (this.userManager !== undefined) {
            return;
        }

        const response = await fetch(ApplicationPaths.ApiAuthorizationClientConfigurationUrl);

        if (!response.ok) {
            throw new Error(`Could not load settings for '${ApplicationName}'`);
        }

        const settings = await response.json();
        settings.automaticSilentRenew = true;
        settings.includeIdTokenInSilentRenew = true;
        settings.userStore = new WebStorageStateStore({
            prefix: ApplicationName
        });

        this.userManager = new UserManager(settings);

        this.userManager.events.addUserSignedOut(() => {
            this.userManager?.removeUser().then(() => this.updateState(undefined)).catch(() => {});
        });
    }

    static get instance () { return authService; }
}

const authService = new AuthorizeService();

export default authService;
