import { useMemo, useRef, useState } from 'react';
import CanvasImage, { canvasColors, canvasDefaultPencilColor, canvasDefaultPencilSize, canvasDefaultTextColor, canvasDefaultTextSize, CanvasDrawingMode, CanvasImageRef, canvasPencilSizes, canvasTextSizes } from '../canvasImage/CanvasImage';
import DropdownButton from '../dropdownButton/DropdownButton';
import { DropdownColorSizeValue } from '../dropdownButton/DropdownColorSizeContent';
import { ReactComponent as TextIcon } from 'assets/svg/text.svg';
import { ReactComponent as CursorIcon } from 'assets/svg/arrow-pointer-solid.svg';
import { ReactComponent as SaveIcon } from 'assets/svg/save_dyn.svg';
import { FaCheck, FaFileImport, FaHandPaper, FaSearchMinus, FaSearchPlus, FaTrash } from 'react-icons/fa';
import { ReactComponent as EditIcon } from 'assets/svg/pencil-dyn.svg';
import { ReactComponent as CancelIcon } from 'assets/svg/x_mobile_dyn.svg';
import styles from './CanvasModal.module.scss';
import useDimensions from 'react-cool-dimensions';
import throttle from 'lodash.throttle';
import { Portal } from 'react-portal';
import { ResizeObserver } from '@juggle/resize-observer';
import ImageCrop, { ImageCropRef } from 'common/components/imageCrop/ImageCrop';
import Loading from 'common/services/Loading';

const buttonActiveColor = '#ffffff';
const buttonInactiveColor = '#c0c0c0';
const throttleDelay = 1000;

export enum CanvasModalMode {
    DEFAULT = 'DEFAULT',
    FROM_PINTEREST = 'FROM_PINTEREST',
}

enum CanvasModalModeStep {
    VIEW = 'VIEW',
    CROP = 'CROP',
}

interface Props {
    url: string;
    drawingMode?: CanvasDrawingMode;
    drawingData?: string | null;
    showButtons?: boolean;
    mode?: CanvasModalMode;
    canImport?: boolean;
    onSaveDrawing?: (data: string, imageUrl: string) => void;
    onChangeDrawingMode?: (dm: CanvasDrawingMode) => void;
    onCancelEdition?: () => void;
}

const CanvasModal: React.FC<Props> = ({
    url, drawingMode, drawingData, onChangeDrawingMode, onCancelEdition, onSaveDrawing, showButtons = true,
    mode = CanvasModalMode.DEFAULT, canImport = true
}: Props) => {
    const canvasImageRef = useRef<CanvasImageRef | null>(null);
    const [pencilColorSize, setPencilColorSize] = useState<DropdownColorSizeValue>({ color: canvasDefaultPencilColor, size: canvasDefaultPencilSize });
    const [textColorSize, setTextColorSize] = useState<DropdownColorSizeValue>({ color: canvasDefaultTextColor, size: canvasDefaultTextSize });
    const [anySelectedDrawingObject, setAnySelectedDrawingObject] = useState(false);
    const [imageWidthBase, setImageWidthBase] = useState<number | undefined>(undefined);
    const [imageHeightBase, setImageHeightBase] = useState<number | undefined>(undefined);
    const { observe: imageRef } = useDimensions({
        polyfill: ResizeObserver,
        onResize: useMemo(
            () =>
                throttle((e: any) => {
                    setImageWidthBase(e.width);
                    setImageHeightBase(e.height);
                }, throttleDelay),
            []
        ),
    });
    const [modeStep, setModeStep] = useState<CanvasModalModeStep | null>(mode === CanvasModalMode.FROM_PINTEREST ? CanvasModalModeStep.VIEW : null);
    const imageCropRef = useRef<ImageCropRef | null>(null);
    const [croppedUrl, setCroppedUrl] = useState<string | null>(null);

    const imageWidth = imageWidthBase;// ? Math.floor(imageWidthBase) : undefined;
    const imageHeight = imageHeightBase;// ? Math.floor(imageHeightBase) : undefined;

    const stopDrawing = () => {
        if (onChangeDrawingMode) {
            onChangeDrawingMode('none');
        }

        if (onCancelEdition) {
            onCancelEdition();
        }
    };

    const onCancelDrawing = () => {
        if (canvasImageRef.current) {
            canvasImageRef.current.revertData(drawingData);
        }

        stopDrawing();
    };

    const onDrawingButtonClick = (dm: CanvasDrawingMode) => {
        if (onChangeDrawingMode) {
            onChangeDrawingMode(dm);
        }
    };

    const onSaveDrawingClick = () => {
        if (!canvasImageRef.current || !onSaveDrawing) {
            return;
        }

        const data = canvasImageRef.current.getData();
        onSaveDrawing(data, croppedUrl ?? url);
        stopDrawing();
    };

    const onFinishCrop = async () => {
        if (!imageCropRef.current) {
            return;
        }

        Loading.show();

        imageCropRef.current.getImageFile((_f, dataUrl) => {
            setCroppedUrl(dataUrl);
            setModeStep(null);
            Loading.hide();
        }, () => {
            Loading.hide();
        });
    };

    const onDeleteDrawingClick = () => {
        if (!canvasImageRef.current) {
            return;
        }

        canvasImageRef.current.deleteSelectedObject();
        setAnySelectedDrawingObject(false);
    };

    const onZoomInButtonClick = () => {
        if (!canvasImageRef.current) {
            return;
        }

        canvasImageRef.current.zoomIn();
    };

    const onZoomOutButtonClick = () => {
        if (!canvasImageRef.current) {
            return;
        }

        canvasImageRef.current.zoomOut();
    };

    const onEnableCrop = () => {
        setModeStep(CanvasModalModeStep.CROP);
    };

    return (
        <Portal>
            <div className={styles.overlay}></div>
            <div className={styles.innerContainer}>
                <img
                    src={croppedUrl ?? url}
                    ref={imageRef}
                    className={styles.hiddenImage}
                />

                {imageWidth && imageHeight && modeStep !== CanvasModalModeStep.CROP && <CanvasImage
                    url={croppedUrl ?? url}
                    width={imageWidth}
                    height={imageHeight}
                    drawingMode={drawingMode ?? 'none'}
                    onChangeDrawingMode={onChangeDrawingMode}
                    ref={canvasImageRef}
                    drawingData={drawingData ?? null}
                    pencilColorSize={pencilColorSize}
                    textColorSize={textColorSize}
                    onSelectObject={setAnySelectedDrawingObject}
                    // className={styles.canvasImage}
                />}

                {modeStep === CanvasModalModeStep.CROP && (
                    <div
                        className={styles.imageCropContainer}
                        style={{
                            width: imageWidth,
                            height: imageHeight,
                        }}
                    >
                        <ImageCrop
                            rectangularShape={true}
                            circularCrop={false}
                            imageUrl={url}
                            maxWidth={imageWidth}
                            maxHeight={imageHeight}
                            ref={imageCropRef}
                        />
                    </div>
                )}

                <div className={styles.buttonsContainer} style={{ width: imageWidth }}>
                    <div className={styles.buttons}></div>
                    {showButtons && <div className={`${styles.buttons} ${styles.buttonsCenter}`}>
                        {!modeStep && <DropdownButton
                            onClick={() => onDrawingButtonClick('selection')}
                            active={drawingMode === 'selection'}
                            activeColor={buttonActiveColor}
                            inactiveColor={buttonInactiveColor}
                        >
                            <CursorIcon fill={drawingMode === 'selection' ? buttonActiveColor : buttonInactiveColor} width={24} height={24} />
                        </DropdownButton>}
                        <DropdownButton
                            onClick={() => onDrawingButtonClick('move')}
                            active={drawingMode === 'move'}
                            activeColor={buttonActiveColor}
                            inactiveColor={buttonInactiveColor}
                        >
                            <FaHandPaper size={19} color={drawingMode === 'move' ? buttonActiveColor : buttonInactiveColor} />
                        </DropdownButton>
                        {!modeStep && <div className={styles.buttonSpacer}></div>}
                        {!modeStep && <DropdownButton
                            dropdown='color-size'
                            onClick={() => onDrawingButtonClick('pencil')}
                            value={pencilColorSize}
                            onChange={setPencilColorSize}
                            colors={canvasColors}
                            sizes={canvasPencilSizes}
                            active={drawingMode === 'pencil'}
                            activeColor={pencilColorSize.color}
                            inactiveColor={buttonInactiveColor}
                            dropdownClassName={styles.dropdown}
                        >
                            <EditIcon stroke={drawingMode === 'pencil' ? pencilColorSize.color : buttonInactiveColor} width={80} height={20} />
                        </DropdownButton>}
                        {!modeStep && <DropdownButton
                            dropdown='color-size'
                            onClick={() => onDrawingButtonClick('text')}
                            value={textColorSize}
                            onChange={setTextColorSize}
                            colors={canvasColors}
                            sizes={canvasTextSizes}
                            active={drawingMode === 'text'}
                            activeColor={textColorSize.color}
                            inactiveColor={buttonInactiveColor}
                            dropdownClassName={styles.dropdown}
                        >
                            <TextIcon stroke={drawingMode === 'text' ? textColorSize.color : buttonInactiveColor} width={80} height={20} />
                        </DropdownButton>}
                        <div className={styles.buttonSpacer}></div>
                        <DropdownButton
                            onClick={() => onZoomInButtonClick()}
                            active={true}
                            activeColor={buttonActiveColor}
                            inactiveColor={buttonInactiveColor}
                        >
                            <FaSearchPlus size={19} color={buttonActiveColor} />
                        </DropdownButton>
                        <DropdownButton
                            onClick={() => onZoomOutButtonClick()}
                            active={true}
                            activeColor={buttonActiveColor}
                            inactiveColor={buttonInactiveColor}
                        >
                            <FaSearchMinus size={19} color={buttonActiveColor} />
                        </DropdownButton>
                    </div> }
                    <div className={`${styles.buttons} ${styles.buttonsRight}`}>
                        {anySelectedDrawingObject && showButtons && <DropdownButton
                            onClick={onDeleteDrawingClick}
                            active={true}
                            activeColor={buttonActiveColor}
                            inactiveColor={buttonInactiveColor}
                            iconContainerStyle={{ padding: 0 }}
                        >
                            <FaTrash size={19} />
                        </DropdownButton>}
                        {showButtons && !modeStep && <DropdownButton
                            onClick={onSaveDrawingClick}
                            active={true}
                            activeColor={buttonActiveColor}
                            inactiveColor={buttonInactiveColor}
                        >
                            <SaveIcon width={20} height={20} stroke={buttonActiveColor} />
                        </DropdownButton> }
                        {showButtons && modeStep === CanvasModalModeStep.CROP && <DropdownButton
                            onClick={onFinishCrop}
                            active={true}
                            activeColor={buttonActiveColor}
                            inactiveColor={buttonInactiveColor}
                        >
                            <FaCheck size={18} />
                        </DropdownButton>}
                        {showButtons && modeStep === CanvasModalModeStep.VIEW && canImport && <DropdownButton
                            onClick={onEnableCrop}
                            active={true}
                            activeColor={buttonActiveColor}
                            inactiveColor={buttonInactiveColor}
                        >
                            <FaFileImport size={18} />
                        </DropdownButton>}
                        <DropdownButton
                            onClick={onCancelDrawing}
                            active={true}
                            activeColor={buttonActiveColor}
                            inactiveColor={buttonInactiveColor}
                            iconContainerStyle={{ padding: 0 }}
                        >
                            <CancelIcon stroke={buttonActiveColor} width={28} height={28} />
                        </DropdownButton>
                    </div>
                </div>
            </div>
        </Portal>
    );
};

export default CanvasModal;
