import styles from './DropdownColorSizeContent.module.scss';

export interface DropdownColorSizeValue {
    color: string;
    size: number;
}

interface Props {
    value: DropdownColorSizeValue;
    sizes: number[];
    colors: string[];
    onChange: (value: DropdownColorSizeValue) => void;
}

function DropdownColorSizeContent ({ value, onChange, colors, sizes }: Props): JSX.Element {
    return (
        <div className={styles.container}>
            <div className={styles.side}>
                {colors.map((c, i) => (
                    <div
                        key={i}
                        className={`${styles.colorBox} ${value.color === c ? styles.colorActive : ''}`}
                        style={{ backgroundColor: c }}
                        onClick={() => onChange({ ...value, color: c })}
                    ></div>
                ))}
            </div>
            <div className={styles.side}>
                {sizes.map((s, i) => (
                    <div
                        key={i}
                        className={`${styles.sizeBox} ${value.size === s ? styles.sizeActive : ''}`}
                        onClick={() => onChange({ ...value, size: s })}
                    >
                        {s}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default DropdownColorSizeContent;
