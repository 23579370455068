import { Action } from './../authentication/type';
import Storage from '../../common/services/Storage';
import { STORAGE } from '../../Config';

const initialState = {
    open: false,
    subcategorie: null
};

export default function (state = initialState, action: Action) {
    switch (action.type) {
        case 'showEditLayout':
            Storage.setObject(STORAGE.EDIT_LAYOUT, !state.open);
            return { ...state, open: !(state.open) };
        case 'initShowEditLayout':
            return { ...state, open: action.editLayout };
        default:
            return state;
    }
}
