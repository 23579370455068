import { PagedSearchCriteria } from 'api/common/types/PagedSearchCriteria';
import { UserDto } from 'api/users/models/UserDto';
import UsersService from 'api/users/UsersService';
import BackofficeTable, { BackofficeTableColumn } from 'common/components/backofficeTable/BackofficeTable';
import Loading from 'common/services/Loading';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, ApplicationPaths, LOGGER_LOG_TYPE } from 'Config';
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import Logger from 'common/services/Logger';
import { ReactComponent as ActionsIcon } from 'assets/svg/more-vertical.svg';
import { ReactComponent as AvatarIcon } from 'assets/svg/icon-user-client.svg';
import styles from './UserTab.module.scss';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/dropdown/DropdownItem';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserProfile } from 'api/account/models/UserProfile';
import { Reducers } from 'store/types';
import hasPolicies from 'common/utils/hasPolicies';

interface Props {
    searchString: string
    searchOrder: boolean
}

const UserTab = (props: Props) => {
    const { searchString, searchOrder } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = hasPolicies(user, ['SETTINGUP_USERS_WRITE']);
    const [users, setUsers] = useState<UserDto[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [criteria, setCriteria] = useState<PagedSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderBy: 'asc',
        orderColumn: 'real_name',
    });

    let allInField = searchString;
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<UserDto>({
        id: '',
        realName: '',
        email: '',
    });

    useEffect(() => {
        allInField = searchString;
        void getUserList(criteria.page === 1);
    }, [criteria]);

    useEffect(() => {
        setCriteria({ ...criteria, page: 1 });
        void getUserList(true);
    }, [searchOrder]);

    const showRemoveItemDialog = (item: UserDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    };

    const onCancelRemove = () => {
        setItemToRemove({
            id: '',
            realName: '',
            email: '',
        });
        setShowRemoveModal(false);
    };

    const removeItem = async () => {
        try {
            Loading.show();
            await UsersService.remove(itemToRemove);
            toast.success(`${t('shared_translations.messages.record_delete_success')}`);
            setShowRemoveModal(false);
            changePage(1);
            Loading.hide();
        } catch (error) {
            setShowRemoveModal(false);
            toast.error(`${t('shared_translations.messages.record_delete_error')}`);
            setShowRemoveModal(false);
            Loading.hide();
        }
    };

    const changePage = (page: number) => {
        setCriteria({ ...criteria, page });
    };

    const getUserList = async (reset = false) => {
        try {
            Loading.show();
            const page = await UsersService.getList({ ...criteria, allIn: allInField });
            if (reset) {
                setUsers(page.items);
            } else {
                setUsers([...users, ...page.items]);
            }
            setTotalItems(page.totalItems);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't get users list", error);
            toast.error(`${t('shared_translations.messages.error_load_info')}`);
            Loading.hide();
        }
    };

    const orderTable = async (orderField: string) => {
        const page = 1;
        const orderColumn = orderField;
        let orderBy: string;

        if (orderColumn !== criteria.orderColumn) {
            orderBy = 'asc';
        } else {
            if (criteria.orderBy === 'asc') {
                orderBy = 'desc';
            } else {
                orderBy = 'asc';
            }
        }

        setCriteria({ ...criteria, page, orderColumn, orderBy });
    };

    const renderActionCell = (row: UserDto) => {
        return (
            <div className={styles.optionsIcon}>
                <Dropdown
                    options={
                        <>
                            <DropdownItem url={`${ApplicationPaths.Backoffice}/users/details/${row.id}`}>
                                {t('shared_translations.common.details')}
                            </DropdownItem>
                            {canWrite &&
                                <DropdownItem url={`${ApplicationPaths.Backoffice}/users/edit/${row.id}`}>
                                    {t('shared_translations.common.edit')}
                                </DropdownItem>
                            }
                            {canWrite &&
                                <DropdownItem onClick={() => showRemoveItemDialog(row)}>
                                    {t('shared_translations.common.delete')}
                                </DropdownItem>
                            }
                        </>
                    }
                >
                    <div>
                        <ActionsIcon />
                    </div>
                </Dropdown>
            </div>
        );
    };

    const UsersColumns: BackofficeTableColumn<UserDto>[] = [
        {
            name: '',
            renderCell: (row) => row.mediaUrl ? <img className={styles.image} src={row.mediaUrl}/> : <AvatarIcon className={styles.image}/>,
            width: '5%',
            hideOn: ['sm', 'md'],
            columnName: '',
        },
        {
            name: t('backoffice.users.form.name'),
            renderCell: (row) => row.realName,
            width: '40%',
            hideOn: [],
            columnName: 'real_name',
        },
        {
            name: t('backoffice.users.form.phone'),
            renderCell: (row) => row.phoneNumber,
            width: '55%',
            hideOn: [],
            columnName: 'phone_number',
        },
        {
            name: '',
            renderCell: renderActionCell,
            width: 'fill',
            columnName: '',
            preventClick: true,
        },
    ];

    return (
        <div style={{ width: '100%' }}>
            <BackofficeTable
                columns={UsersColumns}
                rows={users}
                totalItems={totalItems}
                currentPage={criteria.page}
                changePage={changePage}
                orderTable={orderTable}
                navigateTo={`${ApplicationPaths.Backoffice}/users/create`}
                initialOrder={{ columnName: 'real_name', isOrderAsc: true }}
                onRowClick={row => navigate(`${ApplicationPaths.Backoffice}/users/details/${row.id}`)}
                showAddButton={canWrite}
            />
            <QuestionYesNo
                onNo={onCancelRemove}
                onYes={removeItem}
                isVisible={showRemoveModal}
                message={t('shared_translations.messages.remove_record_with_ident', {
                    name: itemToRemove?.realName ?? '',
                })}
            />
        </div>
    );
};

export default UserTab;
