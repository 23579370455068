import { CategorieDto, CategoriesListDto } from 'api/categories/models/CategorieDto';
import Button, { Color } from 'common/components/buttons/Button';
import FormItem from 'common/components/formItem/FormItem';
import ImageInput from 'common/components/inputs/imageInput/ImageInput';
import Input from 'common/components/inputs/input/Input';
import Label from 'common/components/label/Label';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import ContentLayout from 'common/layouts/contentLayout/ContentLayout';
import Navbar from 'common/layouts/navBar/Navbar';
import Loading from 'common/services/Loading';
import { BreadCrumb } from 'common/types/BreadCrumb';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE, ApplicationPaths } from 'Config';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import styles from './SubCategorieScreen.module.scss';
import WarningToast from 'common/components/warningToast/WarningToast';
import CategoriesService from 'api/categories/CategoriesService';
import SelectInput from 'common/components/inputs/SelectInput';
import InputError from 'common/components/inputs/inputError/InputError';
import ButtonsDiv from 'common/components/buttons/buttonsDiv/ButtonsDiv';
import LanguagesService from 'api/languages/LanguagesService';
import LanguageDto from 'api/languages/models/LanguageDto';
import LanguagesTab from 'common/components/languagesTabs/LanguagesTabs';

import { useSelector } from 'react-redux';
import CopyContent from 'common/components/copyContent/CopyContent';
import { UserProfile } from 'api/account/models/UserProfile';
import hasPolicies from 'common/utils/hasPolicies';
import { Reducers } from 'store/types';
import CustomFile from 'common/models/CustomFile';
import newGuid from 'common/utils/newGuid';
import { urlToFile } from 'common/utils/urlToFile';

const Types = {
    create: 'create',
    edit: 'edit',
    details: 'details',
};

function SubCategorieScreen (): JSX.Element {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = hasPolicies(userProfile, ['SETTINGUP_CATEGORIES_WRITE']);
    const { masterRecordParentId, masterRecordId, type } = useParams<{ masterRecordParentId: string; masterRecordId: string; type: string }>();

    const { register, handleSubmit, setValue, control, formState, reset, getValues, watch } = useForm<CategoriesListDto>({ shouldUnregister: false });

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

    const [categoriesParent, setCategoriesParent] = useState<CategorieDto[]>([]);
    const [selectedLanguageIndex, setSelectedLanguageIndex] = useState(0);
    const [languages, setLanguages] = useState<LanguageDto[]>([]);
    const [removeMessage, setRemoveMessage] = useState<string>('');

    const [attachments, setAttachments] = useState<CustomFile[]>([]);

    const breadCrumbs: BreadCrumb[] = [
        {
            text: t('menu.start'),
            url: ApplicationPaths.DefaultLoginRedirectPath,
        },
        {
            text: t('home_screen.categories.categorie_profile'),
        },
    ];

    const [isDetails, setIsDetails] = useState<boolean>(
        !!(type && type === Types.details)
    );

    const onChooseAttachment = (file: CustomFile, dataUrl: string, index: number) => {
        const key = newGuid();
        file.containerName = `${languages[index].id}__${key}`;
        setAttachments([...attachments, file]);
        setValue(`list.${index}.mediaUrl`, dataUrl);
    };

    const onDeleteAttachment = (index: number) => {
        setValue(`list.${index}.removeMedia`, true);
        setValue(`list.${index}.mediaUrl`, undefined);
    };

    useEffect(() => {
        void getData();
    }, [masterRecordId, type]);

    const getData = async () => {
        try {
            Loading.show();

            const listLanguages = await LanguagesService.getAll();
            listLanguages[0].languageTabVisited = true;
            setLanguages(listLanguages);

            let list: CategorieDto[] = [];
            const model: CategoriesListDto = { list: [] };

            if (type !== Types.create && masterRecordId && masterRecordId != null) {
                list = await CategoriesService.get(masterRecordId);
            }

            const allCategories = await CategoriesService.getAllActive();
            setCategoriesParent(allCategories);

            model.list = listLanguages.map(lang => {
                const itemSelected = allCategories?.find(x => x.languageId === lang.id && x.masterRecordId === masterRecordParentId);
                const subCategoryItem = list?.find(x => x.languageId === lang.id);

                if (itemSelected) {
                    return {
                        masterRecordId,
                        id: subCategoryItem ? subCategoryItem.id : null,
                        name: subCategoryItem ? subCategoryItem.name : '',
                        languageId: lang.id,
                        parentId: itemSelected.masterRecordId,
                        mediaUrl: subCategoryItem?.mediaUrl
                    };
                }

                return {
                    id: null,
                    name: '',
                    languageId: lang.id,
                    parentId: subCategoryItem?.masterRecordId,
                    mediaUrl: subCategoryItem?.mediaUrl,
                    masterRecordId,
                };
            });

            reset(model);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t  get categorie', error);
            toast.error(`${t('shared_translations.messages.error_load_info')}`);
            Loading.hide();
        }
    };

    const onSubmit = async (model: CategoriesListDto) => {
        try {
            Loading.show();

            if (masterRecordParentId && masterRecordParentId != null) {
                if (masterRecordId && masterRecordId != null) {
                    await CategoriesService.update({ categories: model.list, masterRecordId }, attachments);
                    toast.success(`${t('shared_translations.messages.record_save_success')}`);
                    navigateTo();
                } else {
                    await CategoriesService.create({ categories: model.list }, attachments);
                    toast.success(`${t('shared_translations.messages.record_save_success')}`);
                    navigateTo();
                }
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't create/update subCategorie", error);
            toast.error(`${t('shared_translations.messages.record_save_error')}`);
            Loading.hide();
        }
    };

    const removeItem = async () => {
        try {
            Loading.show();
            if (masterRecordId && masterRecordId != null) {
                await CategoriesService.remove({ masterRecordId });
                toast.success(`${t('shared_translations.messages.record_delete_success')}`);
            }

            Loading.hide();
            navigateTo('deleted');
        } catch (error) {
            setShowRemoveModal(false);
            toast.error(`${t('shared_translations.messages.record_delete_error')}`);
            Loading.hide();
        }
    };

    const notSubmitted = async (errorsInForm?: any) => {
        for (let i = 0; i < languages.length; i++) {
            const language = languages[i];
            language.invalid = Boolean((errorsInForm?.list || [])[i]) || (!masterRecordId && !language.languageTabVisited);
        }

        toast(`${t('shared_translations.messages.please_confirm_all_data')}`, {
            icon: <WarningToast />,
        });
    };

    const showRemoveItemDialog = async () => {
        if (masterRecordId && masterRecordId != null) {
            try {
                Loading.show();
                const categoryAssociations = await CategoriesService.getAssociations(masterRecordId);

                let message = '';
                if (categoryAssociations?.inspirationsNumber && categoryAssociations.subCategoriesNumber) {
                    message = t('home_screen.categories.associations_subcategory_inspirations_message',
                        { subCategoriesNumber: categoryAssociations?.subCategoriesNumber, inspirationsNumber: categoryAssociations?.inspirationsNumber });
                }
                if (categoryAssociations?.inspirationsNumber && !categoryAssociations.subCategoriesNumber) {
                    message = t('home_screen.categories.associations_inspirations_message',
                        { inspirationsNumber: categoryAssociations?.inspirationsNumber });
                }
                if (categoryAssociations?.subCategoriesNumber && !categoryAssociations?.inspirationsNumber) {
                    message = t('home_screen.categories.associations_subcategory_message',
                        { subCategoriesNumber: categoryAssociations?.subCategoriesNumber });
                }

                setRemoveMessage(message);
                setShowRemoveModal(true);

                Loading.hide();
            } catch (error) {
                setShowRemoveModal(false);
                toast.error(`${t('shared_translations.messages.record_delete_error')}`);
                Loading.hide();
            }
        }
    };

    const onCancelRemove = () => {
        setShowRemoveModal(false);
    };

    const navigateTo = (typeUrl?: string, idToGo?: string) => {
        if (typeUrl === 'deleted') { return navigate(`${ApplicationPaths.Home}/${masterRecordParentId}`); }
        if (typeUrl) {
            setIsDetails(typeUrl === Types.details);
            return navigate(`${ApplicationPaths.Categories}/${masterRecordParentId}/subcategories/${typeUrl}/${idToGo}`);
        } else {
            return navigate(-1);
        }
    };

    const onLanguageChange = (lang: LanguageDto, i: number) => {
        if (!masterRecordId && !lang.languageTabVisited) {
            const defaultLanguage = languages.find(l => l.isDefault);
            if (defaultLanguage) {
                void copyLanguage(i, defaultLanguage.id);
            }
        }

        setLanguages([
            ...languages.map(l => {
                if (l.id === lang.id) {
                    return {
                        ...l,
                        languageTabVisited: true
                    };
                }
                return l;
            }),
        ]);

        setSelectedLanguageIndex(i);
    };

    const copyLanguage = async (destinationIndex: number, langToCopyId: string) => {
        const sourceItem = getValues('list').find(x => x.languageId === langToCopyId);

        if (sourceItem) {
            if (sourceItem.mediaUrl) {
                const newFile: CustomFile | null = await urlToFile(sourceItem.mediaUrl, 'hello_world.png');
                const key = newGuid();

                if (newFile) {
                    newFile.containerName = `${languages[destinationIndex].id}__${key}`;
                    setAttachments([...attachments, newFile]);
                }
                setValue(`list.${destinationIndex}.media` as 'list.0.media', newFile);
            }

            setValue(`list.${destinationIndex}.name` as 'list.0.name', sourceItem.name);
            setValue(`list.${destinationIndex}.mediaUrl` as 'list.0.mediaUrl', sourceItem.mediaUrl);
        }
    };

    const clearLanguage = (destinationIndex: number, langToCopyId: string) => {
        const sourceItem = getValues('list').find(x => x.languageId === langToCopyId);
        if (sourceItem) {
            setValue(`list.${destinationIndex}.name` as 'list.0.name', '');
            setValue(`list.${destinationIndex}.mediaUrl` as 'list.0.mediaUrl', undefined);
        }
    };

    const setCategoriesEqual = (id: string) => {
        const sourceItem = getValues('list');
        sourceItem.forEach((_, index) => { setValue(`list.${index}.parentId` as 'list.0.parentId', id); });
    };

    if (!canWrite && type !== Types.details) {
        return <Navigate replace={true} to={ApplicationPaths.Home} />;
    }

    return (
        <div>
            <Navbar breadCrumbs={breadCrumbs} isDetails={isDetails}/>
            <ContentLayout>
                <form
                    className={styles.form}
                    onSubmit={handleSubmit(onSubmit, notSubmitted)}
                >
                    <Row>
                        <Col xs={12}>
                            <ButtonsDiv>
                                <Button
                                    text={t('shared_translations.common.go_back')}
                                    onClick={() => navigateTo()}
                                    color={Color.white}
                                />
                                {type !== Types.create && canWrite && (
                                    <Button
                                        text={t('shared_translations.common.delete')}
                                        onClick={() => showRemoveItemDialog()}
                                        color={Color.red}
                                    />
                                )}
                                {type === Types.details && canWrite && (
                                    <Button
                                        text={t('shared_translations.common.edit')}
                                        onClick={() => {
                                            navigateTo('edit', masterRecordId);
                                        }}
                                        color={Color.black}
                                    />
                                )}

                                {type !== Types.details && canWrite && (
                                    <Button
                                        text={
                                            type === Types.create
                                                ? t('shared_translations.common.register')
                                                : t('shared_translations.common.save')
                                        }
                                        type="submit"
                                        color={Color.black}
                                    />
                                )}
                            </ButtonsDiv>
                        </Col>
                    </Row>
                    <LanguagesTab
                        selectedLanguageIndex={selectedLanguageIndex}
                        languages={languages}
                        onChange={onLanguageChange}
                        showTab={languages.length > 1}
                    >
                        {languages.map((lang, index) => {
                            const keyName = `list.${index}`;
                            const itemErrors = (formState.errors.list ?? [])[index];
                            return <div key={`${lang.id}_${index}`}
                                className={`${index !== selectedLanguageIndex && styles.hideTab}`}>
                                {!isDetails && languages.length > 1 && <CopyContent
                                    languages={languages.filter(x => x.id !== lang.id)}
                                    onCopy={language => copyLanguage(selectedLanguageIndex, language.id)}
                                    onClear={() => clearLanguage(selectedLanguageIndex, lang.id)}
                                />}
                                <Row>
                                    <Col xl={9} lg={8} md={8} sm={12} xs={12}>
                                        <FormItem>
                                            <Label required={!!itemErrors?.parentId}>
                                                {t('home_screen.categories.form.title') + '*'}
                                            </Label>
                                            <SelectInput
                                                optionsList={categoriesParent.filter(x => x.languageId === lang.id).map(x => ({ label: x.name || '', value: x.masterRecordId ?? '' }))}
                                                disabled={isDetails}
                                                placeholder={t('home_screen.categories.form.categorie_placeholder')}
                                                required={true}
                                                name={`${keyName}.parentId`}
                                                control={control}
                                                showRequired={!!itemErrors?.parentId}
                                                onCategorieChange={setCategoriesEqual}
                                            />
                                            <InputError error={itemErrors?.parentId} />
                                        </FormItem>
                                        <FormItem>
                                            <Label required={!!itemErrors?.name}>
                                                {t('home_screen.categories.form.sub_title') + '*'}
                                            </Label>
                                            <Input
                                                type="text"
                                                register={register}
                                                name={`${keyName}.name`}
                                                required={true}
                                                disabled={isDetails}
                                                maxLength={250}
                                                showRequired={!!itemErrors?.name}
                                            />
                                            <InputError error={itemErrors?.name} maxLength={250} />

                                        </FormItem>
                                    </Col>

                                    <Col xl={3} lg={4} md={4} sm={12} xs={12}>
                                        <FormItem>
                                            <div className={styles.photoDiv}>
                                                <ImageInput
                                                    key={`${keyName}.media`}
                                                    onChooseFile={(file, dataUrl) => onChooseAttachment(file, dataUrl, index)}
                                                    previewUrl={watch(`list.${index}.mediaUrl`) ?? null}
                                                    onDelete={() => onDeleteAttachment(index)}
                                                    disabled={isDetails}
                                                    circularCrop={false}
                                                    roundDisplay={false}
                                                    rectangularShape={true}
                                                    forceAspectRatio={true}
                                                />
                                            </div>
                                        </FormItem>
                                    </Col>
                                </Row>

                            </div>;
                        })}
                    </LanguagesTab>
                </form>

                <QuestionYesNo
                    onNo={onCancelRemove}
                    onYes={removeItem}
                    isVisible={showRemoveModal}
                    message={t('shared_translations.messages.remove_record_with_ident', {
                        name: '',
                    })}
                    messageLine2={removeMessage}
                />
            </ContentLayout>
        </div >
    );
}

export default SubCategorieScreen;
