import React, { useCallback, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './FileInput.module.scss';
import { ReactComponent as InputIcon } from 'assets/svg/inputImage.svg';
import { FaTrash } from 'react-icons/fa';
import CustomFile from 'common/models/CustomFile';
import { useTranslation } from 'react-i18next';
import useClickOutside from 'common/hooks/useClickOutside';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import useIsMobile from 'common/hooks/useIsMobile';

const imagesAccept = {
    'image/jpeg': [],
    'image/png': []
};

interface PreviewProps {
    previewDataUrl: string;
    disabled: boolean;
    forceAspectRatio: boolean;
    onDelete: (e: React.MouseEvent<HTMLDivElement>) => void;
    onClickOverlay?: (e: React.MouseEvent<HTMLDivElement>) => void;
    roundDisplay: boolean
    selected?: boolean;
}

const aspectRatio = (forceAspectRatio: boolean, isImage: boolean, roundDisplay: boolean) => {
    if (forceAspectRatio && !isImage) {
        return styles.forceAspectRatio;
    }
    if (forceAspectRatio && isImage && !roundDisplay) {
        return styles.forceAspectRatio;
    }
    if (forceAspectRatio && isImage && roundDisplay) {
        return null;
    }
    return styles.forceAspectRatio;
};

const Preview = ({ previewDataUrl, disabled, onDelete, forceAspectRatio, onClickOverlay, roundDisplay, selected }: PreviewProps) => {
    const [show, setShow] = useState(false);
    const componentRef = useRef<HTMLDivElement>(null);
    const isMobile = useIsMobile();

    useClickOutside(componentRef, () => {
        if (show) {
            setShow(false);
        }
    }, [show]);

    const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        setShow(true);

        if (onClickOverlay) {
            onClickOverlay(e);
        }
    };

    const isImage = previewDataUrl !== undefined && previewDataUrl !== null;

    return (
        <div className={`${styles.previewContainer} ${selected ? styles.selectedPreview : ''}`} onClick={onClick} ref={componentRef}>
            <img
                src={previewDataUrl}
                className={`${styles.previewImage} ${onClickOverlay ? styles.cursorPointer : ''} ${aspectRatio(forceAspectRatio, isImage, roundDisplay)} `}
            />
            {!disabled && (!isMobile || show) && <div className={styles.previewOverlay}>
                <div className={`${styles.deleteButton} ${roundDisplay ? null : styles.deleteButtonNotCenter}`} onClick={onDelete}>
                    <FaTrash size={16} />
                </div>
            </div>}
        </div>
    );
};

type Props = {
    multiple?: boolean;
    onDelete: () => void;
    onClickOverlay?: () => void;
    previewDataUrl?: string | null;
    disabled?: boolean;
    readOnly?: boolean;
    roundDisplay?: boolean;
    forceAspectRatio?: boolean;
    selected?: boolean;
    accept?: { [key: string]: string[] };
} & ({
    onChooseFile: (file: CustomFile) => void;
    onChooseFiles?: never;
} | {
    onChooseFile?: never;
    onChooseFiles: (files: CustomFile[]) => void;
});

const FileInput = ({
    multiple = false, onChooseFile, onChooseFiles, onDelete, previewDataUrl, disabled, readOnly = false, roundDisplay = true, forceAspectRatio = false, onClickOverlay,
    accept = imagesAccept, selected
}: Props) => {
    const { t } = useTranslation();
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const onDrop = useCallback((files: File[]) => {
        if (!files || files.length === 0) {
            return;
        }

        if (onChooseFiles) {
            onChooseFiles(files);
        }

        if (onChooseFile) {
            onChooseFile(files[0]);
        }
    }, [onChooseFile]);

    const onAskDelete = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (disabled ?? readOnly) {
            return;
        }

        setShowDeleteModal(true);
    };

    const onCancelDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        setShowDeleteModal(false);
    };

    const onYesDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        setShowDeleteModal(false);
        onDelete();
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple, accept });
    const isImage = previewDataUrl !== undefined && previewDataUrl !== null;

    return (
        <>
            <div
                className={`${styles.fileWrapper} ${disabled ? styles.disabled : ''} ${isImage && roundDisplay ? styles.borderRadius : ''} ${aspectRatio(forceAspectRatio, isImage, roundDisplay)}`}
                {...((!disabled && !readOnly) ? getRootProps() : {})}
            >
                {!previewDataUrl && <div className={styles.noFileContainer}>
                    <InputIcon />
                    {(!disabled && !readOnly) && <div className={styles.text}>{t('shared_translations.common.load_image')}</div>}
                </div>}
                {isImage && (
                    <Preview
                        previewDataUrl={previewDataUrl}
                        disabled={disabled ?? readOnly ?? false}
                        onDelete={onAskDelete}
                        forceAspectRatio={forceAspectRatio}
                        onClickOverlay={onClickOverlay}
                        roundDisplay={roundDisplay}
                        selected={selected}
                    />
                )}
                {(!disabled && !readOnly) && <input
                    className={styles.fileInput}
                    id="file"
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    multiple={multiple}
                    {...getInputProps()}
                />}
            </div>
            <QuestionYesNo
                onNo={onCancelDelete}
                onYes={onYesDelete}
                isVisible={showDeleteModal}
                message={t('shared_translations.messages.remove_record_with_ident', {
                    name: '',
                })}
            />
        </>
    );
};

export default FileInput;
