import BackofficeTable, { BackofficeTableColumn } from 'common/components/backofficeTable/BackofficeTable';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ApisTab.module.scss';
import { ReactComponent as PinterestIcon } from 'assets/svg/pinterest-logo.svg';
import { PagedSearchCriteria } from 'api/common/types/PagedSearchCriteria';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import toast from 'react-hot-toast';
import { UserProfile } from 'api/account/models/UserProfile';
import hasPolicies from 'common/utils/hasPolicies';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import Button, { Color } from 'common/components/buttons/Button';
import PinterestService from 'api/pinterest/PinterestService';
import PinterestDto from 'api/pinterest/models/PinterestDto';
import moment from 'moment';

const ApisTab = () => {
    const { t } = useTranslation();

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = hasPolicies(userProfile, ['SETTINGUP_API_WRITE']);
    const [apis, setApis] = useState<PinterestDto[]>();

    const [criteria] = useState<PagedSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderBy: 'asc',
        orderColumn: 'name',
    });

    const getData = async () => {
        try {
            Loading.show();

            const results = await PinterestService.getList();
            setApis(results);

            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't get users list", error);
            toast.error(`${t('shared_translations.messages.error_load_info')}`);
            Loading.hide();
        }
    };

    const renderStateCell = (row: PinterestDto) => {
        const today = moment();
        const expirationDate = moment(row.expirationDate);

        return (
            row.expirationDate
                ? <span>
                    {(today.isBefore(expirationDate)) ? t('backoffice.api.state.active') : t('backoffice.api.state.expired')}
                </span>
                : <span>{t('backoffice.api.state.awaits_configuration')}</span>
        );
    };

    const renderLoginCell = (row: PinterestDto) => {
        const today = moment();
        const expirationDate = moment(row.expirationDate);

        return (canWrite &&
            <span style={{ display: 'flex', width: 'max-content', right: 0 }}>
                <Button
                    text={t('backoffice.api.login')}
                    disabled={today.isBefore(expirationDate)}
                    color={Color.red}
                    onClick={() => window.open(row.signInUrl, '_blank')}
                />
            </span>
        );
    };

    const apiColumns: BackofficeTableColumn<PinterestDto>[] = [
        {
            name: '',
            renderCell: () => <div className={styles.imageColumn}>{<PinterestIcon className={styles.image} />}
            </div>,
            width: '5%',
            hideOn: ['sm', 'md'],
            columnName: '',
        },
        {
            name: t('backoffice.api.name'),
            renderCell: (row) => <span>{t('backoffice.api.' + row.name)}</span>,
            width: '50%',
            hideOn: [],
            columnName: '',
        },
        {
            name: t('backoffice.api.state.title'),
            renderCell: renderStateCell,
            width: '30%',
            hideOn: ['sm', 'md'],
            columnName: '',
        },
        {
            name: '',
            renderCell: renderLoginCell,
            width: 'fill',
            columnName: '',
        },
    ];

    useEffect(() => {
        void getData();
    }, []);

    return (
        <div style={{ width: '100%' }}>
            <BackofficeTable
                columns={apiColumns}
                rows={apis ?? []}
                totalItems={apis?.length ?? 0}
                currentPage={criteria.page}
                changePage={getData}
                orderTable={() => { }}
                initialOrder={{ columnName: 'name', isOrderAsc: true }} />
        </div>
    );
};

export default ApisTab;
