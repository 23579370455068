import { useTranslation } from 'react-i18next';
import styles from './NoRecords.module.scss';

const NoRecords = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.center}>
            {t('shared_translations.messages.no_info_to_show')}
        </div>
    );
};

export default NoRecords;
