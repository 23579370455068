import { Paged } from 'api/common/types/Page';
import { PagedSearchCriteria } from 'api/common/types/PagedSearchCriteria';
import { UserMediaSearchCriteria } from 'api/users/models/UserMediaSearchCriteria';
import CustomFile from 'common/models/CustomFile';
import Http from 'common/services/Http';
import UserMediaDto, { ClientDto, ClientFavoritesDto } from './models/ClientDto';
import { ClientsSearchCriteria } from './models/ClientsSearchCriteria';

class ClientsService {
    public getList (criteria: PagedSearchCriteria) {
        return Http.get<Paged<ClientDto>>('clients', criteria);
    }

    public getById (id: string) {
        return Http.get<ClientDto>('clients/' + id);
    }

    public create (model: ClientDto, mediaFile: CustomFile | null): Promise<string> {
        return Http.postWithFile('clients', model, mediaFile ? [mediaFile] : []);
    }

    public update (model: ClientDto, mediaFile: CustomFile | null) {
        return Http.putWithFile('clients', model, mediaFile ? [mediaFile] : []);
    }

    public remove (model: ClientDto) {
        return Http.put('clients/deactivate', model);
    }

    // #region client documents
    public getDocumentsListByClient (criteria: UserMediaSearchCriteria) {
        return Http.get<Paged<UserMediaDto>>('userMedias', criteria);
    }

    public GetDocumentsCountByClient (userId: string) {
        return Http.get<number>('userMedias/count/' + userId);
    }

    public saveUserMedia (model: UserMediaDto, mediaFile: CustomFile[]): Promise<string> {
        return Http.putWithFile('userMedias', model, mediaFile);
    }

    public removeUserMedia (model: UserMediaDto,) {
        return Http.put('userMedias/deactivate', model);
    }
    // #endregion

    // #region client favorites
    public getClientFavoritesList (criteria: ClientsSearchCriteria) {
        return Http.get<Paged<ClientFavoritesDto>>('clients/favorites', criteria);
    }

    public GetClientFavoritesCount (userId: string) {
        return Http.get<number>('clients/favorites/count/' + userId);
    }

    // #endregion
}

export default new ClientsService();
