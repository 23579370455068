import styles from './Button.module.scss';

export enum Color {
    white,
    black,
    red,
}
export enum Size {
    small,
    normal,
}

interface Props {
    text: string
    color: Color
    type?: any
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
    style?: any
    disabled?: boolean;
    underLGFlex?: boolean;
    size?: Size;
}

const Button = (props: Props) => {
    const { color, type, onClick, style, disabled, underLGFlex, size = Size.normal } = props;

    let colorClassName;
    if (color === Color.white) {
        colorClassName = styles.white;
    } else if (color === Color.red) {
        colorClassName = styles.red;
    } else {
        colorClassName = styles.black;
    }

    return (
        <button type={type || 'button'} onClick={onClick} style={style} disabled={disabled} className={underLGFlex ? styles.underLGFlex : undefined}>
            <div className={`${colorClassName} ${disabled ? styles.disabled : ''}`} >
                <a className={size === Size.normal ? styles.button : styles.buttonSmall} >{props.text}</a>
            </div>
        </button>
    );
};

export default Button;
