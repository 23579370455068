import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AuthenticatedLayout from './common/layouts/authenticatedLayout/AuthenticatedLayout';
import AuthorizeRoute from './common/routes/AuthorizeRoute';
import { ApplicationPaths, LoginActions, LogoutActions } from './Config';
import ForbiddenScreen from './screens/errors/ForbiddenScreen';
import NotFoundScreen from './screens/errors/NotFoundScreen';
import IdentityLoginScreen from './screens/identity/IdentityLoginScreen';
import IdentityLogoutScreen from './screens/identity/IdentityLogoutScreen';
import Backoffice from './screens/backoffice/BackofficeScreen';
import UserScreen from 'screens/users/UserScreen';
import Clients from 'screens/clients/clientsScreen/ClientsScreen';
import ClientScreen from 'screens/clients/clientScreen/ClientScreen';
import RoleScreen from 'screens/roles/RoleScreen';
import LanguageScreen from 'screens/languages/language/LanguageScreen';
import HomeScreen from 'screens/home/HomeScreen';
import CategorieScreen from 'screens/categorie/CategorieScreen';
import InspirationScreen from 'screens/inspirations/InspirationScreen';
import DefinitionScreen from 'screens/definitions/DefinitionScreen';
import PolicyRoute from 'common/routes/PolicyRoute';
import SubCategorieScreen from 'screens/categorie/subCategorie/SubCategorieScreen';
import ClientFile from 'screens/clients/clientFile/ClientFile';

const HomeRedirection = () => {
    return <Navigate replace={true} to={ApplicationPaths.Home} />;
};

const Router = () => {
    return (

        <BrowserRouter>
            <Routes>
                <Route path="/404" element={<NotFoundScreen />} />
                <Route path="/403" element={<ForbiddenScreen />} />
                <Route path={ApplicationPaths.Login} element={<IdentityLoginScreen action={LoginActions.Login} />} />
                <Route path={ApplicationPaths.Reset} element={<IdentityLoginScreen action={LoginActions.Reset} />} />
                <Route path={ApplicationPaths.LoginFailed} element={<IdentityLoginScreen action={LoginActions.LoginFailed} />} />
                <Route path={ApplicationPaths.LoginCallback} element={<IdentityLoginScreen action={LoginActions.LoginCallback} />} />
                <Route path={ApplicationPaths.LogOut} element={<IdentityLogoutScreen action={LogoutActions.Logout} />} />
                <Route path={ApplicationPaths.LogOutCallback} element={<IdentityLogoutScreen action={LogoutActions.LogoutCallback} />} />
                <Route path='/*' element={
                    <AuthorizeRoute>
                        <AuthenticatedLayout>
                            <Routes>
                                <Route path="/" element={<HomeRedirection />} />
                                <Route path="/home" element={<HomeScreen />} />
                                <Route path="/home/:categoryId" element={<HomeScreen />} />
                                <Route path="/home/:categoryId/:subCategoryId" element={<HomeScreen />} />

                                <Route path={ApplicationPaths.Backoffice} element={<Backoffice />} />

                                <Route path={`${ApplicationPaths.Backoffice}/users/:type`} element={
                                    <PolicyRoute policies={['SETTINGUP_USERS_WRITE', 'SETTINGUP_USERS_READ']}><UserScreen /></PolicyRoute>}
                                />
                                <Route path={`${ApplicationPaths.Backoffice}/users/:type/:id`} element={
                                    <PolicyRoute policies={['SETTINGUP_USERS_WRITE', 'SETTINGUP_USERS_READ']}><UserScreen /></PolicyRoute>}
                                />

                                <Route path={`${ApplicationPaths.Clients}/file/:id`} element={
                                    <PolicyRoute policies={['SETTINGUP_CLIENTS_WRITE', 'SETTINGUP_CLIENTS_READ']}><ClientFile /></PolicyRoute>}
                                />

                                <Route path={ApplicationPaths.Clients} element={
                                    <PolicyRoute policies={['SETTINGUP_CLIENTS_WRITE', 'SETTINGUP_CLIENTS_READ']}><Clients /></PolicyRoute>}
                                />
                                <Route path={`${ApplicationPaths.Clients}/:type`} element={
                                    <PolicyRoute policies={['SETTINGUP_CLIENTS_WRITE', 'SETTINGUP_CLIENTS_READ']}><ClientScreen /></PolicyRoute>}
                                />
                                <Route path={`${ApplicationPaths.Clients}/:type/:id`} element={
                                    <PolicyRoute policies={['SETTINGUP_CLIENTS_WRITE', 'SETTINGUP_CLIENTS_READ']}><ClientScreen /></PolicyRoute>}
                                />

                                <Route path={`${ApplicationPaths.Backoffice}/roles/:type`} element={
                                    <PolicyRoute policies={['SETTINGUP_ROLES_WRITE', 'SETTINGUP_ROLES_READ']}><RoleScreen /></PolicyRoute>}
                                />
                                <Route path={`${ApplicationPaths.Backoffice}/roles/:type/:id`} element={
                                    <PolicyRoute policies={['SETTINGUP_ROLES_WRITE', 'SETTINGUP_ROLES_READ']}><RoleScreen /></PolicyRoute>}
                                />

                                <Route path={`${ApplicationPaths.Backoffice}/languages/:type`} element={
                                    <PolicyRoute policies={['SETTINGUP_LANGUAGES_WRITE', 'SETTINGUP_LANGUAGES_READ']}><LanguageScreen /></PolicyRoute>}
                                />
                                <Route path={`${ApplicationPaths.Backoffice}/languages/:type/:id`} element={
                                    <PolicyRoute policies={['SETTINGUP_LANGUAGES_WRITE', 'SETTINGUP_LANGUAGES_READ']}><LanguageScreen /></PolicyRoute>}
                                />
                                <Route path={`${ApplicationPaths.Backoffice}/definitions/:type/:id`} element={
                                    <PolicyRoute policies={['SETTINGUP_DEFINITIONS_WRITE', 'SETTINGUP_DEFINITIONS_READ']}><DefinitionScreen /></PolicyRoute>}
                                />

                                <Route path={`${ApplicationPaths.Categories}/:type`} element={
                                    <PolicyRoute policies={['SETTINGUP_CATEGORIES_READ', 'SETTINGUP_CATEGORIES_WRITE']}><CategorieScreen /></PolicyRoute>}
                                />
                                <Route path={`${ApplicationPaths.Categories}/:type/:masterRecordId`} element={
                                    <PolicyRoute policies={['SETTINGUP_CATEGORIES_READ', 'SETTINGUP_CATEGORIES_WRITE']}><CategorieScreen /></PolicyRoute>}
                                />

                                <Route path={`${ApplicationPaths.Categories}/:masterRecordParentId/subcategories/:type/`} element={
                                    <PolicyRoute policies={['SETTINGUP_CATEGORIES_READ', 'SETTINGUP_CATEGORIES_WRITE']}><SubCategorieScreen /></PolicyRoute>}
                                />
                                <Route path={`${ApplicationPaths.Categories}/:masterRecordParentId/subcategories/:type/:masterRecordId`} element={
                                    <PolicyRoute policies={['SETTINGUP_CATEGORIES_READ', 'SETTINGUP_CATEGORIES_WRITE']}><SubCategorieScreen /></PolicyRoute>}
                                />

                                <Route path={`${ApplicationPaths.Inspirations}/:type`} element={
                                    <PolicyRoute policies={['SETTINGUP_INSPIRATIONS_READ', 'SETTINGUP_INSPIRATIONS_WRITE']}><InspirationScreen /></PolicyRoute>}
                                />
                                <Route path={`${ApplicationPaths.Inspirations}/:type/:masterRecordId`} element={
                                    <PolicyRoute policies={['SETTINGUP_INSPIRATIONS_READ', 'SETTINGUP_INSPIRATIONS_WRITE']}><InspirationScreen /></PolicyRoute>}
                                />

                                <Route path={`${ApplicationPaths.Backoffice}/:tab`} element={
                                    <PolicyRoute policies={['SETTINGUP_API_WRITE', 'SETTINGUP_API_READ']}><Backoffice/></PolicyRoute>}
                                />

                                <Route path="*" element={<Navigate replace={true} to="/404" />} />
                            </Routes>
                        </AuthenticatedLayout>
                    </AuthorizeRoute>
                } />

                <Route path="*" element={<Navigate replace={true} to="/404" />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
