import BackofficeTable, { BackofficeTableColumn } from 'common/components/backofficeTable/BackofficeTable';
import Loading from 'common/services/Loading';
import { useState, useEffect } from 'react';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import Logger from 'common/services/Logger';
import ClientsService from 'api/clients/ClientsService';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import styles from './DocumentsTab.module.scss';

import { FiImage, FiFile } from 'react-icons/fi';
import { ReactComponent as ActionsIcon } from 'assets/svg/more-vertical.svg';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/dropdown/DropdownItem';
import hasPolicies from 'common/utils/hasPolicies';

import { UserProfile } from 'api/account/models/UserProfile';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { UserMediaSearchCriteria } from 'api/users/models/UserMediaSearchCriteria';
import UserMediaDto from 'api/clients/models/ClientDto';
import moment from 'moment';
import { saveAs } from 'file-saver';
import MediaService from 'api/medias/MediaService';
import useNoInitialEffect from 'common/hooks/useNoInitialEffect';
import CanvasModal from 'common/components/editableImage/canvasModal/CanvasModal';

interface Props {
    clientId: string | undefined
    userProfile: UserProfile | null
    updateCounters: Function
}

function DocumentsTab ({ clientId, userProfile, updateCounters }: Props): JSX.Element {
    const { t } = useTranslation();
    const [documents, setDocuments] = useState<UserMediaDto[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0);
    const orderColDefault = 'm.last_update';
    const [criteria, setCriteria] = useState<UserMediaSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderBy: 'desc',
        orderColumn: orderColDefault,
    });
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemSelected, setItemSelected] = useState<UserMediaDto | null>(null);
    const [showCanvasModal, setShowCanvasModal] = useState<boolean>(false);
    const [url, setUrl] = useState<string | null>(null);
    const canWrite = hasPolicies(userProfile, ['SETTINGUP_CLIENTS_WRITE']);

    useEffect(() => {
        void getData(criteria.page === 1);
    }, [criteria]);

    useNoInitialEffect(() => {
        void getData(true);
    }, [clientId]);

    const getData = async (reset = false) => {
        try {
            Loading.show();
            const page = await ClientsService.getDocumentsListByClient({ ...criteria, userId: clientId });
            if (reset) {
                setDocuments(page.items);
            } else {
                setDocuments([...documents, ...page.items]);
            }
            setTotalItems(page.totalItems);

            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't get documents list", error);
            toast.error(`${t('shared_translations.messages.error_load_info')}`);
            Loading.hide();
        }
    };

    const changePage = (page: number) => {
        setCriteria({ ...criteria, page });
    };

    const orderTable = async (orderField: string) => {
        const page = 1;
        const orderColumn = orderField;
        let orderBy: string;

        if (orderColumn !== criteria.orderColumn) {
            orderBy = 'asc';
        } else {
            if (criteria.orderBy === 'asc') {
                orderBy = 'desc';
            } else {
                orderBy = 'asc';
            }
        }
        setCriteria({ ...criteria, page, orderColumn, orderBy });
    };

    const doDownload = async (row: UserMediaDto) => {
        const file = await MediaService.downloadMedia(row.mediaId ?? '');
        saveAs(file, row.mediaName);
    };

    const validateFileToDownload = async (row: UserMediaDto) => {
        if (row.mimeType?.includes('image/')) {
            onImageClick(row.mediaUrl ?? '');
        } else {
            window.open(row.mediaUrl);
        }
    };

    const renderActionCell = (row: UserMediaDto) => {
        return (
            <div className={styles.optionsIcon}>
                <Dropdown
                    options={
                        <>
                            <DropdownItem onClick={(e) => { e.stopPropagation(); void doDownload(row); }} >
                                {t('shared_translations.common.download')}
                            </DropdownItem>
                            {canWrite &&
                                <DropdownItem onClick={() => showRemoveItemDialog(row)}>
                                    {t('shared_translations.common.delete')}
                                </DropdownItem>
                            }
                        </>
                    }
                >
                    <div>
                        <ActionsIcon />
                    </div>
                </Dropdown>
            </div>
        );
    };

    const whichIcon = (row: UserMediaDto) => {
        if (row.mimeType?.includes('image/')) {
            return <FiImage className={styles.image} />;
        }

        return <FiFile className={styles.image} />;
    };

    const onImageClick = (url: string) => {
        setUrl(url);
        setShowCanvasModal(true);
    };

    const documentsColumn: BackofficeTableColumn<UserMediaDto>[] = [
        {
            name: '',
            renderCell: (row) => whichIcon(row),
            hideOn: ['sm', 'md'],
            columnName: '',
        },
        {
            name: t('clients.tabs.documents.document_name'),
            renderCell: (row) => row.mediaName,
            width: '40%',
            hideOn: [],
            columnName: 'm.file_name',
        },
        {
            name: t('clients.tabs.documents.document_date'),
            renderCell: (row) => moment(row.date).format('DD/MM/YYYY | HH:mm'),
            width: '55%',
            hideOn: ['sm'],
            columnName: 'm.last_update',
        },
        {
            name: '',
            renderCell: renderActionCell,
            width: 'fill',
            columnName: '',
            preventClick: true,
        },
    ];

    const onAddFiles = async (e: any) => {
        try {
            Loading.show();
            await ClientsService.saveUserMedia({ userId: clientId }, e.target.files);
            toast.success(`${t('shared_translations.messages.record_save_success')}`);
            Loading.hide();
            setCriteria({ ...criteria, page: 1 });
            updateCounters();
            await getData(criteria.page === 1);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't save documents", error);
            toast.error(`${t('shared_translations.messages.error_load_info')}`);
            Loading.hide();
        }
    };

    const showRemoveItemDialog = (item: UserMediaDto) => {
        setItemSelected(item);
        setShowRemoveModal(true);
    };

    const onCancelRemove = () => {
        setItemSelected(null);
        setShowRemoveModal(false);
    };

    const removeItem = async () => {
        if (itemSelected) {
            try {
                Loading.show();
                await ClientsService.removeUserMedia(itemSelected);
                toast.success(`${t('shared_translations.messages.record_delete_success')}`);
                setShowRemoveModal(false);
                changePage(1);
                updateCounters();
                Loading.hide();
            } catch (error) {
                setShowRemoveModal(false);
                toast.error(`${t('shared_translations.messages.record_delete_error')}`);
                setShowRemoveModal(false);
                Loading.hide();
            }
        }
    };

    return (
        <div>
            <BackofficeTable
                columns={documentsColumn}
                rows={documents}
                totalItems={totalItems}
                currentPage={criteria.page}
                changePage={changePage}
                orderTable={orderTable}
                initialOrder={{ columnName: orderColDefault, isOrderAsc: false }}
                onRowClick={row => validateFileToDownload(row)}
                showAddButton={canWrite}
                onAddFiles={onAddFiles}
            />
            <QuestionYesNo
                onNo={onCancelRemove}
                onYes={removeItem}
                isVisible={showRemoveModal}
                message={t('shared_translations.messages.remove_record_with_ident', {
                    name: itemSelected?.mediaName ?? '',
                })}
            />

            {showCanvasModal && <CanvasModal
                url={url ?? ''}
                onCancelEdition={() => setShowCanvasModal(false)}
                showButtons={false}
            />}
        </div>
    );
}

export default DocumentsTab;
