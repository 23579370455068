import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EditIcon } from 'assets/svg/edit-pencil.svg';
import { ReactComponent as AddCircleIcon } from 'assets/svg/addcircle.svg';
import styles from './HomeCategories.module.scss';
import { ApplicationPaths, DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import Logger from 'common/services/Logger';

import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import CategoriesService from 'api/categories/CategoriesService';
import { CategorieDto } from 'api/categories/models/CategorieDto';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import Loading from 'common/services/Loading';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { CategorySearchCriteria } from 'api/categories/models/CategorySearchCriteria';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import NoRecords from '../../../common/components/noRecords/NoRecords';
import hasPolicies from 'common/utils/hasPolicies';
import { UserProfile } from 'api/account/models/UserProfile';

enum Display {
    CATEGORIE,
    SUBCATEGORIE,
}

interface Props {
    masterCategoryId: string | undefined
    masterSubCategoryId: string | undefined
}

function HomeCategories ({ masterCategoryId, masterSubCategoryId }: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [categories, setCategories] = useState<CategorieDto[]>([]);
    const [activeCategorie, setActiveCategorie] = useState<CategorieDto | null>();
    const [subCategories, setSubCategories] = useState<CategorieDto[]>([]);
    const [display, setDisplay] = useState<Display>(Display.CATEGORIE);
    const [windowSize, setWindowSize] = useState({
        width: 1,
        height: 1,
    });

    const showEditLayout = useSelector<Reducers, boolean>(state => state.editLayout.open);

    const user = useSelector<Reducers, UserProfile | null>(
        (state) => state.authentication.profile
    );
    const hasReadCategoriesPolicy = hasPolicies(user, ['SETTINGUP_CATEGORIES_READ']);
    const hasWriteCategoriesPolicy = hasPolicies(user, ['SETTINGUP_CATEGORIES_WRITE']);

    const [criteria] = useState<CategorySearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderBy: 'asc',
        orderColumn: 'name',
    });

    useEffect(() => {
        setDisplay(Display.CATEGORIE);
        void getData();

        function handleResize () {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [i18n.language, masterCategoryId]);

    const getData = async () => {
        try {
            Loading.show();
            const result = await CategoriesService.getList(criteria);
            setCategories(result);
            void loadSubCategories(result);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t  get categories list', error);
            toast.error(`${t('shared_translations.messages.error_load_info')}`);
            Loading.hide();
        }
    };

    const loadSubCategories = async (result: CategorieDto[]) => {
        if (masterCategoryId !== null && masterCategoryId !== undefined) {
            const categorie = result.find(x => x.masterRecordId === masterCategoryId);
            setActiveCategorie(categorie);

            const subCats = await CategoriesService.getList({ ...criteria, parentId: masterCategoryId });

            if (subCats.length > 0) {
                setSubCategories(subCats);
                setDisplay(Display.SUBCATEGORIE);
            } else {
                setSubCategories([]);
            }
        }
    };

    async function handleCategorieClick (e: any, data: CategorieDto) {
        if (e.target === e.currentTarget) {
            e.stopPropagation();
            if (activeCategorie?.masterRecordId === data.masterRecordId) {
                setActiveCategorie(null);
                navigate('/home');
            } else {
                navigate(`/home/${data.masterRecordId}`);
                setActiveCategorie(data);
            }
        }
    }

    function goBack () {
        setSubCategories([]);
        setActiveCategorie(null);
        setDisplay(Display.CATEGORIE);
        navigate('/home');
    }

    const handleSubCategoryClick = (subCatMasterRecord: string | undefined, e: any) => {
        if (e.target === e.currentTarget) {
            if (masterSubCategoryId === subCatMasterRecord) {
                navigate(`/home/${masterCategoryId}`);
            } else {
                navigate(`/home/${masterCategoryId}/${subCatMasterRecord}`);
            }
        }
    };

    let slideMinSize = 400;
    if (windowSize.width <= 400) {
        slideMinSize = 200;
    }
    const frationDigits = 2;
    const slidesPerView = parseInt((windowSize.width / slideMinSize).toFixed(frationDigits), 10);

    return (
        <div>
            <span className={styles.span}>
                {display === Display.CATEGORIE && t('home_screen.categories.categories_title')}
                {display === Display.SUBCATEGORIE && (
                    <div className={styles.justifyCenter}>
                        <div>
                            <MdOutlineArrowBackIosNew
                                className={styles.backIcon}
                                onClick={() => goBack()}
                            /></div>
                        <span className={styles.backText} onClick={() => goBack()}>{activeCategorie?.name}</span>
                    </div>
                )}

                {showEditLayout && (
                    <AddCircleIcon
                        onClick={() => !activeCategorie ? navigate(`${ApplicationPaths.Categories}/create`) : navigate(`${ApplicationPaths.Categories}/${activeCategorie?.masterRecordId}/subcategories/create`)}
                        style={{ cursor: 'pointer' }}
                    />
                )}
            </span>

            {categories.length > 0 && display === Display.CATEGORIE && hasReadCategoriesPolicy && (
                <Swiper slidesPerView={slidesPerView} spaceBetween={30} loop={false} centerInsufficientSlides={false} className={`${styles.listDiv} }`} >

                    {categories.map((data, index) => (
                        <SwiperSlide
                            key={index}
                            className={styles.categorySlide}
                            onClick={(event) => handleCategorieClick(event, data)}
                            style={{
                                background: activeCategorie?.masterRecordId === data.masterRecordId
                                    ? `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url(${data.mediaUrl})`
                                    : `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(${data.mediaUrl})`

                            }}
                        >
                            {showEditLayout && (
                                <EditIcon
                                    className={styles.editIcon}
                                    onClick={() =>
                                        navigate(
                                            `${ApplicationPaths.Categories}/edit/${data.masterRecordId}`
                                        )
                                    }
                                />
                            )}
                            <span onClick={(event) => handleCategorieClick(event, data)}>{data.name}</span>
                        </SwiperSlide>
                    ))}

                </Swiper>
            )}
            {Boolean(categories.length <= 0) && display === Display.CATEGORIE && <NoRecords />}
            {subCategories.length > 0 && display === Display.SUBCATEGORIE && hasReadCategoriesPolicy && (
                <Swiper slidesPerView={slidesPerView} spaceBetween={30} loop={false} className={styles.listDiv} centerInsufficientSlides={false}>
                    {subCategories.map((data, index) => (
                        <SwiperSlide
                            key={index}
                            className={styles.categorySlide}
                            onClick={(e) => handleSubCategoryClick(data.masterRecordId, e)}
                            style={{
                                background: masterSubCategoryId === data.masterRecordId
                                    ? `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url(${data.mediaUrl})`
                                    : `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(${data.mediaUrl})`

                            }}
                        >
                            {showEditLayout && hasWriteCategoriesPolicy && (
                                <EditIcon
                                    className={styles.editIcon}
                                    onClick={() =>
                                        navigate(
                                            `${ApplicationPaths.Categories}/${data.parentId}/subcategories/edit/${data.masterRecordId}`
                                        )
                                    }
                                />
                            )}
                            <span onClick={(e) => handleSubCategoryClick(data.masterRecordId, e)}>{data.name}</span>
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
            {Boolean(subCategories.length <= 0) && display === Display.SUBCATEGORIE && <NoRecords />}
        </div>
    );
}

export default HomeCategories;
