import CheckBox from 'common/components/checkBox/CheckBox';
import InputSearch from 'common/components/inputs/inputSearch/InputSearch';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import styles from './Column.module.scss';

interface Item {
    checked?: boolean;
}

const debounceTimer = 500;

interface Props<TItem> {
    title: string;
    allChecked: boolean;
    items: TItem[];
    className?: string;
    allowCheckAll?: boolean;
    allowItemCheck?: boolean;
    isDetails?: boolean;
    onSearch: (value: string) => void;
    onAllCheck?: (allChecked: boolean) => void;
    onItemCheck?: (item: TItem, checked: boolean) => void;
    itemClassName?: (item: TItem) => string;
    render: (item: TItem) => React.ReactNode | string;
}

function Column<TItem extends Item> ({ title, className, allowCheckAll = true, isDetails = false, allowItemCheck = true, itemClassName, onSearch, allChecked, onAllCheck, onItemCheck, items, render }: Props<TItem>): JSX.Element {
    const { t } = useTranslation();
    const searchDebounce = useDebouncedCallback((value: string) => {
        onSearch(value);
    }, debounceTimer);

    return (
        <div className={className}>
            <div className={styles.searchContainer}>
                <InputSearch
                    inputContainerClassName={styles.search}
                    onChangeValue={searchDebounce}
                    placeholder={t('shared_translations.common.search')}
                ></InputSearch>
            </div>

            <div className={styles.bodyContainer}>
                <div className={styles.headerContainer}>
                    <div className={styles.title}>{title}</div>
                    {allowCheckAll && <CheckBox checked={allChecked} disabled={isDetails} onChange={e => {
                        if (onAllCheck) {
                            onAllCheck(e.target.checked);
                        }
                    }} />}
                </div>

                <div className={styles.itemsContainer}>
                    {items.map((row, rowIndex) => (
                        <div key={`row-u-${rowIndex}`} className={`${styles.item} ${itemClassName ? itemClassName(row) : ''}`} onClick={() => {
                            if (onItemCheck) {
                                onItemCheck(row, !row.checked);
                            }
                        }}>
                            <div className={styles.info}>
                                {render(row)}
                            </div>
                            {allowItemCheck && <div className={styles.checkbox}>
                                <CheckBox
                                    onClick={e => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                    checked={row.checked ?? false}
                                    readOnly
                                    key={rowIndex}
                                    disabled={isDetails}
                                />
                            </div>}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Column;
