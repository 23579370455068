import styles from './Favorites.module.scss';
import { ReactComponent as AddFavoriteIcon } from 'assets/svg/heart.svg';
import { ReactComponent as RemoveFavoriteIcon } from 'assets/svg/fill_heart.svg';

export type Props = {
    mode: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Favorites = ({ mode, onClick, ...props }: Props) => {
    return (
        <span className={`${styles.buttonIconFavorite} ${styles.buttonIconEdit} ${props.className}`} onClick={onClick}>
            { mode && <AddFavoriteIcon /> }
            { !mode && <RemoveFavoriteIcon /> }
        </span>
    );
};

export default Favorites;
