import styles from './ClientsScreen.module.scss';
import BackofficeTable, {
    BackofficeTableColumn, BackofficeTableRow,
} from 'common/components/backofficeTable/BackofficeTable';
import { ClientDto } from 'api/clients/models/ClientDto';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import ClientsService from 'api/clients/ClientsService';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import toast from 'react-hot-toast';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE, ApplicationPaths } from 'Config';
import { PagedSearchCriteria } from 'api/common/types/PagedSearchCriteria';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/dropdown/DropdownItem';
import { ReactComponent as ActionsIcon } from 'assets/svg/more-vertical.svg';
import { ReactComponent as AvatarIcon } from 'assets/svg/icon-user-client.svg';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import Navbar from 'common/layouts/navBar/Navbar';
import { BreadCrumb } from 'common/types/BreadCrumb';
import ContentLayout from 'common/layouts/contentLayout/ContentLayout';
import { useNavigate } from 'react-router-dom';
import hasPolicies from 'common/utils/hasPolicies';
import { useSelector, useDispatch } from 'react-redux';
import { UserProfile } from 'api/account/models/UserProfile';
import { Reducers } from 'store/types';
import { setChosenUser } from 'store/chosenUser/action';

function ClientsScreen (): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [clients, setClients] = useState<ClientDto[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [criteria, setCriteria] = useState<PagedSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderBy: 'asc',
        orderColumn: 'real_name',
        allIn: '',
    });
    const user = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = hasPolicies(user, ['SETTINGUP_CLIENTS_WRITE']);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [showChangeClientModal, setShowChangeClientModal] = useState<boolean>(false);
    const [itemSelected, setItemSelected] = useState<ClientDto>({
        id: '',
        realName: '',
        email: '',
    });
    const [searchString, setSearchString] = useState('');

    const breadCrumbs: BreadCrumb[] = [{
        text: t('menu.clients_management'),
        url: ApplicationPaths.Clients
    }];

    const userSelectedOnSearch = useSelector<Reducers, ClientDto | null>((state) => state.chosenUser.user);

    useEffect(() => {
        void getClientList(criteria.page === 1);
    }, [criteria]);

    const changePage = (page: number) => {
        setCriteria({ ...criteria, page });
    };

    const getClientList = async (reset = false) => {
        try {
            Loading.show();
            const page = await ClientsService.getList({ ...criteria, allIn: searchString });
            if (reset) {
                setClients(page.items);
            } else {
                setClients([...clients, ...page.items]);
            }
            setTotalItems(page.totalItems);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't get clients list", error);
            toast.error(`${t('shared_translations.messages.error_load_info')}`);
            Loading.hide();
        }
    };

    const showRemoveItemDialog = (item: ClientDto) => {
        setItemSelected(item);
        setShowRemoveModal(true);
    };

    const onCancelRemove = () => {
        setItemSelected({
            id: '',
            realName: '',
            email: '',
        });
        setShowRemoveModal(false);
    };

    const removeItem = async () => {
        try {
            Loading.show();
            await ClientsService.remove(itemSelected);
            toast.success(`${t('shared_translations.messages.record_delete_success')}`);
            setShowRemoveModal(false);
            changePage(1);
            Loading.hide();
        } catch (error) {
            setShowRemoveModal(false);
            toast.error(`${t('shared_translations.messages.record_delete_error')}`);
            setShowRemoveModal(false);
            Loading.hide();
        }
    };

    const onCancelChangeClient = () => {
        setShowChangeClientModal(false);
    };

    const changeClient = () => {
        dispatch(setChosenUser(itemSelected));
        navigate(`${ApplicationPaths.Clients}/file/${itemSelected.id}`);
    };

    const validateSelectedClient = (item: BackofficeTableRow) => {
        setItemSelected(item as ClientDto);
        if (item.id !== userSelectedOnSearch?.id) {
            setShowChangeClientModal(true);
        } else {
            dispatch(setChosenUser(item as ClientDto));
            navigate(`${ApplicationPaths.Clients}/file/${item.id}`);
        }
    };

    const renderActionCell = (row: ClientDto) => {
        return (
            <div className={styles.optionsIcon}>
                <Dropdown
                    options={
                        <>
                            {canWrite && <DropdownItem onClick={() => validateSelectedClient(row)}>
                                {t('clients.client_profile')}
                            </DropdownItem>
                            }
                            {canWrite &&
                                <DropdownItem url={`${ApplicationPaths.Clients}/details/${row.id}`}>
                                    {t('shared_translations.common.details')}
                                </DropdownItem>
                            }
                            {canWrite &&
                                <DropdownItem url={`${ApplicationPaths.Clients}/edit/${row.id}`}>
                                    {t('shared_translations.common.edit')}
                                </DropdownItem>
                            }
                            {canWrite &&
                                <DropdownItem onClick={() => showRemoveItemDialog(row)}>
                                    {t('shared_translations.common.delete')}
                                </DropdownItem>
                            }
                        </>
                    }
                >
                    <div>
                        <ActionsIcon />
                    </div>
                </Dropdown>
            </div>
        );
    };

    const tableColumns: BackofficeTableColumn<ClientDto>[] = [
        {
            name: '',
            renderCell: (row) => row.mediaUrl ? <img className={styles.image} src={row.mediaUrl} /> : <AvatarIcon className={styles.image} />,
            width: '5%',
            hideOn: ['sm', 'md'],
            columnName: '',
        },
        {
            name: t('clients.form.name'),
            renderCell: (row) => <div className={styles.doubleRowColumn}><div>{row.realName}</div><div>{row.address}</div></div>,
            width: '40%',
            hideOn: [],
            columnName: 'real_name',
        },
        {
            name: t('clients.form.email'),
            renderCell: (row) => <div className={styles.doubleRowColumn}><div>{row.email}</div><div>{row.phoneNumber}</div></div>,
            width: '55%',
            hideOn: [],
            columnName: 'email',
        },
        {
            name: '',
            renderCell: renderActionCell,
            width: 'fill',
            columnName: '',
            preventClick: true,
        },
    ];

    const orderTable = async (orderField: string) => {
        const page = 1;
        const orderColumn = orderField;
        let orderBy: string;

        if (orderColumn !== criteria.orderColumn) {
            orderBy = 'asc';
        } else {
            if (criteria.orderBy === 'asc') {
                orderBy = 'desc';
            } else {
                orderBy = 'asc';
            }
        }

        setCriteria({ ...criteria, page, orderColumn, orderBy });
    };

    const handleChange = (event: any) => {
        setSearchString(event.target.value);
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            void getClientList(true);
        }
    };

    return (
        <div onKeyDown={handleKeyDown}>
            <Navbar searchText={searchString}
                onChange={handleChange} onSearch={() => getClientList(true)} breadCrumbs={breadCrumbs}
                isBackoffice={true} inputPlaceHolder={t('shared_translations.messages.search_bar_clients_placeholder')} />
            <ContentLayout>
                <BackofficeTable
                    columns={tableColumns}
                    rows={clients}
                    totalItems={totalItems}
                    currentPage={criteria.page}
                    changePage={changePage}
                    orderTable={orderTable}
                    navigateTo={`${ApplicationPaths.Clients}/create`}
                    initialOrder={{ columnName: 'real_name', isOrderAsc: true }}
                    onRowClick={row => validateSelectedClient(row)}
                    showAddButton={canWrite}
                />
                <QuestionYesNo
                    onNo={onCancelRemove}
                    onYes={removeItem}
                    isVisible={showRemoveModal}
                    message={t('shared_translations.messages.remove_record_with_ident', {
                        name: itemSelected?.realName ?? '',
                    })}
                />
                <QuestionYesNo
                    onNo={onCancelChangeClient}
                    onYes={changeClient}
                    isVisible={showChangeClientModal}
                    message={t('shared_translations.messages.change_client_chosen', {
                        clientName: itemSelected?.realName ?? '',
                    })}
                />
            </ContentLayout>
        </div>
    );
};

export default ClientsScreen;
