import React from 'react';
import styles from './Label.module.scss';

interface Props {
    children: any
    required?: boolean
}

const Label = (props: Props) => {
    return <label className={`${styles.label} ${props.required ? styles.requiredField : ''}`}>{props.children}</label>;
};

export default Label;
