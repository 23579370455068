import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Popover.module.scss';

interface Props {
    text?: string;
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    url?: string;
}

const DropdownItem: React.FC<Props> = ({ text, children, url, onClick }: Props) => {
    return (
        <>
            {onClick
                ? <a href={url} onClick={onClick} style={{ textDecoration: 'unset' }} className={styles.popoverItem}>
                    {Boolean(text) && text}
                    {children}
                </a>
                : <Link to={url ?? ''} style={{ textDecoration: 'none' }} className={styles.popoverItem}>
                    {Boolean(text) && text}
                    {children}
                </Link>}
        </>
    );
};

export default DropdownItem;
