import React, { memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './InputSearch.module.scss';
import SearchIcon from 'assets/svg/search-circle.svg';
import CloseIcon from 'assets/svg/x_mobile.svg';

export type Props = {
    onChangeValue: (value: string) => void;
    placeholder: string;
    inputContainerClassName?: any,
    type?: string
    className?: string
    maxLength?: any
} & React.DetailedHTMLProps<
React.InputHTMLAttributes<HTMLInputElement>,
HTMLInputElement
>;

const InputSearch = ({ onChangeValue, placeholder, inputContainerClassName, ...props }: Props) => {
    const { t } = useTranslation();
    const [textLength, setTextLength] = useState<number>(0);
    const inputRef = useRef<HTMLInputElement>(null);

    const clearInput = () => {
        setTextLength(0);
        onChangeValue('');
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    return (
        <div>
            <div className={`${styles.inputContainer} ${inputContainerClassName || ''}`}>
                <input
                    ref={inputRef}
                    type={props.type ?? 'text'}
                    placeholder={placeholder}
                    {...props}
                    onChange={e => {
                        setTextLength(e.currentTarget.value.length);
                        onChangeValue(e.currentTarget.value);
                    }}
                    className={`${styles.inputContent} ${props.className ?? ''}`}
                />
                {textLength === 0 && <img className={styles.inputImage} src={SearchIcon} />}
                {textLength !== 0 && <img className={`${styles.inputImage} ${styles.pointer}`} src={CloseIcon} onClick={clearInput} />}
            </div>

            {textLength === props.maxLength && (
                <div>
                    {t('shared_translations.common.field_max_length', { value: textLength })}
                </div>
            )}
        </div>
    );
};

export default memo(InputSearch);
