import ContentConfigurationsService from 'api/contentConfigurations/ContentConfigurationsService';
import { ContentConfigurationDto } from 'api/contentConfigurations/models/ContentConfigurationDto';
import Button, { Color } from 'common/components/buttons/Button';
import ImageInput from 'common/components/inputs/imageInput/ImageInput';
import WarningToast from 'common/components/warningToast/WarningToast';
import CustomFile from 'common/models/CustomFile';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { ApplicationPaths, LOGGER_LOG_TYPE } from 'Config';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useForm, useWatch } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import styles from './DefinitionScreen.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import ContentLayout from 'common/layouts/contentLayout/ContentLayout';
import Navbar from 'common/layouts/navBar/Navbar';
import { Tab } from 'screens/backoffice/BackofficeScreen';
import { BreadCrumb } from 'common/types/BreadCrumb';
import { useSelector } from 'react-redux';
import { UserProfile } from 'api/account/models/UserProfile';
import hasPolicies from 'common/utils/hasPolicies';
import { Reducers } from 'store/types';

const Types = {
    edit: 'edit',
    details: 'details',
};
const DefinitionScreen = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id, type } = useParams<{ id: string; type: string }>();
    const [isDetails, setIsDetails] = useState<boolean>(
        !!(type && type === Types.details)
    );

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = hasPolicies(userProfile, ['SETTINGUP_DEFINITIONS_WRITE']);

    const breadCrumbs: BreadCrumb[] = [
        {
            text: t('menu.backoffice'),
            url: ApplicationPaths.Backoffice,
        },
        {
            text: t('backoffice.definitions.definition_profile'),
        },
    ];

    const [image, setImage] = useState<CustomFile | null>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(null);

    const { handleSubmit, setValue, control } = useForm<ContentConfigurationDto>();

    const internalFind = useWatch({ name: 'internalFind', control });

    const onChooseImage = (file: File, dataUrl: string) => {
        setImage(file);
        setImageUrl(dataUrl);
    };

    const onDeleteImage = () => {
        setImage(null);
        setImageUrl(null);
        setValue('removeMedia', true);
    };

    useEffect(() => {
        setIsDetails(!!(type && type === Types.details));
        void getData();
    }, [id, type]);

    const getData = async () => {
        if (id && id != null) {
            const result = await ContentConfigurationsService.getById(id);
            if (result.mediaUrl) {
                setImageUrl(result.mediaUrl);
            }
            setValue('internalFind', result.internalFind);
        }
    };

    const onSubmit = async (content: ContentConfigurationDto) => {
        try {
            Loading.show();
            await ContentConfigurationsService.update(content, image);
            toast.success(`${t('common.messages.record_save_success')}`);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't save content configurations", error);
            toast.error(`${t('common.messages.record_save_error')}`);
            Loading.hide();
        }
    };

    const onError = async () => {
        toast(`${t('common.messages.required_fields_empty')}`, {
            icon: <WarningToast />,
        });
    };

    const navigateTo = (typeUrl?: string, idToGo?: string) => {
        if (typeUrl) {
            navigate(`${ApplicationPaths.Backoffice}/definitions/${typeUrl}/${idToGo}`);
            setIsDetails(typeUrl === Types.details);
        } else {
            navigate(ApplicationPaths.Backoffice, { state: { tab: Tab.DEFINITIONS } });
        }
    };
    return (
        <>
            <Navbar breadCrumbs={breadCrumbs} isBackoffice={true}/>
            <ContentLayout>
                <form className={styles.form} onSubmit={handleSubmit(onSubmit, onError)}>
                    <Row>
                        <Col xs={12}>
                            <div className={styles.containerButtons}>
                                <Button
                                    text={t('shared_translations.common.go_back')}
                                    onClick={() => navigateTo()}
                                    color={Color.white}
                                />
                                {type === Types.details && canWrite && (
                                    <Button
                                        text={t('shared_translations.common.edit')}
                                        onClick={() => {
                                            navigateTo('edit', id);
                                        }}
                                        color={Color.black}
                                    />
                                )}
                                {canWrite && type !== Types.details && (
                                    <Button
                                        text={t('shared_translations.common.save')}
                                        type="submit"
                                        color={Color.black}
                                    />
                                )}

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div className={styles.mainDiv}>
                                <div className={styles.subDiv}>
                                    <div className={styles.label}>{t('backoffice.definitions.' + (internalFind)?.toLocaleLowerCase())}</div>
                                    <div className={styles.image}>
                                        <ImageInput
                                            onChooseFile={onChooseImage}
                                            previewUrl={imageUrl}
                                            onDelete={onDeleteImage}
                                            disabled={isDetails || !canWrite}
                                            cropImage={false}
                                            roundDisplay={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </form>

            </ContentLayout>
        </>
    );
};

export default DefinitionScreen;
