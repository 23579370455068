import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './TextArea.module.scss';

type Props = {
    register?: UseFormRegister<any>;
    name?: string
    disabled?: boolean
    required?: boolean
    showRequired?: boolean
    maxLength?: number;
    rows?: number;
    onChange?: any
    pattern?: string;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const TextArea = (props: Props) => {
    const { register, name, disabled = false, required = false, showRequired = false, maxLength, rows, onChange, pattern, ...rest } = props;
    const { t } = useTranslation();

    const registerProps: any = (register && name
        ? register(name, {
            required,
            maxLength,
            validate: required ? (value) => { return !value?.trim() ? (t('shared_translations.messages.required_field') ?? false) : true; } : undefined
        })
        : {});

    const checkChange = (e: any) => {
        if (pattern) {
            e.target.value = e.target.value.replace(new RegExp(pattern, 'ig'), '');
        }

        if (onChange) {
            onChange(e);
        }

        if (registerProps?.onChange) {
            registerProps?.onChange(e);
        }
    };

    return (

        <div className={`${styles.box} ${showRequired ? styles.warning : styles.noWarning}`}>
            <textarea
                {...rest}
                {...registerProps}
                rows={rows}
                disabled={disabled}
                onChange={(e) => checkChange(e)}
                className={`${styles.input} ${props.disabled ? styles.disabled : ''} ${rest.className ? rest.className : ''}`}
            />
        </div>
    );
};

export default TextArea;
