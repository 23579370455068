import Http from '../../common/services/Http';
import PinterestDto, { PinterestPageDto } from './models/PinterestDto';
import { PinterestPinsSearchCriteria } from './models/PinterestPinsSearchCriteria';

class PinterestService {
    public getPins (criteria: PinterestPinsSearchCriteria): Promise<PinterestPageDto> {
        return Http.get<PinterestPageDto>('pinterest/pins', criteria);
        // return Http.get<Paged<string[]>>('pinterest/pins', criteria);
    }

    public getList (): Promise<PinterestDto[]> {
        return Http.get<PinterestDto[]>('pinterest/getApiParameters');
    }
}

export default new PinterestService();
