import ContentLayout from 'common/layouts/contentLayout/ContentLayout';
import Navbar from 'common/layouts/navBar/Navbar';
import { BreadCrumb } from 'common/types/BreadCrumb';
import { useTranslation } from 'react-i18next';
import styles from './InspirationScreen.module.scss';
import { Col, Row } from 'react-flexbox-grid';
import Button, { Color } from 'common/components/buttons/Button';
import FormItem from 'common/components/formItem/FormItem';
import Label from 'common/components/label/Label';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useParams, Navigate } from 'react-router-dom';
import WarningToast from 'common/components/warningToast/WarningToast';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE, ApplicationPaths } from 'Config';
import { ChangeEvent, useEffect, useState } from 'react';
import SelectInput from 'common/components/inputs/SelectInput';
import EditableTextInput, { InputType } from 'common/components/editableText/EditableText';
import InputError from 'common/components/inputs/inputError/InputError';
import InspirationDto, { InspirationAttachmentPoint, InspirationListDto } from 'api/inspirations/models/InspirationDto';
import ImageInput from 'common/components/inputs/imageInput/ImageInput';
import LanguageDto from 'api/languages/models/LanguageDto';
import Loading from 'common/services/Loading';
import LanguagesService from 'api/languages/LanguagesService';
import LanguagesTab from 'common/components/languagesTabs/LanguagesTabs';
import CopyContent from 'common/components/copyContent/CopyContent';
import InspirationService from 'api/inspirations/InspirationService';
import CategoriesService from 'api/categories/CategoriesService';
import { CategorieDto } from 'api/categories/models/CategorieDto';
import { Reducers } from 'store/types';
import { useSelector } from 'react-redux';
import { UserProfile } from 'api/account/models/UserProfile';
import hasPolicies from 'common/utils/hasPolicies';
import CustomFile from 'common/models/CustomFile';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import EditableImage, { EditableImageMode, PointData } from 'common/components/editableImage/EditableImage';
import InspirationPointTooltipContent from 'common/components/editableImage/point/inspirationPointTooltipContent/InspirationPointTooltipContent';
import i18n from 'common/services/I18n';
import Input from 'common/components/inputs/input/Input';
import ImagePointForm from './addPoint/ImagePointForm';
import CommentFavorites from './favorites/comment/CommentFavorites';
import newGuid from 'common/utils/newGuid';
import ImageGrid from './imageGrid/ImageGrid';
import { LanguageFile, LanguageFileAttachment } from './models/LanguageFile';
import { CanvasDrawingMode } from 'common/components/editableImage/canvasImage/CanvasImage';
import ImageNoteService from 'api/imageNotes/ImageNoteService';
import InspirationCommentDto from 'api/inspirationComments/models/InspirationCommentDto';
import InspirationCommentService from 'api/inspirationComments/InspirationCommentService';
import { ClientDto } from 'api/clients/models/ClientDto';
import InspirationFavoriteDto from 'api/inspirationFavorites/models/InspirationFavoriteDto';
import InspirationFavoriteService from 'api/inspirationFavorites/InspirationFavoriteService';
import useIsMobile from 'common/hooks/useIsMobile';
import FloatingComment from './favorites/floatingComment/FloatingComment';
import { urlToFile } from 'common/utils/urlToFile';
import { PixelCrop } from 'react-image-crop';
import Favorites from 'common/components/favorites/Favorites';
import PinterestService from 'api/pinterest/PinterestService';
import Layout from 'common/components/masonryList/Layout';
import { PinterestPageDto, PinterestPagePictureDto } from 'api/pinterest/models/PinterestDto';
import { PinterestPinsSearchCriteria } from 'api/pinterest/models/PinterestPinsSearchCriteria';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import UploaderIcon from 'common/components/inputs/uploaderIcon/UploaderIcon';
import CanvasModal, { CanvasModalMode } from 'common/components/editableImage/canvasModal/CanvasModal';
import dataURLtoFile from 'common/utils/dataURLtoFile';

const Types = {
    create: 'create',
    edit: 'edit',
    details: 'details',
};

interface SelectedInspirationAttachmentPoint {
    point: InspirationAttachmentPoint;
    languageId: string;
    fileKey: string;
    isNew?: boolean;
    isTemporary?: boolean;
}

export interface AttachmentsToRemove {
    languageId: string,
    fileId: string
}

function InspirationScreen (): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = hasPolicies(userProfile, ['SETTINGUP_INSPIRATIONS_WRITE']);
    const { masterRecordId, type } = useParams<{ masterRecordId: string; type: string }>();
    const categoryId = location?.state?.categoryId;
    const subCategoryId = location?.state?.subCategoryId;
    const { handleSubmit, formState, reset, getValues, setValue, control, register, clearErrors, setError } = useForm<InspirationListDto>({ shouldUnregister: false });
    const [selectedLanguageIndex, setSelectedLanguageIndex] = useState(0);
    const [languages, setLanguages] = useState<LanguageDto[]>([]);
    const [categoriesParent, setCategoriesParent] = useState<CategorieDto[]>([]);
    const [subCategoriesParent, setSubCategoriesParent] = useState<CategorieDto[]>([]);
    const [isDetails, setIsDetails] = useState<boolean>(
        !!(type && type === Types.details)
    );
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [showRemoveFavoriteModal, setShowRemoveFavoriteModal] = useState<boolean>(false);
    const [removeMessage, setRemoveMessage] = useState<string>('');

    const [pointsEditMode, setPointsEditMode] = useState<EditableImageMode>('none');
    const [selectedImagePoint, setSelectedImagePoint] = useState<SelectedInspirationAttachmentPoint | null>(null);
    const [canvasDrawingMode, setImageDrawingMode] = useState<CanvasDrawingMode>('none');
    const [canvasDrawingModePinterest, setImageDrawingModePinterest] = useState<CanvasDrawingMode>('move');

    const [inspirations, setInspirations] = useState<InspirationDto[]>([]);

    const [addInspirationFavorite, setAddInspirationFavorite] = useState<boolean>(true);
    const [favoriteMasterRecordId, setFavoriteMasterRecordId] = useState<string | undefined>(undefined);
    const [allLanguages, setAllLanguages] = useState<LanguageDto[]>([]);
    const [showFavoriteAndComment, setShowFavoriteAndComment] = useState<boolean>(false);

    const [imagesForPin, setImagesForPin] = useState<PinterestPagePictureDto[]>([]);
    const inititalBreadCrumbs = {
        text: t('menu.start'),
        url: ApplicationPaths.DefaultLoginRedirectPath,
    };
    const [breadCrumbs, setBread] = useState<BreadCrumb[]>([inititalBreadCrumbs]);

    const [attachments, setAttachments] = useState<LanguageFile[]>([]);
    const [attachmentsToRemove, setAttachmentsToRemove] = useState<AttachmentsToRemove[]>([]);

    const isMobile = useIsMobile();

    const [criteriaPinterestPins, setCriteriaPinterestPins] = useState<PinterestPinsSearchCriteria | null>(null);

    const messageSaveSuccess = t('shared_translations.messages.record_save_success');
    const messageSaveError = t('shared_translations.messages.record_save_error');
    const messageDeletedError = t('shared_translations.messages.record_delete_error');
    const messageLoadInfoError = t('shared_translations.messages.error_load_info');

    const selectedClient = useSelector<Reducers, ClientDto | null>((state) => state.chosenUser.user);

    const clientId = selectedClient?.id ?? null;
    const showEditLayout = useSelector<Reducers, boolean>(state => state.editLayout.open);

    const [selectedSearchImage, setSelectedSearchImage] = useState<boolean | undefined>(undefined);

    const [searchImageActive, setSearchImageActive] = useState<LanguageFileAttachment | null>(null);
    const [showSearchImageModal, setShowSearchImageModal] = useState<boolean>(false);

    const [pinterestBase64Image, setPinterestBase64Image] = useState<string | undefined>(undefined);
    const [showCanvasPinterestImageModal, setShowCanvasPinterestImageModal] = useState<boolean>(false);

    const onChooseAttachment = async (file: CustomFile, url: string, languageId: string) => {
        const key = newGuid();
        file.containerName = `${languageId}__${key}`;
        const mediaId = isDetails
            ? await InspirationService.saveUserImage(
                {
                    inspirationId: inspirations.find(x => x.languageId === languageId)?.id ?? '',
                    userId: clientId ?? undefined,
                }, file)
            : undefined;

        setAttachments(attachments.map(at => {
            if (at.languageId === languageId) {
                return {
                    ...at,
                    files: [
                        ...at.files,
                        {
                            file,
                            url,
                            key,
                            id: mediaId ?? '',
                            points: [],
                            pointsToRemove: [],
                            selected: at.files.length === 0,
                            drawingData: null,
                            notes: [],
                            userId: clientId ?? null
                        }
                    ],
                };
            }

            return { ...at };
        }));
    };

    const onDeleteAttachment = async (langId: string, fileKey: string, id: string | undefined) => {
        if (id !== '' && id !== undefined) {
            if (isDetails) {
                await InspirationService.removeUserImage(
                    {
                        inspirationId: inspirations.find(x => x.languageId === langId)?.id ?? '',
                        userId: clientId ?? undefined,
                        mediaId: id
                    });
                void getData();
            } else {
                setAttachmentsToRemove([...attachmentsToRemove, { languageId: langId, fileId: id }]);
            }
        }

        setAttachments(attachments.map(at => {
            if (at.languageId === langId) {
                const newFiles = at.files.filter((f) => f.key !== fileKey);

                if (newFiles.filter(x => x.selected).length === 0 && newFiles.length > 0) {
                    newFiles[0].selected = true;
                }

                return {
                    ...at,
                    files: newFiles,
                };
            }
            return { ...at };
        }));
    };

    const [submitNoImages, setSubmitNoImages] = useState<boolean>(false);

    useEffect(() => {
        setIsDetails(!!(type && type === Types.details));
        void getData();
        if (!selectedClient || showEditLayout) {
            onCancelSearchImage();
        }
    }, [selectedClient, masterRecordId, type, i18n.language, showEditLayout]);

    const getData = async () => {
        try {
            Loading.show();
            let listLanguages = await LanguagesService.getAll();
            setAllLanguages(listLanguages);

            if (isDetails) {
                let languagesFinal = listLanguages.filter(x => x.iso === i18n.language);

                if (languagesFinal.length === 0) { languagesFinal = listLanguages.filter(x => x.isDefault); }
                listLanguages = languagesFinal;
            }

            listLanguages[0].languageTabVisited = true;
            setLanguages(listLanguages);

            let inspiration: InspirationDto[] = [];
            const model: InspirationListDto = { list: [] };

            let subcategories: CategorieDto[] = [];
            if (type !== Types.create && masterRecordId != null) {
                inspiration = await InspirationService.get(masterRecordId, (selectedClient && !showEditLayout && isDetails) ? selectedClient.id : '00000000-0000-0000-0000-000000000000');
                setInspirations(inspiration);

                subcategories = await CategoriesService.getByMasterRecordParentId(categoryId);
                setSubCategoriesParent(subcategories);
            }

            const allCategories = await CategoriesService.getAllActive();
            setCategoriesParent(allCategories);
            makeBreadCrumbs(listLanguages, allCategories, subcategories, inspiration);

            model.list = listLanguages.map(lang => {
                const categorySelected = allCategories?.find(x => x.languageId === lang.id && x.masterRecordId === categoryId);
                const subcategorySelected = subcategories.find(x => x.languageId === lang.id && x.masterRecordId === subCategoryId);
                const inspirationItem = inspiration?.find(x => x.languageId === lang.id);

                if (inspirationItem) {
                    if (inspirationItem.favorites && inspirationItem.favorites.length > 0) {
                        setFavoriteMasterRecordId(inspirationItem.favorites[0].masterRecordId);
                        setAddInspirationFavorite(false);
                    } else {
                        setFavoriteMasterRecordId(undefined);
                        setAddInspirationFavorite(true);
                    }
                }

                if (categorySelected) {
                    return {
                        masterRecordId,
                        id: inspirationItem ? inspirationItem.id : null,
                        position: inspirationItem?.position,
                        categoryId: categorySelected.masterRecordId,
                        subCategoryId: subcategorySelected?.masterRecordId,
                        title: inspirationItem?.title ?? '',
                        description: inspirationItem?.description,
                        languageId: lang.id,
                        comments: inspirationItem?.comments,
                        favorites: inspirationItem?.favorites
                    };
                }

                return {
                    id: null,
                    position: undefined,
                    title: '',
                    description: '',
                    languageId: lang.id,
                    masterRecordId,
                };
            });

            setAttachments(listLanguages.map(lang => {
                const atts = inspiration?.find(x => x.languageId === lang.id)?.attachments ?? [];
                const oldAtts = attachments.find(x => x.languageId === lang.id)?.files ?? [];
                const alreadySelectedIndex = oldAtts.findIndex(x => x.selected);

                return {
                    languageId: lang.id,
                    files: atts.map((f, i) => {
                        return {
                            file: null,
                            pinterestPinId: f.pinterestPinId,
                            url: f.attachment?.url ?? '',
                            id: f.attachment?.id ?? '',
                            points: f.points.map(p => ({ ...p, key: newGuid() })),
                            pointsToRemove: [],
                            key: newGuid(),
                            selected: (!selectedClient || showEditLayout) ? (atts.indexOf(f) === 0) : (alreadySelectedIndex > -1 ? i === alreadySelectedIndex : i === 0),
                            drawingData: null,
                            notes: f.notes ?? [],
                            userId: f.attachment?.userId
                        };
                    })
                };
            }));

            reset(model);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t  get inspiration', error);
            toast.error(messageLoadInfoError);
            Loading.hide();
        }
    };

    const makeBreadCrumbs = (listLanguages: LanguageDto[], allCategories: CategorieDto[], subcategories: CategorieDto[], inspiration: InspirationDto[]) => {
        if (type !== Types.create) {
            const categorie = allCategories?.find(x => x.languageId === listLanguages[0].id && x.masterRecordId === categoryId);
            const subcategorie = subcategories?.find(x => x.languageId === listLanguages[0].id && x.masterRecordId === subCategoryId);
            setBread([inititalBreadCrumbs,
                { text: categorie?.name ?? '', url: `${ApplicationPaths.Home}/${categorie?.masterRecordId}` },
                { text: subcategorie?.name ?? '', url: `${ApplicationPaths.Home}/${categorie?.masterRecordId}/${subcategorie?.masterRecordId}` },
                { text: inspiration?.find(x => x.languageId === listLanguages[0].id)?.title ?? '' }]);
        } else {
            setBread([inititalBreadCrumbs,
                { text: t('home_screen.inspirations.dossier_inspiration') },
                { text: t('home_screen.inspirations.add_inspiration') }]);
        }
    };

    const onSubmit = async (model: InspirationListDto) => {
        const tempModel = { ...model };
        try {
            if (!(masterRecordId && masterRecordId !== '') && languages.find(x => !x.languageTabVisited)) {
                void notSubmitted();
                return;
            }

            let existImages = true;
            languages.forEach(lang => {
                const numberImages = attachments.find(x => x.languageId === lang.id)?.files.length;
                if (numberImages === 0) {
                    existImages = false;
                }
            });

            if (!existImages) {
                setSubmitNoImages(true);
                toast(`${t('home_screen.inspirations.add_at_least_one_image_in_each_language')}`, {
                    icon: <WarningToast />,
                });
                return;
            }
            const filesToSend: CustomFile[] = [];
            attachments.forEach(x => x.files.forEach(y => y.file && filesToSend.push(y.file)));

            for (const item of tempModel.list) {
                const languageFiles = attachments.find(x => x.languageId === item.languageId)?.files ?? [];
                item.attachments = languageFiles.map(f => ({
                    key: f.key,
                    pointsToRemove: f.pointsToRemove,
                    points: f.points,
                    mediaId: f.id || null,
                }));
            }

            Loading.show();
            if (masterRecordId && masterRecordId != null) {
                await InspirationService.update({ masterRecordId, attachmentsToRemove, inspirations: tempModel.list }, filesToSend);
                toast.success(messageSaveSuccess);
                navigateTo();
            } else {
                await InspirationService.create({ inspirations: tempModel.list }, filesToSend);
                toast.success(messageSaveSuccess);
                navigateTo();
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't create/update categorie", error);
            toast.error(messageSaveError);
            Loading.hide();
        }
    };

    const notSubmitted = async (errorsInForm?: any) => {
        verifyAllErros(errorsInForm, true);
        toast(`${t('shared_translations.messages.please_confirm_all_data')}`, {
            icon: <WarningToast />,
        });
    };

    const navigateTo = (typeUrl?: string, idToGo?: string) => {
        if (typeUrl) {
            navigate(`${ApplicationPaths.Inspirations}/${typeUrl}/${idToGo}`);
            setIsDetails(typeUrl === Types.details);
        } else {
            navigate(ApplicationPaths.DefaultLoginRedirectPath);
        }
    };

    const onLanguageChange = (lang: LanguageDto, i: number) => {
        if (!masterRecordId && !lang.languageTabVisited) {
            const defaultLanguage = languages.find(l => l.isDefault);
            if (defaultLanguage) {
                void copyLanguage(i, defaultLanguage.id);
            }
        }

        setLanguages([
            ...languages.map(l => {
                if (l.id === lang.id) {
                    return {
                        ...l,
                        languageTabVisited: true
                    };
                }
                return l;
            }),
        ]);

        setSelectedLanguageIndex(i);
        verifyAllErros(formState.errors, false);
    };

    const customErroTitle = (title: string, index: number) => {
        if (title !== '' && title != null) {
            clearErrors(`list.${index}.title`);
        } else {
            setError(`list.${index}.title`, { type: 'custom', message: t('shared_translations.messages.required_field') });
        }
    };

    const customErroCategory = (category: string, index: number) => {
        if (category) {
            clearErrors(`list.${index}.categoryId`);
        } else {
            setError(`list.${index}.categoryId`, { type: 'custom', message: t('shared_translations.messages.required_field') });
        }
    };

    const verifyAllErros = (errorsInForm: any, onlyTab: boolean) => {
        for (let i = 0; i < languages.length; i++) {
            const language = languages[i];
            language.invalid = onlyTab
                ? Boolean((errorsInForm?.list || [])[i]) || (!masterRecordId && !language.languageTabVisited)
                : Boolean((errorsInForm?.list || [])[i]);
        }
    };

    const copyLanguage = async (destinationIndex: number, langToCopyId: string) => {
        const sourceItem = getValues('list').find(x => x.languageId === langToCopyId);
        const attachmentsToCopy = attachments.find(x => x.languageId === langToCopyId)?.files ?? [];
        const destinationLanguageId = getValues('list')[destinationIndex].languageId;

        const newAttachments = [];
        for (const att of attachments) {
            if (att.languageId === destinationLanguageId) {
                const newFiles = [];

                att.files.forEach((a) => {
                    if (a.id !== '' && a.id !== undefined) {
                        setAttachmentsToRemove((ar) => [...ar, { languageId: att.languageId, fileId: a.id }]);
                    }
                });

                for (const x of attachmentsToCopy) {
                    const newFile: CustomFile | null = x.file
                        ? new File([
                            x.file
                        ], x.file.name, { type: x.file.type })
                        : await urlToFile(x.url, 'hello_world.png');

                    const key = newGuid();

                    if (newFile) {
                        newFile.containerName = `${destinationLanguageId}__${key}`;
                    }

                    newFiles.push({
                        ...x,
                        key,
                        file: newFile,
                        id: '',
                        pointsToRemove: [],
                        points: x.points.map(p => ({ ...p, key: newGuid(), id: null }))
                    });
                }

                newAttachments.push({
                    ...att,
                    files: [...newFiles],
                });
            } else {
                newAttachments.push({ ...att });
            }
        }

        setAttachments([...newAttachments]);

        if (sourceItem) {
            setValue(`list.${destinationIndex}.title` as 'list.0.title', sourceItem.title);
            setValue(`list.${destinationIndex}.description` as 'list.0.description', sourceItem.description);
            setValue(`list.${destinationIndex}.position` as 'list.0.position', sourceItem.position);

            customErroTitle(sourceItem.title, destinationIndex);
            verifyAllErros(formState.errors, false);
        }
    };

    const clearLanguage = (destinationIndex: number, langToCopyId: string) => {
        const sourceItem = getValues('list').find(x => x.languageId === langToCopyId);
        const destinationLanguageId = getValues('list')[destinationIndex].languageId;

        setAttachments(attachments.map(att => {
            if (att.languageId === destinationLanguageId) {
                return { ...att, files: [] };
            }
            return { ...att };
        }));

        if (sourceItem) {
            setValue(`list.${destinationIndex}.title` as 'list.0.title', '');
            setValue(`list.${destinationIndex}.description` as 'list.0.description', '');
            setValue(`list.${destinationIndex}.position` as 'list.0.position', undefined);
            customErroTitle('', destinationIndex);
            verifyAllErros(formState.errors, false);
        }
    };

    const setCategoriesEqual = async (id: string) => {
        const sourceItem = getValues('list');
        sourceItem.forEach((_, index) => {
            setValue(`list.${index}.categoryId` as 'list.0.categoryId', id);
            customErroCategory(id, index);
        });
        sourceItem.forEach((_, index) => { setValue(`list.${index}.subCategoryId` as 'list.0.subCategoryId', ''); });
        const subcategories = id ? await CategoriesService.getByMasterRecordParentId(id) : [];
        setSubCategoriesParent(subcategories);
        verifyAllErros(formState.errors, false);
    };

    const setSubCategoriesEqual = (id: string) => {
        const sourceItem = getValues('list');
        sourceItem.forEach((_, index) => { setValue(`list.${index}.subCategoryId` as 'list.0.subCategoryId', id); });
    };

    const onValueChangePosition = (e: ChangeEvent<HTMLInputElement>) => {
        const sourceItem = getValues('list');
        sourceItem.forEach((_, index) => { setValue(`list.${index}.position` as 'list.0.position', Number(e.target.value)); });
    };

    if (!canWrite && type !== Types.details) {
        return <Navigate replace={true} to={ApplicationPaths.Home} />;
    }

    const removeItem = async () => {
        if (masterRecordId && masterRecordId != null) {
            try {
                Loading.show();
                await InspirationService.remove({ masterRecordId });
                toast.success(`${t('shared_translations.messages.record_delete_success')}`);
                Loading.hide();
                navigateTo();
            } catch (error) {
                setShowRemoveModal(false);
                toast.error(messageDeletedError);
                Loading.hide();
            }
        }
    };

    const onCancelRemove = () => {
        setShowRemoveModal(false);
    };

    const onCancelRemoveFavorite = () => {
        setShowRemoveFavoriteModal(false);
    };

    const buttonsDiv = () => {
        return (
            !isDetails && canWrite && <Col xl lg={12} sm={12} xs={12} className={styles.buttonsContent}><Button
                text={t('shared_translations.common.cancel')}
                onClick={() => navigateTo()}
                color={Color.white}
            />
            {type !== Types.create && (
                <Button
                    text={t('shared_translations.common.delete')}
                    onClick={() => setShowRemoveModal(true)}
                    color={Color.red}
                />
            )}
            {type !== Types.details && (
                <Button
                    text={t('shared_translations.common.register')}
                    type="submit"
                    color={Color.black}
                />
            )}
            {type === Types.details && (
                <Button
                    text={t('shared_translations.common.edit')}
                    onClick={() => navigateTo(Types.edit, masterRecordId)}
                    color={Color.black}
                />
            )}
            </Col>);
    };

    const updateImagePoint = (languageId: string, fileKey: string, pointKey: string, newPoint: PointData) => {
        setAttachments(attachments.map(at => {
            if (at.languageId === languageId) {
                return {
                    ...at,
                    files: at.files.map(f => {
                        if (f.key === fileKey) {
                            return ({
                                ...f,
                                points: f.points.map(p => {
                                    if (p.key === pointKey) {
                                        const price = (newPoint as InspirationAttachmentPoint).price;
                                        return { ...p, ...newPoint, price: price ? Number(price) : null };
                                    }
                                    return { ...p };
                                })
                            });
                        }
                        return { ...f };
                    })
                };
            }
            return { ...at };
        }));
    };

    const addImagePoint = (languageId: string, fileKey: string, point: InspirationAttachmentPoint) => {
        setAttachments(attachments.map(at => {
            if (at.languageId === languageId) {
                return {
                    ...at,
                    files: at.files.map(f => {
                        if (f.key === fileKey) {
                            return ({ ...f, points: [...f.points, point] });
                        }
                        return { ...f };
                    })
                };
            }
            return { ...at };
        }));
    };

    const removeImagePoint = (languageId: string, fileKey: string, pointKey: string) => {
        setAttachments(attachments.map(at => {
            if (at.languageId === languageId) {
                return {
                    ...at,
                    files: at.files.map(f => {
                        if (f.key === fileKey) {
                            const point = f.points.find(x => x.key === pointKey);
                            const toRemoveArr = point?.id ? [point.id] : [];
                            return ({
                                ...f,
                                points: f.points.filter(fp => fp.key !== pointKey),
                                pointsToRemove: [...f.pointsToRemove, ...toRemoveArr]
                            });
                        }
                        return { ...f };
                    }),
                };
            }
            return { ...at };
        }));
    };

    const onAddImagePoint = (pointData: PointData, languageId: string, fileKey: string) => {
        // If there is one image point selected than move it
        if (selectedImagePoint && !selectedImagePoint.isTemporary) {
            const newPoint: InspirationAttachmentPoint = {
                ...selectedImagePoint.point,
                x: pointData.x,
                y: pointData.y
            };

            setSelectedImagePoint({ ...selectedImagePoint, point: newPoint });
            updateImagePoint(languageId, fileKey, selectedImagePoint.point.key, newPoint);
        } else { // Else create a new point
            const point: InspirationAttachmentPoint = { ...pointData, name: '', description: '', price: null, id: null, clientId: null };

            setSelectedImagePoint({ point, languageId, fileKey, isNew: true });
            addImagePoint(languageId, fileKey, point);
        }
    };

    const onNewImagePointClick = (languageId: string, fileKey: string) => {
        setPointsEditMode('add-point');

        setSelectedImagePoint({
            fileKey,
            languageId,
            isNew: true,
            isTemporary: true,
            point: {
                clientId: null,
                description: '',
                price: null,
                key: newGuid(),
                id: null,
                name: '',
                x: 0,
                y: 0,
                icon: 'default',
            }
        });
    };

    const onNewImageNoteClick = () => {
        setPointsEditMode('add-note');

        setImageDrawingMode('move');
    };

    const onNewImagePinterestNoteClick = async (imageUrl: string) => {
        try {
            Loading.show();

            const base64 = imageUrl ? await InspirationService.getImageBase64FromUrl(imageUrl) : null;
            setPinterestBase64Image(base64 ? ('data:image/png;base64,' + base64) : imageUrl);
            setShowCanvasPinterestImageModal(true);

            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't load pinterest data", error);
            toast.error(messageLoadInfoError);
            Loading.hide();
        }
    };

    const onSaveImagePinterestDrawing = async (drawingData: string, croppedBase64Image: string) => {
        if (!clientId) {
            return;
        }

        try {
            Loading.show();

            const imageUrl = pinterestBase64Image;
            const language = languages[selectedLanguageIndex];

            const newFile: CustomFile | null = croppedBase64Image ? dataURLtoFile(croppedBase64Image, 'pinterest.png') : null;

            if (imageUrl && language && newFile) {
                const key = newGuid();
                newFile.containerName = `${language.id}__${key}`;

                const mediaId = await InspirationService.saveUserImage({
                    inspirationId: inspirations.find(x => x.languageId === language.id)?.id ?? '',
                    userId: clientId ?? undefined,
                }, newFile);

                if (!mediaId) {
                    Loading.hide();
                    return;
                }

                await ImageNoteService.save({
                    id: null,
                    clientId,
                    drawingData,
                    mediaId,
                });

                toast.success(messageSaveSuccess);
            }

            setPinterestBase64Image(undefined);
            setShowCanvasPinterestImageModal(false);
            setImageDrawingModePinterest('move');
            onCancelSearchImage();
            void getData();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't save drawing data", error);
            toast.error(messageSaveError);
            Loading.hide();
        }
    };

    const onCancelImagePinterestNoteClick = () => {
        setPinterestBase64Image(undefined);
        setShowCanvasPinterestImageModal(false);
        setImageDrawingModePinterest('move');
    };

    const onImagePointClick = (point: InspirationAttachmentPoint, languageId: string, fileKey: string) => {
        if (isDetails) {
            return;
        }

        setPointsEditMode('add-point');
        setSelectedImagePoint({ point, languageId, fileKey });
    };

    const onCancelImagePoint = () => {
        if (selectedImagePoint?.isNew) {
            removeImagePoint(selectedImagePoint.languageId, selectedImagePoint.fileKey, selectedImagePoint.point.key);
        }

        setSelectedImagePoint(null);
        setPointsEditMode('none');
    };

    const onCancelEditImagePoint = (oldPointData: InspirationAttachmentPoint) => {
        if (selectedImagePoint?.isNew) {
            removeImagePoint(selectedImagePoint.languageId, selectedImagePoint.fileKey, selectedImagePoint.point.key);
        } else if (oldPointData && selectedImagePoint) {
            updateImagePoint(selectedImagePoint.languageId, selectedImagePoint.fileKey, selectedImagePoint.point.key, oldPointData);
        }

        setSelectedImagePoint(null);
        setPointsEditMode('none');
    };

    const onSaveImagePoint = (point: InspirationAttachmentPoint) => {
        if (!selectedImagePoint) {
            return;
        }
        updateImagePoint(selectedImagePoint.languageId, selectedImagePoint.fileKey, selectedImagePoint.point.key, point);

        setSelectedImagePoint(null);
        setPointsEditMode('none');
    };

    const onDeleteImagePoint = () => {
        if (!selectedImagePoint) {
            return;
        }

        removeImagePoint(selectedImagePoint.languageId, selectedImagePoint.fileKey, selectedImagePoint.point.key);

        setSelectedImagePoint(null);
        setPointsEditMode('none');
    };

    const selectImage = (languageId: string, fileKey: string) => {
        setAttachments(attachments.map(at => {
            if (at.languageId === languageId) {
                return {
                    ...at,
                    files: at.files.map((f) => {
                        if (f.key === fileKey) {
                            return {
                                ...f,
                                selected: true,
                            };
                        }
                        return {
                            ...f,
                            selected: false,
                        };
                    }),
                };
            }
            return { ...at };
        }));
        setSelectedSearchImage(false);
    };

    const onSaveImageDrawing = async (languageId: string, fileKey: string, drawingData: string) => {
        if (!clientId) {
            return;
        }

        try {
            Loading.show();

            const files = attachments.find(x => x.languageId === languageId)?.files ?? [];
            const media = files.find(x => x.key === fileKey);
            const noteId = (media?.notes ?? []).find(x => x.clientId === clientId)?.id ?? null;

            if (!media?.id) {
                Loading.hide();
                return;
            }

            await ImageNoteService.save({
                clientId,
                drawingData,
                id: noteId,
                mediaId: media?.id,
            });

            toast.success(messageSaveSuccess);

            void getData();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't save drawing data", error);
            toast.error(messageSaveError);
            Loading.hide();
        }
    };

    const onSaveComment = async (comment: InspirationCommentDto) => {
        try {
            Loading.show();
            if (comment.id) {
                const comments: InspirationCommentDto[] = [];
                inspirations.forEach(x => {
                    const inspirationComment = x.comments?.find(inspiration => inspiration.masterRecordId === comment.masterRecordId);
                    if (inspirationComment) {
                        comments.push({
                            id: inspirationComment.id,
                            comment: comment.comment,
                            inspirationId: comment.inspirationId,
                            clientId: comment.clientId,
                            languageId: inspirationComment.languageId,
                            masterRecordId: comment.masterRecordId
                        });
                    }
                });
                await InspirationCommentService.update({ inspirationComments: comments });
            } else {
                const comments: InspirationCommentDto[] = allLanguages.map(lang => {
                    return {
                        id: '',
                        comment: comment.comment,
                        inspirationId: comment.inspirationId,
                        clientId: comment.clientId,
                        languageId: lang.id
                    };
                });
                await InspirationCommentService.create({ inspirationComments: comments });
            }
            toast.success(messageSaveSuccess);
            void getData();
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't create inspiration comment", error);
            toast.error(messageSaveError);
            Loading.hide();
        }
    };

    const onDeleteComment = async (comment: InspirationCommentDto) => {
        try {
            Loading.show();
            await InspirationCommentService.remove({ masterRecordId: comment.masterRecordId });
            toast.success(`${t('shared_translations.messages.record_delete_success')}`);
            void getData();
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't create inspiration comment", error);
            toast.error(messageDeletedError);
            Loading.hide();
        }
    };

    const onAddFavorite = async () => {
        try {
            Loading.show();
            const favorite: InspirationFavoriteDto[] = allLanguages.map(lang => {
                return {
                    id: '',
                    inspirationId: masterRecordId && masterRecordId !== '' ? masterRecordId : '',
                    clientId: selectedClient ? selectedClient.id : '',
                    languageId: lang.id,
                    masterRecordId: ''
                };
            });
            const _favoriteMasterRecordId = await InspirationFavoriteService.create({ inspirationFavorites: favorite });
            setFavoriteMasterRecordId(_favoriteMasterRecordId);
            setAddInspirationFavorite(false);
            setShowFavoriteAndComment(true);
            toast.success(`${t('home_screen.inspirations.add_to_favorites')}`);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't create inspiration favorite", error);
            toast.error(messageSaveError);
            Loading.hide();
        }
    };

    const removeFavorite = async () => {
        if (favoriteMasterRecordId && favoriteMasterRecordId != null) {
            try {
                Loading.show();
                await InspirationFavoriteService.remove({ masterRecordId: favoriteMasterRecordId, userId: clientId ?? undefined });
                setAddInspirationFavorite(true);
                setShowFavoriteAndComment(false);
                setShowRemoveFavoriteModal(false);
                toast.success(`${t('home_screen.inspirations.remove_to_favorites')}`);
                void getData();
                Loading.hide();
            } catch (error) {
                toast.error(messageDeletedError);
                Loading.hide();
            }
        }
    };

    const showRemoveFavoriteDialog = async (commentsNumber: number) => {
        if (favoriteMasterRecordId && favoriteMasterRecordId != null) {
            let imagesNumber = -1;

            if (!addInspirationFavorite && selectedClient) {
                imagesNumber = attachments[0].files.filter(x => x.userId === selectedClient.id).length;
            }

            let message = '';
            if (commentsNumber > 0 && imagesNumber > 0) {
                message = t('home_screen.inspirations.associations_favorite_comments_images',
                    { commentsNumber, imagesNumber });
            }
            if (commentsNumber > 0 && imagesNumber <= 0) {
                message = t('home_screen.inspirations.associations_favorite_comments',
                    { commentsNumber });
            }
            if (commentsNumber <= 0 && imagesNumber > 0) {
                message = t('home_screen.inspirations.associations_favorite_images',
                    { imagesNumber });
            }

            setRemoveMessage(message);
            setShowRemoveFavoriteModal(true);
        }
    };

    const onSaveCommentFavorite = async (commentText: string) => {
        setShowFavoriteAndComment(false);
        const inspiration = getValues(`list.${0}`);
        await onSaveComment({
            id: '',
            comment: commentText,
            inspirationId: inspiration.masterRecordId ?? '',
            clientId: selectedClient ? selectedClient.id : '',
            languageId: ''
        });
    };

    const onSearchImage = (pinterestPinId: string | undefined) => {
        if (pinterestPinId) {
            setSelectedSearchImage(true);
        } else {
            setShowSearchImageModal(true);
        }
    };

    const onCancelSearchImage = () => {
        setSelectedSearchImage(false);
        setSearchImageActive(null);
        setImagesForPin([]);
        setCriteriaPinterestPins(null);
    };

    const onChangePinterestPagePins = async (crit?: PinterestPinsSearchCriteria) => {
        const c = crit ?? criteriaPinterestPins;
        if (c) {
            setCriteriaPinterestPins({ ...c, page: c.page++ });
            const page: PinterestPageDto = await PinterestService.getPins(c);
            setCriteriaPinterestPins({ ...c, bookmarks: page.bookmarks });
            setImagesForPin((pins) => [...pins, ...page.pictures]);
        }
    };

    const onCompletedSearchImage = async (data: PixelCrop, imageWidth: number, imageHeight: number) => {
        if (searchImageActive) {
            try {
                Loading.show();
                setImagesForPin([]);
                await onChangePinterestPagePins({
                    page: 0,
                    itemsPerPage: 25,
                    mediaId: searchImageActive.id,
                    x: data.x,
                    y: data.y,
                    width: data.width,
                    height: data.height,
                    widthImage: imageWidth,
                    heightImage: imageHeight,
                    bookmarks: []
                });
                Loading.hide();
            } catch (error) {
                toast.error(messageLoadInfoError);
                Loading.hide();
            }
        }
    };

    const onCancelSearchImageModal = () => {
        setShowSearchImageModal(false);
    };

    const isDetailsClientID = Boolean(isDetails && clientId);
    const isDetailsClientIDAndFavorite = Boolean(isDetails && clientId && favoriteMasterRecordId);
    const noDetailsHasLanguages = !isDetails && languages.length > 1;
    const isDetailsHasClientNoEdit = isDetails && selectedClient && !showEditLayout;

    const goToEdit = () => {
        const item = getValues(`list.${0}`);
        navigate(`${ApplicationPaths.Inspirations}/edit/${item.masterRecordId}`, { state: { categoryId: item.categoryId, subCategoryId: item.subCategoryId } });
        setIsDetails(false);
    };

    const titleFavoritePosition = (length: number) => {
        if (!length) {
            return undefined;
        }
        if (length === 1 && addInspirationFavorite) {
            return '1fr';
        }
        if (length === 1 && !addInspirationFavorite) {
            return undefined;
        }

        return undefined;
    };

    return (
        <div>
            <Navbar breadCrumbs={breadCrumbs} isDetails={isDetails} inputPlaceHolder={isDetails ? t('shared_translations.messages.search_bar_clients_placeholder') : undefined} />
            <ContentLayout>
                <form className={styles.form} onSubmit={handleSubmit(onSubmit, notSubmitted)} >
                    <Row>
                        {buttonsDiv()}
                    </Row>
                    <LanguagesTab
                        selectedLanguageIndex={selectedLanguageIndex}
                        languages={languages}
                        onChange={onLanguageChange}
                        showTab={noDetailsHasLanguages}
                    >
                        {languages.map((lang, index) => {
                            const keyName = `list.${index}`;
                            const itemErrors = (formState.errors.list ?? [])[index];
                            const languageFiles = attachments.find(x => x.languageId === lang.id)?.files ?? [];
                            const selectedImage = languageFiles.find(x => x.selected) ?? null;
                            const currentDrawingData = selectedImage?.notes.find(x => x.clientId === clientId)?.drawingData ?? null;

                            return <div key={`${lang.id}_${index}`}
                                className={`${index !== selectedLanguageIndex ? styles.hideTab : ''}`}>
                                {noDetailsHasLanguages && <CopyContent
                                    languages={languages.filter(x => x.id !== lang.id)}
                                    onCopy={language => copyLanguage(selectedLanguageIndex, language.id)}
                                    onClear={() => clearLanguage(selectedLanguageIndex, lang.id)}
                                />}

                                {!isDetails && <Row className={styles.marginTop}>
                                    <Col xl lg={12} sm={12} xs={12}>
                                        <FormItem>
                                            <Label required={!!itemErrors?.categoryId}>{t('home_screen.inspirations.category_title') + '*'}</Label>
                                            <SelectInput
                                                optionsList={categoriesParent.filter(x => x.languageId === lang.id).map(x => ({ label: x.name, value: x.masterRecordId }))}
                                                disabled={isDetails}
                                                placeholder={t('home_screen.inspirations.category_title')}
                                                required={true}
                                                name={`${keyName}.categoryId`}
                                                control={control}
                                                showRequired={!!itemErrors?.categoryId}
                                                onCategorieChange={setCategoriesEqual}
                                            />
                                            <InputError error={itemErrors?.categoryId} />
                                        </FormItem>
                                    </Col>

                                    <Col xl lg={12} sm={12} xs={12}>
                                        <FormItem>
                                            <Label required={!!itemErrors?.subCategoryId}>{t('home_screen.inspirations.sub_category_title')}</Label>
                                            <SelectInput
                                                optionsList={subCategoriesParent.filter(x => x.languageId === lang.id).map(x => ({ label: x.name, value: x.masterRecordId }))}
                                                disabled={isDetails}
                                                placeholder={t('home_screen.inspirations.sub_category_title')}
                                                name={`${keyName}.subCategoryId`}
                                                control={control}
                                                showRequired={!!itemErrors?.subCategoryId}
                                                onCategorieChange={setSubCategoriesEqual}
                                            />
                                            <InputError error={itemErrors?.subCategoryId} />
                                        </FormItem>

                                    </Col>

                                    <Col xl={2} lg={4} md={6} sm={8} xs={12}>
                                        <FormItem>
                                            <Label>{t('home_screen.inspirations.position')}</Label>
                                            <Input
                                                type="number"
                                                register={register}
                                                name={`${keyName}.position`}
                                                disabled={isDetails}
                                                maxLength={5}
                                                step={1}
                                                min={0}
                                                showRequired={!!itemErrors?.position}
                                                className={styles.inputAlignRight}
                                                onChange={onValueChangePosition}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>}
                                {isDetails && <Row className={styles.space_between}>
                                    <div className={styles.pointer}>
                                        <div>
                                            <MdOutlineArrowBackIosNew
                                                className={styles.backIcon}
                                                onClick={() => navigateTo()}
                                            />
                                        </div>
                                        <span onClick={() => navigateTo()}>{t('shared_translations.common.go_back')}</span>
                                    </div>
                                    {isDetailsHasClientNoEdit && <Favorites
                                        className={styles.favoritesContainer}
                                        mode={addInspirationFavorite}
                                        onClick={addInspirationFavorite ? () => onAddFavorite() : () => showRemoveFavoriteDialog(getValues(`list.${0}`).comments?.length ?? 0)}
                                    />}
                                </Row>}
                                {selectedLanguageIndex === index && <ImageGrid
                                    fullWidth={!addInspirationFavorite || !isDetails}
                                    isFavorite={!addInspirationFavorite}
                                    renderEditableImage={(canDraw) => (
                                        <EditableImage
                                            url={selectedImage?.url ?? ''}
                                            points={selectedImage?.points ?? []}
                                            disabled={!(!isDetails || (isDetails && selectedImage?.userId === selectedClient?.id && !showEditLayout))}
                                            hideAddPointsIcon={isDetails}
                                            renderPointTooltip={(pointData) => pointData.name
                                                ? <InspirationPointTooltipContent
                                                    title={pointData.name}
                                                    subtitle={pointData.description}
                                                    price={pointData.price}
                                                />
                                                : null}
                                            selectedPointKey={selectedImagePoint?.point?.key ?? null}
                                            selectedPointIsNew={selectedImagePoint?.isNew}
                                            editMode={pointsEditMode}
                                            onAddPoint={(pointData) => onAddImagePoint(pointData, lang.id, selectedImage?.key ?? '')}
                                            onNewPointClick={() => onNewImagePointClick(lang.id, selectedImage?.key ?? '')}
                                            onNewNoteClick={onNewImageNoteClick}
                                            onPointClick={(pointData) => onImagePointClick(pointData, lang.id, selectedImage?.key ?? '')}
                                            onCancelEdition={onCancelImagePoint}
                                            onDelete={() => onDeleteAttachment(lang.id, selectedImage?.key ?? '', selectedImage?.id)}
                                            allowDrawing={isDetailsClientIDAndFavorite && canDraw}
                                            drawingMode={canvasDrawingMode}
                                            onChangeDrawingMode={setImageDrawingMode}
                                            drawingData={currentDrawingData}
                                            onSaveDrawing={data => onSaveImageDrawing(lang.id, selectedImage?.key ?? '', data)}
                                            onEditInspiration={() => goToEdit()}
                                            editInspirationMode={type === Types.details && showEditLayout}
                                            onChangeSearchImage={selectedSearchImage}
                                            onSearchImageMode={data => {
                                                onSearchImage(data);
                                                setSearchImageActive(selectedImage);
                                            }}
                                            onCancelSearchImageMode={onCancelSearchImage}
                                            onCompletedSearchImage={async (data, imageWidth, imageHeight) => {
                                                await onCompletedSearchImage(data, imageWidth, imageHeight);
                                            }}
                                            pinterestPin={selectedImage?.pinterestPinId}
                                            inspirationImageUser={selectedImage?.userId}
                                            searchImageShow={isDetailsClientID}
                                        />
                                    )}
                                    showRequireEditableImage={submitNoImages && languageFiles.length === 0}
                                    attachments={attachments.find(x => x.languageId === lang.id)?.files ?? []}
                                    renderSmallImage={att => (
                                        <ImageInput
                                            previewUrl={att.url}
                                            onDelete={() => onDeleteAttachment(lang.id, att.key, att.id)}
                                            onClickOverlay={() => selectImage(lang.id, att.key)}
                                            readOnly={!(!isDetails || (isDetails && att.userId === selectedClient?.id && !showEditLayout))}
                                            roundDisplay={false}
                                            rectangularShape={true}
                                            forceAspectRatio={true}
                                            fill={false}
                                            selected={att.selected}
                                        />
                                    )}
                                    renderUploaderImage={() => (
                                        <UploaderIcon
                                            onChooseFile={(file, url) => onChooseAttachment(file, url, lang.id)}
                                            circularCrop={false}
                                            rectangularShape={true}
                                        />
                                    )}
                                    isDetails={!(!isDetails || (!addInspirationFavorite && selectedClient && isDetails && !showEditLayout))}
                                    mobileOnChangeImage={(att) => selectImage(lang.id, att.key)}
                                    mobileCanSwipe={!selectedImagePoint && canvasDrawingMode === 'none' && !selectedSearchImage}
                                />}

                                {!searchImageActive && <div className={`${styles.titleMarginTop} ${styles.title_favorite_grid}`} style={{ gridTemplateColumns: titleFavoritePosition(attachments[0]?.files?.length ?? null) }}>
                                    <div className={styles.inputContainer}>
                                        <EditableTextInput
                                            type={InputType.text}
                                            required={true}
                                            control={control}
                                            showRequired={!!itemErrors?.title}
                                            name={`${keyName}.title`}
                                            maxLength={250}
                                            placeholder={t('home_screen.inspirations.add_title')}
                                            disabled={isDetails}
                                        />
                                        <div style={{ paddingLeft: '10px' }}>
                                            <InputError error={itemErrors?.title} maxLength={250} />
                                        </div>

                                    </div>
                                </div> }
                                {!searchImageActive && <Row className={`${!isMobile ? styles.grid : ''}`}>
                                    <EditableTextInput
                                        disabled={isDetails}
                                        type={InputType.textArea}
                                        control={control}
                                        name={`${keyName}.description`}
                                        maxLength={999}
                                        placeholder={!isDetails ? t('home_screen.inspirations.add_description') : ''}
                                        disabledAsText
                                    />
                                    <InputError error={itemErrors?.description} maxLength={999} />
                                </Row> }

                            </div>;
                        })}
                    </LanguagesTab>

                </form>

                {!searchImageActive && showFavoriteAndComment &&
                    <FloatingComment
                        item={{ ...getValues(`list.${0}`), mediaUrl: attachments.find(x => x.languageId === getValues(`list.${0}`).languageId)?.files[0].url }}
                        onClose={() => setShowFavoriteAndComment(false)}
                        onSaveComment={(comment) => onSaveCommentFavorite(comment)} />}

                {!searchImageActive && isDetailsHasClientNoEdit && !addInspirationFavorite && <div className={isMobile ? undefined : styles.grid}>
                    <CommentFavorites
                        onDelete={(c) => onDeleteComment(c)}
                        onSaveComment={(c) => onSaveComment(c)}
                        inspiration={type !== Types.create
                            ? { ...getValues(`list.${0}`), mediaUrl: attachments.find(x => x.languageId === getValues(`list.${0}`).languageId)?.files[0].url }
                            : null}
                        selectedClient={selectedClient}
                    />
                </div>}

                {imagesForPin && imagesForPin.length > 0 &&
                    <div className={isMobile ? styles.imagesForPinAreaMobile : styles.imagesForPinArea} style={{ gridTemplateColumns: titleFavoritePosition(attachments[0]?.files?.length ?? null) }}>
                        <div className={styles.imagesForPinContainer}>
                            <label className={styles.textImagesForPin}>{t('home_screen.inspirations.more_in_pinterest')}</label>
                            <Layout items={imagesForPin} onClickImage={onNewImagePinterestNoteClick} onPageChange={() => onChangePinterestPagePins()} hasMore={true} />
                        </div>
                    </div>
                }

            </ContentLayout>

            <QuestionYesNo onNo={onCancelRemove} onYes={removeItem} isVisible={showRemoveModal} message={t('shared_translations.messages.remove_record_with_ident', { name: '' })} />

            <QuestionYesNo onNo={onCancelRemoveFavorite} onYes={removeFavorite}
                isVisible={showRemoveFavoriteModal}
                message={t('home_screen.inspirations.remove_from_favorites')}
                messageLine2={removeMessage}
            />

            <QuestionYesNo onNo={onCancelSearchImageModal} onYes={() => null}
                isVisible={showSearchImageModal}
                message={t('shared_translations.messages.image_not_load_pinterest')}
                isInformation={true}
            />

            {selectedImagePoint && (
                <ImagePointForm
                    onCancel={onCancelEditImagePoint}
                    onSave={onSaveImagePoint}
                    onDelete={onDeleteImagePoint}
                    model={selectedImagePoint.point}
                />
            )}

            {showCanvasPinterestImageModal && pinterestBase64Image && <CanvasModal
                url={pinterestBase64Image}
                drawingMode={canvasDrawingModePinterest}
                onChangeDrawingMode={setImageDrawingModePinterest}
                onCancelEdition={onCancelImagePinterestNoteClick}
                onSaveDrawing={onSaveImagePinterestDrawing}
                mode={CanvasModalMode.FROM_PINTEREST}
                canImport={isDetailsClientIDAndFavorite}
            />}

        </div>
    );
}

export default InspirationScreen;
