import { ClientDto } from 'api/clients/models/ClientDto';
import Storage from '../../common/services/Storage';
import { STORAGE } from '../../Config';

export const setChosenUser = (user: ClientDto | null) => {
    Storage.setObject(STORAGE.CHOSEN_USER, user);
    return {
        type: 'changeUser',
        user
    };
};

export const initChosenUser = () => {
    const user = Storage.getObject(STORAGE.CHOSEN_USER) ?? null;
    return {
        type: 'changeUser',
        user
    };
};
