import { PagedSearchCriteria } from 'api/common/types/PagedSearchCriteria';
import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { RoleDto } from './models/RoleDto';

class RolesService {
    public getList (criteria: PagedSearchCriteria) {
        return Http.get<Paged<RoleDto>>('roles', criteria);
    }

    public getById (id: string) {
        return Http.get<RoleDto>('roles/' + id);
    }

    public getAll () {
        return Http.get<RoleDto[]>('roles/all');
    }

    public create (model: RoleDto): Promise<string> {
        return Http.post('roles', model);
    }

    public update (model: RoleDto) {
        return Http.put('roles', model);
    }

    public remove (model: RoleDto) {
        return Http.put('roles/deactivate', model);
    }
}

export default new RolesService();
