import styles from './Card.module.scss';
import { ReactComponent as PinterestIcon } from 'assets/svg/pinterest-logo.svg';
import { ReactComponent as SearchIcon } from 'assets/svg/arrow.svg';
import useIsMobile from 'common/hooks/useIsMobile';
import { PinterestPagePictureDto } from 'api/pinterest/models/PinterestDto';

export interface Props {
    item: PinterestPagePictureDto;
    onClick: () => void;
}

const Card = ({ item, onClick }: Props) => {
    const isMobile = useIsMobile();

    return (
        <div className={styles.card}>
            <img
                src={item.thumbnail}
                style={{ width: '100%', display: 'block', borderRadius: '16px' }}
            />
            <PinterestIcon width={20} height={20} className={styles.iconPinterest} />
            {!isMobile && <div className={styles.previewOverlay}>
                <div className={styles.linkButton} onClick={onClick}>
                    <SearchIcon width={20} height={20} />
                </div>
            </div> }
        </div>
    );
};

export default Card;
