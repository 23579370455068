import Http from '../../common/services/Http';
import ImageNoteDto from './models/ImageNoteDto';

class ImageNoteService {
    public save (model: ImageNoteDto) {
        return Http.put<string>('imagenotes', model);
    }

    public remove (model: ImageNoteDto) {
        return Http.put('imagenotes/deactivate', model);
    }
}

export default new ImageNoteService();
