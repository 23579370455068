import { useState } from 'react';
import TabsHeader from './tabsHeader/TabsHeader';
import TabHeader from './tabHeader/TabHeader';
import { useTranslation } from 'react-i18next';
import Navbar from 'common/layouts/navBar/Navbar';
import { BreadCrumb } from 'common/types/BreadCrumb';
import ContentLayout from 'common/layouts/contentLayout/ContentLayout';
import UserTab from './usersTab/UserTab';
import RolesTab from './rolesTab/RolesTab';
import RulesTab from './rulesTab/RulesTab';
import DefinitionsTab from './definitionsTab/DefinitionsTab';
import LanguageTab from './languageTab/LanguageTab';
import { useLocation, useParams } from 'react-router-dom';
import { UserProfile } from 'api/account/models/UserProfile';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import styles from './BackofficeScreen.module.scss';
import IfPolicy from 'common/components/policy/IfPolicy';
import ApisTab from './apiTab/ApisTab';

export enum Tab {
    USERS,
    ROLES,
    RULES,
    DEFINITIONS,
    LANGUAGE,
    API,
}

function BackofficeScreen (): JSX.Element {
    const { t } = useTranslation();
    const location = useLocation();
    const params = useParams();
    const paramsTab = params.tab ? Tab[params.tab.toUpperCase() as any] : null;
    const tab = location?.state?.tab ?? paramsTab;
    const [selectedTab, setSelectedbTab] = useState<Tab>(tab || Tab.USERS);
    const user = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);

    const breadCrumbs: BreadCrumb[] = [
        {
            text: t('menu.backoffice'),
        },
    ];

    const changeTab = async (newtab: Tab) => {
        setSelectedbTab(newtab);
    };

    const [searchString, setSearchString] = useState('');
    const [search, setSearch] = useState(false);

    const handleChange = (event: any) => {
        setSearchString(event.target.value);
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            setSearch(!search);
        }
    };

    return (
        <div onKeyDown={handleKeyDown}>
            <Navbar
                searchText={searchString}
                onChange={handleChange}
                onSearch={() => setSearch(!search)}
                breadCrumbs={breadCrumbs}
                isBackoffice={true}
                inputPlaceHolder={t('shared_translations.messages.search_bar_placeholder')}
                hideSearch={selectedTab !== Tab.USERS && selectedTab !== Tab.ROLES}
            />
            <ContentLayout>
                <TabsHeader className={styles.tabsHeader}>
                    <IfPolicy user={user} policies={['SETTINGUP_USERS_WRITE', 'SETTINGUP_USERS_READ']}>
                        <TabHeader
                            name={t('backoffice.users.title')}
                            id={Tab.USERS}
                            isSelected={selectedTab === Tab.USERS}
                            changeTab={changeTab}
                        />
                    </IfPolicy>
                    <IfPolicy user={user} policies={['SETTINGUP_ROLES_WRITE', 'SETTINGUP_ROLES_READ']}>
                        <TabHeader
                            name={t('backoffice.roles.title')}
                            id={Tab.ROLES}
                            isSelected={selectedTab === Tab.ROLES}
                            changeTab={changeTab}
                        />
                    </IfPolicy>
                    <IfPolicy user={user} policies={['SETTINGUP_RULES_WRITE', 'SETTINGUP_RULES_READ']}>
                        <TabHeader
                            name={t('backoffice.rules.title')}
                            id={Tab.RULES}
                            isSelected={selectedTab === Tab.RULES}
                            changeTab={changeTab}
                        />
                    </IfPolicy>
                    <IfPolicy user={user} policies={['SETTINGUP_DEFINITIONS_WRITE', 'SETTINGUP_DEFINITIONS_READ']}>
                        <TabHeader
                            name={t('backoffice.definitions.title')}
                            id={Tab.DEFINITIONS}
                            isSelected={selectedTab === Tab.DEFINITIONS}
                            changeTab={changeTab}
                        />
                    </IfPolicy>
                    <IfPolicy user={user} policies={['SETTINGUP_LANGUAGES_WRITE', 'SETTINGUP_LANGUAGES_READ']}>
                        <TabHeader
                            name={t('backoffice.languages.title')}
                            id={Tab.LANGUAGE}
                            isSelected={selectedTab === Tab.LANGUAGE}
                            changeTab={changeTab}
                        />
                    </IfPolicy>
                    <IfPolicy user={user} policies={['SETTINGUP_API_WRITE', 'SETTINGUP_API_READ']}>
                        <TabHeader
                            name={t('backoffice.api.title')}
                            id={Tab.API}
                            isSelected={selectedTab === Tab.API}
                            changeTab={changeTab}
                        />
                    </IfPolicy>
                </TabsHeader>
                <div>
                    {selectedTab === Tab.USERS && (
                        <UserTab searchString={searchString} searchOrder={search} />
                    )}
                    {selectedTab === Tab.ROLES && (
                        <RolesTab searchString={searchString} searchOrder={search} />
                    )}
                    {selectedTab === Tab.RULES && <RulesTab />}
                    {selectedTab === Tab.DEFINITIONS && <DefinitionsTab />}
                    {selectedTab === Tab.LANGUAGE && (
                        <LanguageTab searchString={searchString} searchOrder={search} />
                    )}
                    {selectedTab === Tab.API && <ApisTab />}
                </div>
            </ContentLayout>
        </div>
    );
}

export default BackofficeScreen;
