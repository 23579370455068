import React from 'react';
import styles from './TabsHeader.module.scss';

type Props = {
    children: React.ReactElement[];
    className?: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const TabsHeader = ({ children, className, ...props }: Props) => (
    <div {...props} className={`${styles.tabsHeader} ${className ?? ''}`}>
        {children}
    </div>
);

export default TabsHeader;
