import { Col, Row } from 'react-flexbox-grid';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE, ApplicationPaths } from 'Config';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import Button, { Color } from 'common/components/buttons/Button';
import FormItem from 'common/components/formItem/FormItem';
import Input from 'common/components/inputs/input/Input';
import Label from 'common/components/label/Label';
import styles from './LanguageScreen.module.scss';
import Navbar from 'common/layouts/navBar/Navbar';
import { BreadCrumb } from 'common/types/BreadCrumb';
import ContentLayout from 'common/layouts/contentLayout/ContentLayout';
import LanguagesService from 'api/languages/LanguagesService';
import LanguageDto from 'api/languages/models/LanguageDto';
import WarningToast from 'common/components/warningToast/WarningToast';
import ImageInput from 'common/components/inputs/imageInput/ImageInput';
import CheckBox from 'common/components/checkBox/CheckBox';
import TranslationsTree, { translationKeyPrefix, Translations, translationsHasErrors } from './translationsTree/TranslationsTree';
import { Tab } from 'screens/backoffice/BackofficeScreen';
import { deepCleanStrings, deepFilter, mergeDeep } from 'common/utils/deepen';
import { getDefaultLanguageData } from 'common/services/I18n';
import CopyContent from 'common/components/copyContent/CopyContent';
import TabsHeader from 'screens/backoffice/tabsHeader/TabsHeader';
import TabHeader from 'screens/backoffice/tabHeader/TabHeader';
import InputError from 'common/components/inputs/inputError/InputError';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import hasPolicies from 'common/utils/hasPolicies';

const Types = {
    create: 'create',
    edit: 'edit',
    details: 'details',
};

enum InsideTab {
    PROPERTIES,
    TRANSLATION,
}

function LanguageScreen (): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id, type } = useParams<{ id: string; type: string }>();

    const [selectedTab, setSelectedTab] = useState<InsideTab>(InsideTab.PROPERTIES);
    const { register, handleSubmit, setValue, formState } = useForm<LanguageDto>({ reValidateMode: 'onChange' });
    const errors = formState.errors;
    const [languageToRemove, setLanguageToRemove] = useState<LanguageDto>({
        id: '',
        name: '',
        iso: '',
        mediaUrl: null,
        translationsJson: '',
        isDefault: false,
    });
    const user = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = hasPolicies(user, ['SETTINGUP_LANGUAGES_WRITE']);

    const [logoFile, setLogoFile] = useState<File | null>(null);
    const [logoUrl, setLogoUrl] = useState<string | null>(null);
    const [translations, setTranslations] = useState<Translations>({});
    const [canRemoveIsDefault, setCanRemoveIsDefault] = useState(false);
    const [languages, setLanguages] = useState<LanguageDto[]>([]);
    const [defaultLanguage, setDefaultLanguage] = useState<LanguageDto | null>(null);

    const breadCrumbs: BreadCrumb[] = [
        {
            text: t('menu.backoffice'),
            url: ApplicationPaths.Backoffice,
        },
        {
            text: t('backoffice.languages.language_profile'),
        },
    ];

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [isDetails, setIsDetails] = useState<boolean>(
        Boolean(type && type === Types.details)
    );

    useEffect(() => {
        setIsDetails(!!(type && type === Types.details));
        void getData();
    }, [id, type]);

    const getData = async () => {
        try {
            Loading.show();

            const [langs, defaultLang] = await Promise.all([
                LanguagesService.getAll(),
                LanguagesService.getDefault(),
            ]);
            setLanguages(langs);
            setDefaultLanguage(defaultLang);

            if (id) {
                const language = await LanguagesService.get(id);
                setValues(language);
            } else {
                if (defaultLang) {
                    setTranslations(parseTranslations(defaultLang, true));
                }
                setCanRemoveIsDefault(true);
            }

            Loading.hide();
        } catch (error) {
            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                "Couldn't get language",
                error
            );
            toast.error(`${t('shared_translations.messages.error_load_info')}`);
            Loading.hide();
        }
    };

    const setValues = (language: LanguageDto) => {
        setLanguageToRemove(language);
        setValue('id', language.id);
        setValue('name', language.name);
        setValue('iso', language.iso);
        setValue('isDefault', language.isDefault);
        setTranslations(parseTranslations(language));
        setCanRemoveIsDefault(!language.isDefault);
        setLogoUrl(language.mediaUrl);
    };

    const parseTranslations = (language: LanguageDto, clearValues = false): Translations => {
        const defaultData = getDefaultLanguageData(language.iso, null);
        const data = language.translationsJson && language.translationsJson.length > 0 ? JSON.parse(language.translationsJson) : {};
        const filteredData = deepFilter(data, (k, c) => {
            if (!c[translationKeyPrefix + k]) {
                return false;
            }
            return true;
        });

        const mergedData = mergeDeep(defaultData, filteredData);

        if (clearValues) {
            return deepCleanStrings(mergedData, translationKeyPrefix);
        }

        return mergedData;
    };

    const onChangeTranslations = (trans: Translations) => {
        setTranslations(trans);
    };

    const onSubmit = async (language: LanguageDto) => {
        if (!logoUrl || translationsHasErrors(translations)) {
            void onError();
            return;
        }

        try {
            Loading.show();

            const translationsJson = JSON.stringify(translations);

            if (id && id != null) {
                await LanguagesService.update({ ...language, translationsJson }, logoFile);
                toast.success(`${t('shared_translations.messages.record_save_success')}`);
                navigateTo();
            } else {
                await LanguagesService.create({ ...language, translationsJson }, logoFile);
                toast.success(`${t('shared_translations.messages.record_save_success')}`);
                navigateTo();
            }

            Loading.hide();
        } catch (error) {
            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                "Couldn't save language",
                error
            );
            toast.error(`${t('shared_translations.messages.record_save_error')}`);
            Loading.hide();
        }
    };

    const onError = async () => {
        toast(`${t('shared_translations.messages.required_fields_empty')}`, {
            icon: <WarningToast />,
        });
    };

    const showRemoveItemDialog = async () => {
        setShowRemoveModal(true);
    };

    const onCancelRemove = () => {
        setShowRemoveModal(false);
    };

    const removeItem = async () => {
        if (id && id != null) {
            try {
                Loading.show();
                await LanguagesService.deactivate(languageToRemove.id);
                toast.success(`${t('shared_translations.messages.record_delete_success')}`);
                Loading.hide();
                navigateTo();
            } catch (error) {
                setShowRemoveModal(false);
                toast.error(`${t('shared_translations.messages.record_delete_error')}`);
                Loading.hide();
            }
        }
    };

    const navigateTo = (typeUrl?: string, idToGo?: string) => {
        if (typeUrl) {
            navigate(`${ApplicationPaths.Backoffice}/languages/${typeUrl}/${idToGo}`);
            setIsDetails(typeUrl === Types.details);
        } else {
            navigate(ApplicationPaths.Backoffice, { state: { tab: Tab.LANGUAGE } });
        }
    };

    const onChooseAvatar = (file: File, dataUrl: string) => {
        setLogoFile(file);
        setLogoUrl(dataUrl);
    };

    const onDeleteAvatar = () => {
        setLogoFile(null);
        setLogoUrl(null);
    };

    const onCopyTranslations = (lang: LanguageDto) => {
        setTranslations(parseTranslations(lang));
    };

    const onClearTranslations = () => {
        if (!defaultLanguage) {
            return;
        }

        setTranslations(parseTranslations(defaultLanguage, true));
    };

    const hasMainFormErrors = formState.isSubmitted && (Object.keys(errors).map(k => (errors as any)[k]).filter(v => Boolean(v)).length > 0 || !logoUrl);
    const hasTranslationErrors = formState.isSubmitted && translationsHasErrors(translations);

    if (!canWrite && type !== Types.details) {
        return <Navigate replace={true} to={ApplicationPaths.Backoffice} state={{ tab: Tab.LANGUAGE }} />;
    }

    return (
        <div>
            <Navbar breadCrumbs={breadCrumbs} isBackoffice={true}/>
            <ContentLayout>
                <form
                    className={styles.form}
                    onSubmit={handleSubmit(onSubmit, onError)}
                >
                    <div className={styles.buttonsDiv}>
                        <div className={styles.tabButtons}>
                            <TabsHeader>
                                <TabHeader
                                    name={t('backoffice.languages.properties')}
                                    id={InsideTab.PROPERTIES}
                                    isSelected={selectedTab === InsideTab.PROPERTIES}
                                    changeTab={() => setSelectedTab(InsideTab.PROPERTIES)}
                                    hasError={hasMainFormErrors}
                                />
                                <TabHeader
                                    name={t('backoffice.languages.translation')}
                                    id={InsideTab.TRANSLATION}
                                    isSelected={selectedTab === InsideTab.TRANSLATION}
                                    changeTab={() => setSelectedTab(InsideTab.TRANSLATION)}
                                    hasError={hasTranslationErrors}
                                />
                            </TabsHeader>
                        </div>
                        <div className={styles.saveButtons}>
                            <Button
                                text={t('shared_translations.common.go_back')}
                                onClick={() => navigateTo()}
                                color={Color.white}
                            />
                            {type !== Types.create && canWrite && (
                                <Button
                                    text={t('shared_translations.common.delete')}
                                    onClick={() => showRemoveItemDialog()}
                                    color={Color.red}
                                />
                            )}
                            {type === Types.details && canWrite && (
                                <Button
                                    text={t('shared_translations.common.edit')}
                                    onClick={() => {
                                        navigateTo('edit', languageToRemove.id);
                                    }}
                                    color={Color.black}
                                />
                            )}

                            {type !== Types.details && (
                                <Button
                                    text={
                                        type === Types.create
                                            ? t('shared_translations.common.register')
                                            : t('shared_translations.common.save')
                                    }
                                    type="submit"
                                    color={Color.black}
                                />
                            )}
                        </div>
                    </div>

                    {selectedTab === InsideTab.PROPERTIES && (
                        <Row>
                            <Col xl={9} lg={8} md={8} sm={12} xs={12}>
                                <FormItem>
                                    <Label required={Boolean(errors.name)}>
                                        {t('backoffice.languages.form.name') + '*'}
                                    </Label>
                                    <Input
                                        type="text"
                                        register={register}
                                        name="name"
                                        disabled={isDetails}
                                        required={true}
                                        maxLength={200}
                                        showRequired={Boolean(errors.name)}
                                    />
                                    <InputError error={errors.name} maxLength={200} />
                                </FormItem>
                                <FormItem>
                                    <Label required={Boolean(errors.iso)}>
                                        {t('backoffice.languages.form.iso') + '*'}
                                    </Label>
                                    <Input
                                        type="text"
                                        register={register}
                                        name="iso"
                                        disabled={isDetails}
                                        required={true}
                                        showRequired={Boolean(errors.iso)}
                                        maxLength={2}
                                    />
                                    <InputError error={errors.iso} maxLength={2} />
                                </FormItem>
                                <FormItem>
                                    <div className={styles.center}>
                                        <Label>{t('backoffice.languages.form.default')}</Label>
                                        <CheckBox
                                            register={register}
                                            name="isDefault"
                                            disabled={!canRemoveIsDefault}
                                        />
                                    </div>
                                </FormItem>
                            </Col>
                            <Col xl={3} lg={4} md={4} sm={12} xs={12}>
                                <div className={styles.photoDiv}>
                                    <ImageInput
                                        onChooseFile={onChooseAvatar}
                                        previewUrl={logoUrl}
                                        onDelete={onDeleteAvatar}
                                        disabled={isDetails}
                                        cropImage={true}
                                        forceAspectRatio={true}
                                    />
                                    {formState.isSubmitted && !logoUrl && <InputError error={{ type: 'required' }} />}
                                </div>
                            </Col>
                        </Row>
                    )}

                    {selectedTab === InsideTab.TRANSLATION && (
                        <div>
                            {!isDetails && ((type === Types.create && languages.length > 0) || (type === Types.edit && languages.length > 1)) && <CopyContent languages={languages} onCopy={onCopyTranslations} onClear={onClearTranslations} />}
                            <TranslationsTree
                                translations={translations}
                                onChange={onChangeTranslations}
                                isRoot={true}
                                disabled={isDetails}
                                className={styles.translationsTree}
                                formSubmitted={formState.isSubmitted}
                            />
                        </div>
                    )}
                </form>
                <QuestionYesNo
                    onNo={onCancelRemove}
                    onYes={removeItem}
                    isVisible={showRemoveModal}
                    message={t('shared_translations.messages.remove_record_with_ident', {
                        name: languageToRemove?.name ?? '',
                    })}
                />
            </ContentLayout>
        </div>
    );
};

export default LanguageScreen;
