import { ReactElement, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReactComponent as AddCircleIcon } from 'assets/svg/addcircle.svg';
import styles from './BackofficeTable.module.scss';
import { ReactComponent as GreyArrows } from 'assets/svg/arrangeverticalcinza.svg';
import { ReactComponent as DownArrow } from 'assets/svg/arrangeverticaldown.svg';
import { ReactComponent as UpArrow } from 'assets/svg/arrangeverticalup.svg';
import { useTranslation } from 'react-i18next';

interface TableRow {
    [field: string]: any
}

export interface FilterItem {
    columnName: string
    isOrderAsc: boolean
}

export interface BackofficeTableColumn<TRow> {
    name?: string
    renderCell?: (row: TRow) => ReactElement | any
    width?: string | number
    hideOn?: string[]
    columnName: string;
    preventClick?: boolean;
}
export interface BackofficeTableRow {
    [field: string]: any;
}

interface Props<TRow> {
    columns: Array<BackofficeTableColumn<TRow>>
    rows: TRow[]
    currentPage: number
    changePage: Function
    totalItems: number
    orderTable: Function
    navigateTo?: string
    initialOrder: FilterItem;
    showAddButton?: boolean;
    onRowClick?: (row: BackofficeTableRow) => void;
    onAddFiles?: any
}

const BackofficeTable = <TRow extends TableRow>(props: Props<TRow>) => {
    const {
        rows,
        columns,
        currentPage,
        changePage,
        totalItems,
        orderTable,
        navigateTo,
        initialOrder,
        onRowClick,
        showAddButton = true,
        onAddFiles
    } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [orderColumn, setOrderColumn] = useState<FilterItem | null>(
        initialOrder
    );

    const getColStyle = (col: BackofficeTableColumn<TRow>) => {
        if (!col.width) {
            return {};
        }

        return { width: col.width, cursor: 'pointer' };
    };

    const hideCol = (col: BackofficeTableColumn<TRow>) => {
        if (!col.hideOn) {
            return '';
        }

        let style = '';
        if (col.hideOn?.includes('xxl')) {
            style = styles.xxl;
        }
        if (col.hideOn?.includes('xl')) {
            style = styles.xl;
        }
        if (col.hideOn?.includes('lg')) {
            style = styles.lg;
        }
        if (col.hideOn?.includes('md')) {
            style = styles.md;
        }
        if (col.hideOn?.includes('sm')) {
            style = styles.sm;
        }

        return style;
    };

    const onPageChange = () => {
        if (rows.length === 0) {
            return;
        }
        const page = currentPage + 1;
        changePage(page);
    };

    const orderChange = (col: BackofficeTableColumn<TRow>) => {
        if (col.columnName === orderColumn?.columnName) {
            setOrderColumn({ ...orderColumn, isOrderAsc: !orderColumn.isOrderAsc });
        } else {
            setOrderColumn({ columnName: col.columnName, isOrderAsc: true });
        }
    };

    const renderArrow = (col: BackofficeTableColumn<TRow>) => {
        if (!col.columnName || col.columnName === '') {
            return '';
        }
        let arrowsIcon;
        if (col.columnName === orderColumn?.columnName && orderColumn.isOrderAsc) {
            arrowsIcon = <UpArrow />;
        } else if (col.columnName === orderColumn?.columnName) {
            arrowsIcon = <DownArrow />;
        } else {
            arrowsIcon = <GreyArrows />;
        }

        return arrowsIcon;
    };

    const onClick = (row: BackofficeTableRow, preventClick: boolean) => {
        if (preventClick) {
            return;
        }

        if (onRowClick) {
            onRowClick(row);
        }
    };

    const inputFile = useRef<HTMLInputElement>(null);

    return (
        <div>
            <div className={styles.tableDiv}>
                <InfiniteScroll
                    dataLength={rows.length}
                    loader={null}
                    hasMore={rows.length < totalItems}
                    next={onPageChange}
                    scrollableTarget={'tableScroll'}
                >
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                {columns.map((col, index) => (
                                    <th key={index} className={hideCol(col)}>
                                        <div style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                orderTable(col.columnName);
                                                orderChange(col);
                                            }}>
                                            <a>
                                                {col.name}
                                            </a>
                                            {renderArrow(col)}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>

                        <tbody>
                            {(Boolean(rows.length > 0) || (Boolean(rows.length <= 0))) && rows.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {columns.map((col, colIndex) => (
                                        <td
                                            onClick={() => onClick(row, (col?.preventClick ?? false))}
                                            key={colIndex}
                                            style={getColStyle(col)}
                                            className={hideCol(col)}
                                        >
                                            {col.renderCell ? col.renderCell(row) : ''}
                                        </td>
                                    ))}

                                </tr>
                            ))}
                        </tbody>

                        {Boolean(rows.length <= 0) && <tfoot>
                            <tr>
                                <td colSpan={columns.length} className={styles.noItemsText}>
                                    {<h3>{t('shared_translations.messages.no_info_to_show')}</h3>}
                                </td>
                            </tr>
                        </tfoot>}
                    </table>
                    {showAddButton && navigateTo && <div className={styles.iconDiv}>
                        <AddCircleIcon onClick={() => navigate(navigateTo)} />
                    </div>}
                    {showAddButton && onAddFiles && <div className={styles.iconDiv}>
                        <AddCircleIcon onClick={() => inputFile?.current?.click()}/>
                    </div>}
                    <input type='file' id='file' multiple ref={inputFile} style={{ display: 'none' }} onChange={(e) => { onAddFiles(e); }}/>
                </InfiniteScroll>
            </div>
        </div>
    );
};

export default BackofficeTable;
