import Http from 'common/services/Http';
import { ClientNoteDto } from './models/ClientNoteDto';
import { ClientNotesSearchCriteria } from './models/ClientNotesSearchCriteria';

class ClientNotesService {
    public getList (criteria: ClientNotesSearchCriteria) {
        return Http.get<ClientNoteDto[]>('clientnotes/list', criteria);
    }

    public getClienteNotesCounter (userId: string) {
        return Http.get<number>('clientnotes/count/' + userId);
    }

    public create (model: ClientNoteDto,): Promise<string> {
        return Http.post('clientnotes', model);
    }

    public update (model: ClientNoteDto) {
        return Http.put('clientnotes', model);
    }

    public remove (model: ClientNoteDto) {
        return Http.put('clientnotes/deactivate', model);
    }
}

export default new ClientNotesService();
