import Http from '../../common/services/Http';
import { InspirationFavoritesDto } from './models/InspirationFavoriteDto';

class InspirationFavoriteService {
    public create (model: InspirationFavoritesDto) {
        return Http.post<string>('inspirationFavorites', model);
    }

    public remove (model: InspirationFavoritesDto) {
        return Http.put('inspirationFavorites/deactivate', model);
    }
}

export default new InspirationFavoriteService();
