import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { useEffect, useRef, useState } from 'react';
import styles from './RulesTab.module.scss';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import UsersService from 'api/users/UsersService';
import RolesService from 'api/roles/RolesService';
import RulesService from 'api/rules/RulesService';
import { UsersSelectItemDto } from 'api/users/models/UsersSelectItemDto';
import { RuleConcatType, RuleDto } from 'api/rules/models/RuleDto';
import { RoleDto } from 'api/roles/models/RoleDto';
import Button, { Color } from 'common/components/buttons/Button';
import { UserProfile } from 'api/account/models/UserProfile';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { removeAccents } from 'common/utils/removeAccents';
import Column from './column/Column';
import hasPolicies from 'common/utils/hasPolicies';

const deepClone = (data: any) => JSON.parse(JSON.stringify(data));

function RulesTab (): JSX.Element {
    const { t } = useTranslation();
    const [usersList, setUsersList] = useState<UsersSelectItemDto[]>([]);
    const [rulesList, setRulesList] = useState<RuleDto[]>([]);
    const [rolesList, setRolesList] = useState<RoleDto[]>([]);

    const [finalRule, setFinalRule] = useState<RuleDto>();

    const [filterRule, setFilterRule] = useState<string>('');
    const [filterUser, setFilterUser] = useState<string>('');
    const [filterRole, setFilterRole] = useState<string>('');

    const [usersListFiltered, setUsersListFiltered] = useState<UsersSelectItemDto[]>([]);
    const [rulesListFiltered, setRulesListFiltered] = useState<RuleDto[]>([]);
    const [rolesListFiltered, setRolesListFiltered] = useState<RoleDto[]>([]);

    const originalRules = useRef<RuleDto[] | null>(null);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = hasPolicies(userProfile, ['SETTINGUP_RULES_WRITE']);
    const isDetails = !canWrite;

    const getData = async () => {
        try {
            Loading.show();

            const [users, roles, rules] = await Promise.all([
                UsersService.getAllForSelectItem(),
                RolesService.getAll(),
                RulesService.getAll(),
            ]);
            setUsersList(users);
            setRolesList(roles);
            setRulesList(rules);

            originalRules.current = deepClone(rules);

            if (rules.length >= 1) {
                void onRuleSelected(rules[0], users, roles);
                setFinalRule({ ...rules[0] });
            }
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get roles list', error);
            toast.error(`${t('shared_translations.messages.error_load_info')}`);
            Loading.hide();
        }
    };

    useEffect(() => {
        void getData();
    }, []);

    const roleName = (role: RoleDto): string => {
        if (role.system === true || role.readOnly === true) {
            return role.name;
        } else {
            return role.realName;
        }
    };

    const onRuleSelected = async (rule: RuleDto, users: UsersSelectItemDto[], roles: RoleDto[]) => {
        setFinalRule({ ...rule });
        void parseRuleFromExpression(rule, users, roles);
    };

    const parseRuleFromExpression = async (rule: RuleDto, users: UsersSelectItemDto[], roles: RoleDto[]) => {
        const andOrRegex = /\(([A-Z0-9_\-.:\\@ *]+)\)(AND|OR)\(([A-Z0-9_\-.:\\@ *]+)\)/gi;
        const andOrMatch = andOrRegex.exec(rule?.expression);
        if (andOrMatch) {
            // AND / OR
            const andOr = andOrMatch[2];
            if (andOr.toUpperCase() === RuleConcatType.AND) {
                rule.concatType = RuleConcatType.AND;
            } else if (andOr.toUpperCase() === RuleConcatType.OR) {
                rule.concatType = RuleConcatType.OR;
            } else {
                // pass
            }
        } else {
            rule.concatType = RuleConcatType.OR;
        }

        setFinalRule({ ...rule });

        // users
        parseRuleFromExpressionUsers(rule, users, andOrMatch);

        // Roles
        parseRuleFromExpressionRoles(rule, roles, andOrMatch);
    };

    const parseRuleFromExpressionUsers = (rule: RuleDto, users: UsersSelectItemDto[], andOrMatch: any) => {
        users.forEach(x => { x.checked = false; });

        const position = 1;
        const usersExpression = calculateExpression(rule, position, andOrMatch);
        const usersRegex = /I:([^\s]+)/gi;

        const usersMatchArray = [];
        let usersMatch = usersRegex.exec(usersExpression);
        while (usersMatch !== null) {
            usersMatchArray.push(usersMatch[1]);
            usersMatch = usersRegex.exec(usersExpression);
        }

        if (usersMatchArray && usersMatchArray.length > 0) {
            if (usersMatchArray.length === 1 && usersMatchArray[0] === '*') {
                if (filterUser === '') {
                    users.forEach((e: UsersSelectItemDto) => {
                        e.checked = true;
                    });
                } else {
                    users.filter(c => usersListFiltered?.find(e => e.id === c.id) !== null).forEach((e: UsersSelectItemDto) => {
                        e.checked = true;
                    });
                }
            } else {
                usersMatchArray.forEach(userName => {
                    const user = users.find(u => u.userName === userName);
                    if (user) {
                        user.checked = true;
                    }
                });
            }
        }
        setUsersList([...users]);
    };

    const calculateExpression = (rule: RuleDto, position: number, andOrMatch: any) => {
        return andOrMatch ? andOrMatch[position] : rule.expression?.replace(/\(/g, '').replace(/\)/g, '');
    };

    const parseRuleFromExpressionRoles = (rule: RuleDto, roles: RoleDto[], andOrMatch: any) => {
        roles.forEach(x => { x.checked = false; });

        const position = 3;
        const rolesExpression = calculateExpression(rule, position, andOrMatch);
        const rolesRegex = /R:([^\s]+)/gi;

        const rolesMatchArray = [];
        let rolesMatch = rolesRegex.exec(rolesExpression);
        while (rolesMatch !== null) {
            rolesMatchArray.push(rolesMatch[1]);
            rolesMatch = rolesRegex.exec(rolesExpression);
        }

        if (rolesMatchArray && rolesMatchArray.length > 0) {
            if (rolesMatchArray.length === 1 && rolesMatchArray[0] === '*') {
                if (filterRole === '') {
                    rolesList.forEach((r: RoleDto) => {
                        r.checked = true;
                    });
                } else {
                    rolesList.filter(c => rolesListFiltered?.find(e => e.id === c.id) !== null).forEach((r: RoleDto) => {
                        r.checked = true;
                    });
                }
            } else {
                rolesMatchArray.forEach(userRole => {
                    const role = roles.find(r => r.name === userRole);
                    if (role) {
                        role.checked = true;
                    }
                });
            }
        }

        setRolesList([...roles]);
    };

    const onSave = async () => {
        try {
            Loading.show();

            if (rulesList !== null) {
                originalRules.current = deepClone(rulesList);
                await RulesService.update(rulesList);
            }

            Loading.hide();
            toast.success(`${t('shared_translations.messages.record_save_success')}`);
        } catch (error) {
            toast.error(`${t('shared_translations.messages.record_save_error')}`);
            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                'Couldn\'t create or update rule',
                error
            );
            Loading.hide();
        }
    };

    const onCancel = () => {
        if (!originalRules.current) {
            return;
        }
        const rules = deepClone(originalRules.current);

        setRulesList(rules);

        if (rules.length >= 1) {
            void onRuleSelected(rules[0], usersList, rolesList);
            setFinalRule({ ...rules[0] });
        }
    };

    const createExpression = (rule: RuleDto) => {
        // Users
        const usersString = usersStringFunc();

        // Roles
        const rolesString = rolesStringFunc();

        const andOr = rule.concatType && rule.concatType === RuleConcatType.OR ? RuleConcatType.OR : RuleConcatType.AND;

        // Result
        if (usersString && rolesString) {
            rule.expression = usersString + andOr + rolesString;
        } else if (usersString && !rolesString) {
            rule.expression = usersString;
        } else if (!usersString && rolesString) {
            rule.expression = rolesString;
        } else {
            rule.expression = '';
        }

        setRulesList([
            ...rulesList.map(r => {
                if (r.id === rule.id) {
                    r.expression = rule.expression;
                }
                return r;
            }),
        ]);
    };

    const usersStringFunc = () => {
        const users = usersList.filter((e: UsersSelectItemDto) => {
            return e.checked;
        });
        const usersExpression = users.map((u: UsersSelectItemDto) => {
            return 'I:' + u.userName;
        });

        let usersString = '';
        if (isAllUsersChecked(false)) {
            usersString = '(I:*)';
        } else if (usersExpression && usersExpression.length > 0) {
            usersString = `( ${usersExpression.join(' OR ')} )`;
        } else {
            // pass
        }

        return usersString;
    };

    const rolesStringFunc = () => {
        const roles = rolesList.filter((r: RoleDto) => {
            return r.checked;
        });
        const rolesExpression = roles
            ? roles.map((r: RoleDto) => {
                return 'R:' + r.name;
            })
            : [];

        let rolesString = '';
        if (isAllRolesChecked(false)) {
            rolesString = '(R:*)';
        } else if (rolesExpression && rolesExpression.length > 0) {
            rolesString = `( ${rolesExpression.join(' OR ')} )`;
        } else {
            // pass
        }

        return rolesString;
    };

    const setAnd = () => {
        if (!finalRule || isDetails) {
            return;
        }

        finalRule.concatType = RuleConcatType.AND;
        createExpression(finalRule);
        setFinalRule({ ...finalRule });
    };

    const setOr = () => {
        if (!finalRule || isDetails) {
            return;
        }

        finalRule.concatType = RuleConcatType.OR;
        createExpression(finalRule);
        setFinalRule({ ...finalRule });
    };

    const isAllUsersChecked = (checkFiltered: boolean) => {
        if (checkFiltered) {
            return filterUser !== '' && usersListFiltered.length > 0 && usersListFiltered?.every(_ => _.checked);
        }
        return usersList?.length > 0 && usersList?.every(_ => _.checked);
    };

    const onAllUsersChecked = (checked: boolean) => {
        void selectOrUnSelectUsers(checked, filterUser !== '');
        if (finalRule) {
            createExpression(finalRule);
        }
    };

    const selectOrUnSelectUsers = async (checked: boolean, filter: boolean) => {
        if (!filter) {
            usersList.forEach((e: UsersSelectItemDto) => {
                e.checked = checked;
            });
        } else {
            usersList.filter(c => usersListFiltered?.find(e => e.id === c.id) !== null).forEach((e: UsersSelectItemDto) => {
                e.checked = checked;
            });
        }
        setUsersList([...usersList]);
    };

    const isAllRolesChecked = (checkFiltered: boolean) => {
        if (checkFiltered) {
            return filterRole !== '' && rolesListFiltered.length > 0 && rolesListFiltered?.every(_ => _.checked);
        }
        return rolesList?.length > 0 && rolesList?.every(_ => _.checked);
    };

    const onAllRolesChecked = (checked: boolean) => {
        selectOrUnSelectRoles(checked, filterRole !== '');
        if (finalRule) {
            createExpression(finalRule);
        }
    };

    const selectOrUnSelectRoles = (checked: boolean, filter: boolean) => {
        if (!filter) {
            rolesList.forEach((r: RoleDto) => {
                r.checked = checked;
            });
        } else {
            rolesList.filter(c => rolesListFiltered?.find(e => e.id === c.id) !== null).forEach((r: RoleDto) => {
                r.checked = checked;
            });
        }
        setRolesList([...rolesList]);
    };

    const onSearchRule = (value: string) => {
        setFilterRule(value);
        const rules = rulesList.filter(item => {
            return removeAccents(t(('backoffice.rules.policies.' + item.name) as any)).toLowerCase().includes(removeAccents(value).toLowerCase());
        });
        setRulesListFiltered(rules);
    };

    const onSearchUser = (value: string) => {
        setFilterUser(value);
        const users = usersList.filter(item => {
            return removeAccents(item.realName).toLowerCase().includes(removeAccents(value).toLowerCase()) ||
                removeAccents(item.userName).toLowerCase().includes(removeAccents(value).toLowerCase());
        });
        setUsersListFiltered(users);
    };

    const onSearchRole = (value: string) => {
        setFilterRole(value);
        const roles = rolesList.filter(item => {
            return removeAccents(item.name).toLowerCase().includes(removeAccents(value).toLowerCase());
        });
        setRolesListFiltered(roles);
    };

    const onCheckUser = (item: UsersSelectItemDto, checked: boolean) => {
        if (isDetails) {
            return;
        }

        setUsersList([
            ...usersList.map(r => {
                if (r.id === item.id) {
                    r.checked = checked;
                }
                return r;
            }),
        ]);
        if (finalRule) {
            createExpression(finalRule);
        }
    };

    const onCheckRole = (item: RoleDto, checked: boolean) => {
        if (isDetails) {
            return;
        }

        setRolesList([
            ...rolesList.map(r => {
                if (r.id === item.id) {
                    r.checked = checked;
                }
                return r;
            }),
        ]);
        if (finalRule) {
            createExpression(finalRule);
        }
    };

    return (
        <div className={styles.container}>
            {canWrite && <div className={styles.buttonContainer}>
                <Button type='button' text={t('shared_translations.common.cancel')} onClick={() => onCancel()} color={Color.white} />
                <Button type='button' text={t('shared_translations.common.save')} onClick={() => onSave()} color={Color.black} />
            </div>}

            <div className={styles.configContainer}>
                <Column
                    title={t('backoffice.rules.title')}
                    allChecked={isAllUsersChecked(filterUser !== '')}
                    items={(filterRule !== '' ? rulesListFiltered : rulesList)}
                    allowCheckAll={false}
                    allowItemCheck={false}
                    onSearch={onSearchRule}
                    onItemCheck={(item) => {
                        void onRuleSelected(item, usersList, rolesList);
                    }}
                    render={(item) => (
                        <div className={styles.ruleInfo}>{t(('backoffice.rules.policies.' + item.name) as any)}</div>
                    )}
                    itemClassName={(item) => item.id === finalRule?.id ? styles.striped : ''}
                    className={styles.rulesContainer}
                    isDetails={isDetails}
                />

                <Column
                    title={t('backoffice.rules.users')}
                    allChecked={isAllUsersChecked(filterUser !== '')}
                    items={(filterUser !== '' ? usersListFiltered : usersList)}
                    onAllCheck={(c) => onAllUsersChecked(c)}
                    onItemCheck={onCheckUser}
                    onSearch={onSearchUser}
                    render={(item) => (
                        <div className={styles.userInfo}>
                            <span className={styles.name}>{item.realName}</span>
                            <span className={styles.email}>({item.userName})</span>
                        </div>
                    )}
                    className={styles.usersContainer}
                    isDetails={isDetails}
                />

                <div className={styles.operationsContainer}>
                    <div className={styles.multiButton}>
                        <Button text={t('backoffice.rules.and')}
                            onClick={setAnd}
                            color={finalRule?.concatType === 'AND' ? Color.black : Color.white}
                            style={{ paddingLeft: 0 }}
                        />
                        <Button text={t('backoffice.rules.or')}
                            onClick={setOr}
                            color={finalRule?.concatType === 'OR' ? Color.black : Color.white}
                            style={{ paddingLeft: 0 }}
                        />
                    </div>
                </div>

                <Column
                    title={t('backoffice.rules.roles')}
                    allChecked={isAllRolesChecked(filterRole !== '')}
                    items={(filterRole !== '' ? rolesListFiltered : rolesList)}
                    onAllCheck={(c) => onAllRolesChecked(c)}
                    onItemCheck={onCheckRole}
                    onSearch={onSearchRole}
                    render={(item) => roleName(item)}
                    className={styles.rolesContainer}
                    isDetails={isDetails}
                />
            </div>
        </div>
    );
};

export default RulesTab;
